<style>
.coupon {
  border: 5px dotted #bbb;
}

.promo {
  padding: 3px;
}

@media only screen and (max-width: 480px) {
  .swiper-wrapper {
    display: flex;
    flex-direction: column;
  }

  .swiper-slide {
    width: 100% !important;
  }
}
</style>
<template>

  <b-card-code class="">

    <swiper class="swiper-multiple" :options="swiperOptions" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
      <swiper-slide class="pb-2" v-for="(data, index) in swiperData" :key="index">
        <!-- <b-img :src="data.img" fluid /> -->
        <div class="coupon">
          <div class="d-flex">
            <b-img :src="data.img" style="width: 100%;height: 50%;" />
            <div class="d-flex position-absolute justify-content-between w-100">
              <!-- <div class="d-flex"> -->
              <div style="font-size: 4rem;font-weight: bold !important;" class="pl-2 pt-1">
                20%
              </div>
              <!-- <div class=" h1 pl-2 pt-3" style="font-weight: bold !important;">
                  OFF
                </div> -->
              <!-- </div> -->
              <span class="brand-logo pr-2">
                <b-img :src="logo" style="width: 40px;height: 40px;" alt=" logo" />
              </span>
            </div>
          </div>
          <div class="p-1" style=" background-color:white">
            <h2 class="m-0"><b>20% OFF YOUR PURCHASE</b></h2>
            <p class="m-0">{{ text.slice(0, 50) + '....' }}<span @click="readMoreClicked"
                class="text-danger text-lighten-1" style="font-weight: bold; cursor: pointer;">Read
                More</span></p>
          </div>
          <div class="container bg-secondary bg-lighten-5">
            <p class="text-white  h5 pt-1 text-dark text-darken-5" style="font-weight: bold">Use Promo Code: <span
                class="promo text-primary bg-white" @dblclick="copyclick">BOH232</span></p>
            <div style="font-size:20px" class="text-danger text-darken-3">Expires: Jan 03, 2021</div>
          </div>
        </div>
      </swiper-slide>
      <div slot="pagination" class="swiper-pagination" />
    </swiper>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BImg } from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import logo from '../../../../public/logo.png'
import logotxt from '../../../../src/assets/images/logo/ngerplogotxt.png'
export default {
  components: {
    Swiper,
    SwiperSlide,
    BCardCode,
    BImg
  },
  data () {
    return {

      logo,
      logotxt,
      /* eslint-disable global-require */
      swiperData: [
        { img: require('@/assets/images/banner/banner-31.jpg') },
        { img: require('@/assets/images/banner/banner-32.jpg') },
        { img: require('@/assets/images/banner/banner-33.jpg') },
        { img: require('@/assets/images/banner/banner-34.jpg') },
        { img: require('@/assets/images/banner/banner-35.jpg') }
      ],
      message1: 'Copy Me Without Directive',
      /* eslint-disable global-require */
      text: `Lorem ipsum dolor sit amet, et nam pertinax gloriatur. Sea te minim soleat senserit, ex quo luptatum
              tacimates voluptatum, salutandi delicatissimi eam ea. In sed nullam laboramus appellantur, mei ei omnis
              dolorem mnesarchum.`,
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
        autoplay: {
          delay: 3000
        },
        scrollbar: { draggable: true },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  methods: {
    copyclick () {
      this.$copyText(this.message1).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Text copied',
            icon: 'CopyIcon'
          }
        })
      }, e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'CopyIcon'
          }
        })
      })
    }
  }
}
</script>
