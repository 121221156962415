<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Name</label>
              <b-form-input v-model="name" placeholder="Enter Name"/>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto; max-height: 500px">
              <table
                class="table table-bordered"
                style="min-width: 25cm; max-height: 15cm"
              >
                <thead class="text">
                  <tr>
                    <th></th>
                    <th style="width:230px">Title</th>
                    <th>Description</th>
                    <th>Images</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, id) in items"
                    :key="id"
                    style="max-height: 100px"
                  >
                    <td>{{ id + 1 }}</td>
                    <td>
                      <b-form-input v-model="item.title" placeholder="Enter Title"/>
                    </td>
                    <td>
                      <quill-editor v-model="item.description" />
                    </td>
                    <td style="max-height: 50px">
                      <!-- <b-form-file
                        v-model="item.thumbnail"
                        placeholder="Select Image"
                        drop-placeholder="Drop file here..."
                        @input="handleFileChange($event, 'gallery', id + 1)"
                      /> -->
                      <div  @click="openmodal('items',id+1,'image')" class="fileuploader cursor-pointer d-flex align-items-center" style="border:1px solid silver;padding:10px;border-radius:5px;display:flex;justify-content:space-between">
                        <div><feather-icon icon="ImageIcon" size="20" /></div>
                        <div>Browse</div>
                      </div>
                      <attachment v-if="item.image && item.image.thumbnail" :data="item.image.thumbnail" />
                    </td>
                    <td>
                      <b-button
                        variant="outline-danger"
                        @click="removeitemsRow(id)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="additemsRow" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>
          <!-- <b-col md="4">
            <b-form-group>
              <label>Groups</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Group"
              >
                <v-select
                  v-model="groups"
                  placeholder="None"
                  label="name"
                  @input="getCategories"
                  :clearable="false"
                  :options="groupsOption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <!-- submit button -->
            <gallary-image-model v-if="modelValue" :modelValue="modelValue" :openmodal="openmodal" @setImage="setImage" :selectedIndex="selectedIndex"/>

          <b-col cols="12" class="mt-1">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              class="mr-4"
              :disabled="check"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import gallaryImageModel from "../../components/gallaryImageModel.vue";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import { quillEditor } from "vue-quill-editor";

import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import {
  required,
} from "@validations";
import Attachment from "../../components/Attechment.vue";

export default {
  components: {
    flatPickr,
    quillEditor,
    BFormCheckboxGroup,
    gallaryImageModel,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BFormCheckbox,
  },
  data() {
    return {
      required,
      check:false,
      items: [
        {
          image:{
            original: "",
            thumbnail: "",
            file_name: "",
            id: "",
          },
          description: '',
          title: '',
        }

      ],
      name: '',
      id: '',
      modelValue: false,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      ifEdit: false,
      check: false,
      selectedField: '',
      selectedIndex: false,
      selected: '',
      is_approve:0
    };
  },
  async mounted() {
    this.ifEdit = !!this.$route.params.slug;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    openmodal(name,index, field) {
      this.modelValue = !this.modelValue
      this.selected = name
      this.selectedField = field ? field:false
      this.selectedIndex = index
      this.$forceUpdate()
    },
    setImage(image) {
      if (this.selectedIndex  && this.selectedField) {
        image.map((item, index) => {
          if (!this[this.selected][this.selectedIndex - 1 + index]) {
            this[this.selected].push({ ...this[this.selected][0] })
            for (let key in this[this.selected][0]) {
              this[this.selected][this.selectedIndex - 1 + index][key] = ''
            }
          }
          this[this.selected][this.selectedIndex - 1 + index][this.selectedField] = item
        })
      }
      else if (this.selectedIndex) {
        image.map((item, index) => {
          if (!this[this.selected][this.selectedIndex - 1 + index]) {
            this[this.selected].push({})
          }
          this[this.selected][this.selectedIndex-1 + index] = item
        })
      }
      else {
        this[this.selected] =image
      }
    },
    async getEditValue() {
      // console.log(this.$route.params.id, "id====");
      await axios({
        method: "GET",
        url: `${this.baseApi}/testimonials/${this.$route.params.slug}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.id = item.id;
      this.name = item.name;
      this.items = item.items;
      this.is_approve= item.is_approve
    },


    submitForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          data = {
            items: this.items,
            name: this.name,
            is_approve:this.is_approve
          };
        }
        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.check = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit || this.editPage ? "put" : "post"}`,
            url: this.ifEdit || this.editPage
              ? `${baseApi}/testimonials/${this.id}`
              : `${baseApi}/testimonials`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.check = false;
              this.editPage = true
              this.id = json.data.id
              this.$swal({
                title: "Submited",
                text: "Successfully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            this.$router.go(-1);

              // this.$router.push("/product");
            })
            .catch((error) => {
              this.check = false;
              this.$swal({
                title: "Error!",
                // text: `${error}`,
                html: html,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    removeitemsRow(index) {
      this.items.splice(index, 1);
    },
    additemsRow() {
      this.items.push({
        image:{
        original: "",
        thumbnail: "",
        file_name: "",
        id: "",
        },
        title: '',
        description:''
      });
    },
    async handleFileChange(e, name, id, idName) {
      this.check = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("attachment[]", e);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachments`,
      };
      await axios(requestOptions)
        .then((response) => {
          if (id) {
            this[name][id - 1].thumbnail = response.data[0].thumbnail;
            this[name][id - 1].file_name = e.name;
            this[name][id - 1].id = response.data[0].id;
            this[name][id - 1].original = response.data[0].original;
          } else {
            this[name].thumbnail = response.data[0].thumbnail;
            this[name].file_name = e.name;
            this[name].id = response.data[0].id;
            this[name].original = response.data[0].original;
          }
          this.check = false;

        })
        .catch((error) => {
          this.check = false;
        });
    },
    onClickBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>

