<template>
  <div>
    <b-card>
      <div class="row align-items-end">
        <div class="col-md-6 mt-1 d-flex gap-1">
          <div class="searchBy">
            <label> Search By</label>
            <v-select :options="searchFieldOptions" v-model="searchField" @input="searchFieldChange" label="name" :clearable="false"></v-select>
          </div>
          <div>
            <label> Search</label>
            <b-input-group class="input-group-merge">
              <b-form-input id="search" v-model="searchTerm" class="form-control-merge" @input="searchApply" type="text" />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <div class="col-md-3 mt-1">
          <label> Category</label>
          <v-select :options="categoryData" v-model="category" @input="searchApply" label="name"></v-select>
        </div>
        <div class="col-md-3 mt-1">
            <label>Date</label>
              <flat-pickr
                v-model="date"
                class="form-control"
                @input="searchApply"
                :config="{
                  enableTime: false,
                  dateFormat: 'd/m/Y',
                }"
                style="background: transparent"
              />
          </div>
          <div class="col-md-12 mt-1 d-flex justify-content-end gap-1 align-items-center">
          <b-button variant="success" id="download" class="p-half d-flex align-items-center justify-content-center" size='sm' :disabled='downloading'
              @click="handleDownload()">
              <b-spinner small v-if="downloading"/>
              <feather-icon icon="ArrowDownIcon" size='20'v-else />
            </b-button>

            <b-tooltip placement="top" target="download" triggers="hover">
              Download in excel
            </b-tooltip>
          <b-dropdown text="Filter" variant="primary" style="height:38px">
            <b-dropdown-item @click="handleOutOfStock">Out of stock</b-dropdown-item>
            <b-dropdown-item @click="clearOutOfStock">Clear filler</b-dropdown-item>
          </b-dropdown>
          <b-button variant="primary" @click="$router.push('/add-product')" class="btn btn-primary float-right">
            Add Product
          </b-button>
        </div>
      </div>
    </b-card>
    <b-card>
      <b-row class="mb-2">
        <b-col cols="6">
          <p>
            <b>Active Products</b> : <b-badge variant="success">{{ counts.active }}</b-badge>
          </p>
          <p>
            <b>In Stock Products</b> : <b-badge variant="success">{{ counts.inStock }}</b-badge>
          </p>
          <p>
            <b>Out  of Stock Products</b> : <b-badge variant="success">{{ counts.outOfStock }}</b-badge>
          </p>
        </b-col>
        <b-col cols="6" class="d-flex justify-content-end">
          <p>
            <b>Inactive Products</b> : <b-badge variant="danger">{{ counts.inactive }}</b-badge>
          </p>
        </b-col>
      </b-row>
      <vue-good-table class="table" :columns="fields" :rows="allUser" :rtl="direction" :isLoading="loadData"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false,
          selectAllByGroup: false,
        }" :pagination-options="pagination">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'image'">
            <!-- {{ props.row.image.thumbnail }} -->

            <b-img v-if="props.row.image" :src="props.row.image.webp_thumbnail ? props.row.image.webp_thumbnail : props.row.image.thumbnail" fluid style="width: 100px; height: 100px" />
          </span>
          <div v-else-if="props.column.field === 'is_approved'">
            <b-form-checkbox :checked="props.row.is_approved ? true : false" class="custom-control-primary"
              name="check-button" @change="ToggleChanged(props.row.id, $event)" switch>
            </b-form-checkbox>
          </div>
          <span v-else-if="props.column.field === 'action'" style="width: fit-content; display: flex" class='position-relative'>
            <b-badge variant="warning" :id="'edit' + props.row.id" class="mr-50 cursor-pointer"
              @click="handleEdit(props.row)">
              <feather-icon icon="Edit2Icon" />
            </b-badge>

            <b-badge variant="danger" :id="'delete' + props.row.id" class="cursor-pointer"
              @click="handleDelete(props.row)">
              <feather-icon icon="TrashIcon" />
            </b-badge>
            <b-tooltip placement="top" :target="'edit' + props.row.id" triggers="hover">
              Edit
            </b-tooltip>
            <b-tooltip placement="top" :target="'delete' + props.row.id" triggers="hover">
              Delete
            </b-tooltip>
          </span>
          <div v-else>
            {{ props.row[props.column.field] }}
          </div>
        </template>
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <!-- <b-form-select v-model="pageLength" :options="['5', '10', '20', '50', '100', '500']" class="mx-1"
                @input="changePagination()" />
              <span class="text-nowrap"> Showing {{ paginationStart }} to {{ paginationEnd }} of {{ paginationTotal
              }}</span> -->
            </div>
            <div>
              <b-pagination v-if="show" v-model="paginationValue" :total-rows="paginationTotal" :per-page="pageLength"
                first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="changePagination('change')">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>

  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import axios from "@/components/axios";
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import { VueGoodTable } from "vue-good-table";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormSelect,
  BPagination,
  BButton,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
  BFormDatepicker,
  BSpinner,
  BImg,
  BImgLazy,
  BCard,
  BTooltip,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BInputGroupAppend

} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormGroup,
    BForm,
    BSpinner,
    VueGoodTable,
    BRow,
    BBadge,
    BFormInput,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BCol,
    BImg,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    BCard,
    BTooltip,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,

  },
  data() {
    return {
      
      loadData: true,
      pagination: {
        enabled: true,
        mode: "records",
        perPage: 500,
        position: "bottom",
        perPageDropdown: [3, 7, 9],
        dropdownAllowAll: false,
        setCurrentPage: 2,
        jumpFirstOrLast: true,
        firstLabel: "First Page",
        lastLabel: "Last Page",
        nextLabel: "next",
        prevLabel: "prev",
        rowsPerPageLabel: "Rows per page",
        ofLabel: "of",
        pageLabel: "page", // for 'pages' mode
        allLabel: "All",
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`,
      },
      category: "",
      pageLength: 15,
      paginationStart: 0,
      paginationValue: 1,
      paginationTotal: 0,
      paginationEnd: 0,
      categoryData: [],
      searchTerm: "",
      startdate: "",
      enddate: "",
      result: "",
      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      searchFieldOptions:[
        {name:'Name',value:'name'},
        {name:'Code',value:'sku'},
      ],
      searchField:{name:'Name',value:'name'},
      fields: [
        {
          field: "image",
          label: "Image",
          hidden: false,
        },
        {
          field: "name",
          label: "Name",
        },
        {
          field: "is_approved",
          label: "Approval Action",
        },
        {
          field: "created_at",
          label: "Created At",
        },
        {
          field: "updated_at",
          label: "Updated At",
        },
        {
          field: "sku",
          label: "Code",
        },
        {
          field: "price",
          label: "Price",
        },

        {
          field: "quantity",
          label: "Quantity",
        },
        {
          field: "action",
          label: "Actions",
        },
      ],
      allUser: [],
      show: false,

      counts:{
        inactive: 0,
        active:0,
        inStock:0,
        Outofstock:0
      },
      outofstock: false,
      date:null,
      downloading:false
    };
  },
  computed: {
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  async mounted() {
    this.getCategory();
    this.paginationValue = this.$route.query.page ? this.$route.query.page : ''
    this.searchTerm = this.$route.query.code ? this.$route.query.code : (this.$route.query.name ?this.$route.query.name:'')
    this.searchField = this.$route.query.code?{name:'Code',value:'sku'}:{name:'Name',value:'name'}
    this.category = this.$route.query.category ? { slug: this.$route.query.category } : ''
    this.outofstock = this.$route.query.outofstock ? this.$route.query.outofstock  : false
    this.date = this.$route.query.date ? this.$route.query.date : null

    this.getAllUsers();

    // const currentDate = new Date();

    // // Calculate the start date for the last 6 months
    // const sixMonthsAgo = new Date();
    // sixMonthsAgo.setMonth(currentDate.getMonth() - 6);

    // // Format the dates as strings in the 'YYYY-MM-DD' format
    // const formattedCurrentDate = currentDate.toISOString().split('T')[0];
    // const formattedSixMonthsAgo = sixMonthsAgo.toISOString().split('T')[0];

    // Replace the searchdate with the calculated dates
    // this.searchdate = [formattedSixMonthsAgo, formattedCurrentDate];
  },
  methods: {
    async handleDownload(){
      this.downloading=true
      let params={}
      params.currency=this.$store.state.app.currency;
      params.page=this.paginationValue;
      params.limit='1000000';
      params.searchJoin='AND';
      params.search=`${this.searchTerm
            ? (this.searchField.value + ":" + this.searchTerm +(this.category ?';':'')) 
            : ""
          }${this.category ? "categories.slug:" + this.category.slug + (this.outofstock?';':'') : ""}${this.outofstock ? 'quantity:0' : ''}`
          if(this.date){
            params.date_range2= moment(this.date, 'DD/MM/yyyy').format('yyyy-MM-DD')+'//'+ moment(this.date, 'DD/MM/yyyy').format('yyyy-MM-DD')
          }
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/products`,
        params
      };
      await axios(request).then((json) => {
        let allProduct = json.data.data;
        this.downloading=false
        var aoa = [
                ['No.', 'Name', 'Code', 'Quantity', 'Status', 'Created At', 'Updated At','Image','Other Images','Description'],
                []
            ];
            let arr = []
            allProduct.map((item,index) => {
              let otherarr=[]
              if(item.gallery){
                otherarr = item.gallery.map((inner)=>{
                  return inner? (inner.webp_thumbnail ?inner.webp_thumbnail : inner.thumbnail) :'' 
                })
              }
              otherarr=otherarr.filter(item=>item) 
              arr.push([
                index+1,
                item.name,
                item.sku,
                item.quantity,
                item.is_approved?'Active':'In Active',
                item.updated_at ? moment(item.updated_at).format('DD/MM/yyyy') : '',  
                item.created_at ? moment(item.created_at).format('DD/MM/yyyy') : '',
                item.image && item.image.webp_thumbnail ? item.image.webp_thumbnail : (item.image ? item.image.thumbnail:''),
                otherarr.join('\n'),
                item.description
              ])
            })
            aoa = [...aoa, ...arr]
            var ws = XLSX.utils.aoa_to_sheet(aoa);
            var html_string = XLSX.utils.sheet_to_html(ws, { id: "data-table", editable: true });
            document.getElementById("container").innerHTML = html_string;
            function doit(type, fn) {
                var elt = document.getElementById('data-table');
                var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
                XLSX.writeFile(wb, fn || ('SheetJSTableExport.' + (type || 'xlsx')));
              }
            doit('xlsx','products-'+moment().format('DD/MM/YYYY')+'.xlsx');
      });
    },
    searchFieldChange(){
      if(this.searchTerm){
        this.searchApply()
      }
    },
    handleDateFilter() {
       this.dateFilter = true
    },
    handleOutOfStock() {
      this.outofstock = true
      const url = new URL(window.location.href);
      url.searchParams.set('outofstock', true);
      window.history.replaceState({}, '', url);

      this.getAllUsers();
    },
    clearOutOfStock() {
      this.outofstock = false
      this.date = null
      this.searchTerm = ''
      const url = new URL(window.location.href);
      url.searchParams.delete('outofstock');
      url.searchParams.delete('date');
      url.searchParams.delete('name');
      url.searchParams.delete('code');
      window.history.replaceState({}, '', url);

      this.getAllUsers();
    },
    handleEdit(row) {
      this.$router.push(`/edit-product/${row.id}`);
    },
    handleDelete(row) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            const request = {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
              url: `${this.baseApi}/products/${row.id}`,
            };
            axios(request).then((json) => {
              this.getAllUsers();
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "Your data has been deleted.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async ToggleChanged(id, e) {
      let tmpData = this.allUser.filter((i) => {
        i.id == id;
      });
      // await this.Setting();
      const request = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: {
          is_approved: e,
          name: tmpData.name,
          language: tmpData.language,
        },
        url: `${this.baseApi}/products/${id}`,
      };
      await axios(request).then((json) => {
        this.getAllUsers();
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Successfully Updated`,
            icon: "CheckIcon",
            variant: "success",
            text: `You have successfully Changed Status of ${json.data.name}.`,
          },
        });
      });
    },
    searchApply: _.debounce(function () {
      const url = new URL(window.location.href);
      if (this.searchField.name == 'Name' &&this.searchTerm ) {
        url.searchParams.set('name', this.searchTerm);
      }
      else {
        url.searchParams.delete('name')
      }

      if (this.searchField.name == 'Code'&&this.searchTerm) {
        url.searchParams.set('code', this.searchTerm);
      }
      else {
        url.searchParams.delete('code')
      }

      if (this.category) {
        url.searchParams.set('category', this.category.slug);
      }
      else {
        url.searchParams.delete('category')
      }
       if (this.date) {
        url.searchParams.set('date', moment(this.date,'DD/MM/yyyy').format('yyyy-MM-DD'));
      }
      else {
        url.searchParams.delete('date')
      }
      window.history.replaceState({}, '', url);
      this.getAllUsers();
    }, 1000),
    changePagination() {

      const url = new URL(window.location.href);
      url.searchParams.set('page', this.paginationValue);
      window.history.replaceState({}, '', url);
      this.getAllUsers();
    },
    async getAllUsers() {

      // this.Setting();
      this.loadData = true;
      let params={}
      params.currency=this.$store.state.app.currency;
      params.page=this.paginationValue;
      params.limit='15';
      params.searchJoin='AND';
      params.search=`${this.searchTerm
            ? (this.searchField.value + ":" + this.searchTerm +(this.category ?';':'')) 
            : ""
          }${this.category ? "categories.slug:" + this.category.slug + (this.outofstock?';':'') : ""}${this.outofstock ? 'quantity:0' : ''}`

          if(this.date){
            params.date_range2= moment(this.date, 'DD/MM/yyyy').format('yyyy-MM-DD')+'//'+ moment(this.date, 'DD/MM/yyyy').format('yyyy-MM-DD')
          }


      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/products`,
        params
      };
      await axios(request).then((json) => {
        this.loadData = false;
        this.paginationTotal = json.data.total;
        this.paginationStart = json.data.from;
        this.paginationEnd = json.data.to;
        this.allUser = json.data.data;
        this.getCounts()

        this.show = true
        this.allUser.map((item) => {
          item.price = item.price ? '₹' + item.price.toLocaleString("en-IN") : '₹0.00'
          item.updated_at = item.updated_at ? moment(item.updated_at).format('DD/MM/yyyy') : ''
          item.created_at = item.created_at ? moment(item.created_at).format('DD/MM/yyyy') : ''
        })

      });
    },
    async getCounts() {
      let params={}
      params.currency=this.$store.state.app.currency;
      params.page=this.paginationValue;
      params.limit='15';
      params.searchJoin='AND';
      params.search=`${this.searchTerm
            ? (this.searchField.value + ":" + this.searchTerm +(this.category ?';':'')) 
            : ""
          }${this.category ? "categories.slug:" + this.category.slug + (this.outofstock?';':'') : ""}${this.outofstock ? 'quantity:0' : ''}`

          if(this.date){
            params.date_range2= moment(this.date, 'DD/MM/yyyy').format('yyyy-MM-DD')+'//'+ moment(this.date, 'DD/MM/yyyy').format('yyyy-MM-DD')
          }


      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/productcount`,
        params

      };
      await axios(request).then((json) => {
        let data = json.data
        this.counts.active = data.active_products;
        this.counts.inactive = data.inactive_products;
        this.counts.inStock = data.instock_products;
        this.counts.outOfStock = data.outstock_products;
      });
    },
    async getCategory() {
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/categories?limit=999`,
      };
      await axios(request).then((json) => {
        this.categoryData = json.data.data;
        if (this.$route.query.category) {
          this.category = this.categoryData.filter((item) => item.slug == this.$route.query.category)[0]
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.gap-1{
  gap:10px
}
.searchBy{
  min-width:35%
}
</style>
