<style>
.img-fluid {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.carousel-inner {
  width: 100%;
  height: 100%;
}
</style>
<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <!-- <b-link class="brand-logo">
        <b-img
          :src="require('@/assets/images/logo/ngerplogo.png')"
          alt="logo"
          width="240px"
        />
      </b-link> -->
      <!-- /Brand logo-->

      <!-- <b-link class="brand-logo">
        <b-img
          :src="require('@/assets/images/logo/ngerplogo.png')"
          alt="logo"
          width="240px"
        />
      </b-link> -->
      <b-col
        lg="8"
        style="width: 100%; height: 100%"
        class="p-0 d-none d-lg-flex align-items-center"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center"
          style="width: 100%; height: 100%"
        >
          <b-carousel
            style="width: 100%; height: 100%"
            id=" carousel-interval"
            controls
            indicators
            :interval="3000"
          >
            <b-carousel-slide
              v-for="itemImage in banner"
              :key="itemImage.id"
              :img-src="itemImage"
              style="width: 100%; height: 100%"
            />
          </b-carousel>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1"> Forgot Password? 🔒 </b-card-title>
          <b-card-text class="mb-2">
            Enter your email and we'll send you instructions to reset your
            password
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="clickReset"
            >
              <b-row>
                <b-col md="12">
                  <b-form-group>
                    <label>Email</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="forgot-password-email"
                        v-model="userEmail"
                        :state="errors.length > 0 ? false : null"
                        name="forgot-password-email"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col v-if="ifToken" md="12">
                  <b-form-group>
                    <label>Password</label>
                    <validation-provider #default="{ errors }" name="Password">
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          v-model="password"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Enter Password"
                          @input="CheckPassword()"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col v-if="ifToken" md="12">
                  <b-form-group>
                    <label>Confirm Password</label>
                    <validation-provider
                      #default="{ errors }"
                      name="Confirm Password"
                    >
                      <b-form-input
                        v-model="cnfPassword"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Enter Confirm Password"
                        @input="CheckPassword()"
                      />
                      <small v-if="chkPassword == false" class="text-danger"
                        >Your Password Does Not Match</small
                      >
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-button type="submit" variant="primary" block>
                  {{ ifToken ? "Reset Password" : "Send reset link" }}
                </b-button>
              </b-row>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-login' }">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BInputGroupAppend,
  BCarousel,
  BCarouselSlide,
  BInputGroup,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton
} from 'bootstrap-vue'
import { required, email } from '@validations'
import axios from '@/components/axios'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BInputGroup,
    BLink,
    BImg,
    BForm,
    BInputGroupAppend,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BCarousel,
    BCarouselSlide
  },
  mixins: [togglePasswordVisibility],
  data () {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      required,
      email,
      ifToken: false,
      password: '',
      cnfPassword: '',
      chkPassword: true,
      banner: []
    }
  },
  computed: {
    passwordToggleIcon () {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl () {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    }
  },
  mounted () {
    this.getBanner()
    this.ifToken = !!this.$route.query.token
  },
  methods: {
    async getBanner () {
      await axios({
        method: 'GET',
        url: `${this.baseApi}/auth/banners`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((json) => {
          const data = json.data.data.data
          data.map((item) => {
            if (item.position == 'Forgot Password Page') {
              this.banner.push(item.image)
            }
          })
        })
        .catch((error) => console.log(error, 'error'))
      if (JSON.stringify(this.banner) == '[]') {
        this.banner.push(this.imgUrl)
      }
    },
    CheckPassword () {
      this.chkPassword = false
      if (this.password == this.cnfPassword) {
        this.chkPassword = true
      }
    },
    // validationForm () {
    //   this.$refs.simpleRules.validate().then(success => {
    //     if (success) {
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'This is for UI purpose only.',
    //           icon: 'EditIcon',
    //           variant: 'success'
    //         }
    //       })
    //     }
    //   })
    // },
    async clickReset () {
      // const userData = JSON.parse(localStorage.getItem('userData')
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: 'Warning',
            timer: 5000,
            text: 'Plese Fill the All Field',
            icon: 'warning',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        }
        if (success) {
          if (this.ifToken) {
            const requestOptions = {
              method: 'post',
              headers: {
                'Content-Type': 'application/json'
                // 'Authorization': `Bearer ${this.accessToken}`
              },
              url: `${this.baseApi}/reset-password`,
              data: {
                email: this.userEmail,
                token: this.$route.query.token,
                password: this.password,
                password_confirmation: this.cnfPassword
              }
            }
            await axios(requestOptions)
              .then((response) => {
                this.$swal({
                  title: 'Submited',
                  text: `${response.data.message}`,
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary'
                  },
                  buttonsStyling: false
                })
                this.$router.push('/login')
              })
              .catch((error) => {
                this.$swal({
                  title: 'Error!',
                  text: `${error}`,
                  icon: 'error',
                  timer: 5000,
                  customClass: {
                    confirmButton: 'btn btn-primary'
                  },
                  buttonsStyling: false
                })
              })
          } else {
            const requestOptions = {
              method: 'post',
              headers: {
                'Content-Type': 'application/json'
                // 'Authorization': `Bearer ${this.accessToken}`
              },
              url: `${this.baseApi}/forget-password`,
              data: {
                email: this.userEmail
              }
            }
            await axios(requestOptions)
              .then((response) => {
                this.$swal({
                  title: 'Submited',
                  text: `${response.data.status}`,
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary'
                  },
                  buttonsStyling: false
                })
                this.$router.push('/login')
              })
              .catch((error) => {
                const validEmail = JSON.stringify(error).includes('404')
                this.$swal({
                  title: 'Error!',
                  text: validEmail
                    ? 'Aahh! There is no record found in our DB. Please check your data.'
                    : 'Try Again',
                  icon: 'error',
                  timer: 5000,
                  customClass: {
                    confirmButton: 'btn btn-primary'
                  },
                  buttonsStyling: false
                })
              })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
