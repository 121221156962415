<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from "@core/components/scroll-to-top/ScrollToTop.vue";

Vue.prototype.$eventBus = new Vue();

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import axios from "@/components/axios";

import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";
import Vue from "vue";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      const accessToken = localStorage.getItem("accessToken");
      if (this.$route.name !== null) {
        if (
          this.$route.name !== "auth-forgot-password" &&
          this.$route.name !== "auth-register"
        ) {
          if (accessToken == null) {
            this.$router.push("/login");
          }
        } else {
          if (accessToken !== null) {
            this.$router.push("/");
          }
        }
      }
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  methods: {
    // async logoutBeforeUnload(e) {
    //   event.preventDefault(); // This is required for some browsers
    //   event.returnValue = 'Are you sure you want to leave this page?';

    //   // Optionally, you can call a function if the user chooses to stay
    //   let alert = alert('You are logout');
    //     // Call your function here
    //       let accessToken = localStorage.getItem("accessToken");
    //     let baseApi = process.env.VUE_APP_APIENDPOINT;
    //     const requestOptionsUsers = {
    //       method: "GET",
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${accessToken}`,
    //       },
    //       url: `${baseApi}/logout`,
    //     };
    //     await axios(requestOptionsUsers).then((response) => {
    //       console.log('logout')
    //       localStorage.clear();
    //     });

    // },
  },
  mounted() {
    // window.addEventListener("beforeunload",this.logoutBeforeUnload);
    const accessToken = localStorage.getItem("accessToken");
    // const requestOptions = {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${accessToken}`
    //   }
    // }
    // // eslint-disable-next-line prefer-const
    // let data1 = []
    const baseApi = process.env.VUE_APP_APIENDPOINT;


    // if(userData && userData.role !== "admin"){


    // const user = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${accessToken}`,
    //   },
    //   url: `${baseApi}/getuserrights`,
    //   data,
    // };
    // axios(user)
    //   .then((response) => {
    //     this.checkJSON = true;
    //     try {
    //       JSON.parse(response);
    //     } catch (e) {
    //       this.checkJSON = false;
    //     }
    //     const result =
    //       this.checkJSON == false
    //         ? JSON.parse(response.data.data)
    //         : JSON.stringify(response.data.data) == "[]"
    //         ? null
    //         : response.data.data;

    //     let arr = [];
    //     Object.keys(result).forEach((key) => {
    //       arr = [...arr, { ...result[key] }];
    //     });
    //     localStorage.removeItem("UserRights");
    //     localStorage.setItem("UserRights", JSON.stringify(arr));
    //   })
    //   .catch((error) => {
    //     if (JSON.stringify(error).includes("401") == true) {
    //       const keys = Object.keys(localStorage);
    //       let i = keys.length;
    //       while (i--) {
    //         localStorage.removeItem(`${keys[i]}`);
    //       }
    //       this.$router.push("/login");
    //     }
    //   });
    // }
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();
    const { enableScrollToTop } = $themeConfig.layout;

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
      enableScrollToTop,
    };
  },
};
</script>
