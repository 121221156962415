export default [
  {
    path: '/backup',
    name: 'backup',
    component: () => import('@/views/Backup.vue'),
    meta: {
      pageTitle: 'Backup',
      breadcrumb: [
        {
          text: 'Backup',
          active: true
        }
      ]
    }
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product/Product.vue'),
    meta: {
      pageTitle: 'Product',
      breadcrumb: [
        {
          text: 'Product',
          active: true
        }
      ]
    }
  },
  {
    path: '/add-product',
    name: 'add-product',
    component: () => import('@/views/product/AddProduct.vue'),
    meta: {
      pageTitle: 'Add Product',
      breadcrumb: [
        {
          text: 'Product',
          to: '/product'
        },
        {
          text: 'Add Product',
          active: true
        },
      ],
    },
  },
  {
    path: '/createorder',
    name: 'createorder',
    component: () => import('@/views/createOrder/CreateOrder.vue'),
    meta: {
      pageTitle: 'Create Order',
      breadcrumb: [
        {
          text: 'Create Order',
          active: true
        }
      ]
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/orders/Orders.vue'),
    meta: {
      pageTitle: 'Orders',
      breadcrumb: [
        {
          text: 'Orders',
          active: true
        }
      ]
    }
  },
  {
    path: '/orders/:id',
    name: 'ordersView',
    component: () => import('@/views/orders/View.vue'),
    meta: {
      pageTitle: 'Orders',
      breadcrumb: [
        {
          text: 'Orders',
          to: '/orders'

        },
        {
          text: 'View',
          active: true,
        }
      ]
    }
  },
  {
    path: '/edit-product/:slug',
    name: 'edit-product',
    component: () => import('@/views/product/AddProduct.vue'),
    meta: {
      pageTitle: 'Edit Product',
      breadcrumb: [
        {
          text: 'Product',
          to: '/product'
        },
        {
          text: 'Edit Product',
          active: true
        }
      ]
    },
  },
  {
    path: '/banner',
    name: 'banner',
    component: () => import('@/views/banner/Banner.vue'),
    meta: {
      pageTitle: 'Banner',
      breadcrumb: [
        {
          text: 'Banner',
          active: true
        },
      ]
    },
  },
  {
    path: '/add-banner',
    name: 'add-banner',
    component: () => import('@/views/banner/AddBanner.vue'),
    meta: {
      pageTitle: 'Add Banner',
      breadcrumb: [
        {
          text: 'Banner',
          to: '/banner'
        },
        {
          text: 'Add Banner',
          active: true
        },
      ]
    },
  },
  {
    path: '/edit-banner/:slug',
    name: 'edit-banner',
    component: () => import('@/views/banner/AddBanner.vue'),
    meta: {
      pageTitle: 'Edit Banner',
      breadcrumb: [
        {
          text: 'Banner',
          to: '/banner'
        },
        {
          text: 'Edit Banner',
          active: true
        },
      ]
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/users/Users.vue'),
    meta: {
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true
        },
      ]
    },
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('@/views/categories/Categories.vue'),
    meta: {
      pageTitle: 'Categories',
      breadcrumb: [
        {
          text: 'Categories',
          active: true
        }
      ]
    }
  },
  {
    path: '/add-categories',
    name: 'add-categories',
    component: () => import('@/views/categories/AddCategories.vue'),
    meta: {
      pageTitle: 'Add Categories',
      breadcrumb: [
        {
          text: 'Categories',
          to: '/categories'
        },
        {
          text: 'Add Categories',
          active: true
        },
      ],
    },
  },
  {
    path: '/edit-categories/:slug',
    name: 'edit-categories',
    component: () => import('@/views/categories/AddCategories.vue'),
    meta: {
      pageTitle: 'Edit Categories',
      breadcrumb: [
        {
          text: 'Categories',
          to: '/categories'
        },
        {
          text: 'Edit Categories',
          active: true
        }
      ]
    },
  },


  {
    path: '/gazette',
    name: 'gazette',
    component: () => import('@/views/gazette/Gazette.vue'),
    meta: {
      pageTitle: 'Gazette',
      breadcrumb: [
        {
          text: 'Gazette',
          active: true
        }
      ]
    }
  },

  {
    path: '/add-gazette',
    name: 'add-gazette',
    component: () => import('@/views/gazette/AddGazette.vue'),
    meta: {
      pageTitle: 'Add Gazette',
      breadcrumb: [
        {
          text: 'Gazette',
          to: '/gazette'
        },
        {
          text: 'Add Gazette',
          active: true
        },
      ],
    },
  },
  {
    path: '/edit-gazette/:slug',
    name: 'edit-gazette',
    component: () => import('@/views/gazette/AddGazette.vue'),
    meta: {
      pageTitle: 'Edit Gazette',
      breadcrumb: [
        {
          text: 'Gazette',
          to: '/gazette'
        },
        {
          text: 'Edit Gazette',
          active: true
        }
      ]
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/settings/Settings.vue'),
    meta: {
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'Settings',
          active: true
        }
      ]
    },
  },


  {
    path: '/latestupdate',
    name: 'latestupdate',
    component: () => import('@/views/latestupdate/LatestUpdate.vue'),
    meta: {
      pageTitle: 'Latest Update',
      breadcrumb: [
        {
          text: 'Latest Update',
          active: true
        }
      ]
    }
  },
  {
    path: '/add-latestupdate',
    name: 'add-latestupdate',
    component: () => import('@/views/latestupdate/AddLatestUpdate.vue'),
    meta: {
      pageTitle: 'Add Latest Update',
      breadcrumb: [
        {
          text: 'Latest Update',
          to: '/latestupdate'
        },
        {
          text: 'Add Latest Update',
          active: true
        },
      ],
    },
  },
  {
    path: '/edit-latestupdate/:slug',
    name: 'edit-latestupdate',
    component: () => import('@/views/latestupdate/AddLatestUpdate.vue'),
    meta: {
      pageTitle: 'Edit Latest Update',
      breadcrumb: [
        {
          text: 'Latest Update',
          to: '/latestupdate'
        },
        {
          text: 'Edit Latest Update',
          active: true
        }
      ]
    },
  },


  {
    path: '/taxes',
    name: 'taxes',
    component: () => import('@/views/tax/Tax.vue'),
    meta: {
      pageTitle: 'Taxes',
      breadcrumb: [
        {
          text: 'Taxes',
          active: true
        }
      ]
    }
  },
  {
    path: '/add-taxes',
    name: 'add-taxes',
    component: () => import('@/views/tax/AddTax.vue'),
    meta: {
      pageTitle: 'Add Tax',
      breadcrumb: [
        {
          text: 'Taxes',
          to: '/taxes'
        },
        {
          text: 'Add Taxes',
          active: true
        },
      ],
    },
  },
  {
    path: '/edit-taxes/:id',
    name: 'edit-taxes',
    component: () => import('@/views/tax/AddTax.vue'),
    meta: {
      pageTitle: 'Edit Taxes',
      breadcrumb: [
        {
          text: 'Taxes',
          to: '/taxes'
        },
        {
          text: 'Edit Taxes',
          active: true
        }
      ]
    },
  },

  {
    path: '/reviews',
    name: 'Reviews',
    component: () => import('@/views/reviews/Reviews.vue'),
    meta: {
      pageTitle: 'Reviews',
      breadcrumb: [
        {
          text: 'Reviews',
          active: true
        }
      ]
    }
  }, {
    path: '/contentmanagement',
    name: 'ContentManagement',
    component: () => import('@/views/cms/Cms.vue'),
    meta: {
      pageTitle: 'Content Management',
      breadcrumb: [
        {
          text: 'ContentManagement',
          active: true
        }
      ]
    },
  },
  {
    path: '/add-contentmanagement',
    name: 'add-ContentManagement',
    component: () => import('@/views/cms/AddCms.vue'),
    meta: {
      pageTitle: 'Add CMS',
      breadcrumb: [
        {
          text: 'Content Management',
          to: '/contentmanagement'
        },
        {
          text: 'Add CMS',
          active: true
        },
      ]
    },
  },
  {
    path: '/edit-contentmanagement/:slug',
    name: 'edit-ContentManagement',
    component: () => import('@/views/cms/AddCms.vue'),
    meta: {
      pageTitle: 'Edit CMS',
      breadcrumb: [
        {
          text: 'Content Management',
          to: '/contentmanagement'
        },
        {
          text: 'Edit CMS',
          active: true
        },
      ]
    },
  },

  {
    path: '/testimonial',
    name: 'testimonial',
    component: () => import('@/views/testimonial/Testimonial.vue'),
    meta: {
      pageTitle: 'Testimonial',
      breadcrumb: [
        {
          text: 'Testimonial',
          active: true
        }
      ]
    },
  },
  {
    path: '/add-testimonial',
    name: 'add-testimonial',
    component: () => import('@/views/testimonial/AddTestimonial.vue'),
    meta: {
      pageTitle: 'Add Testimonial',
      breadcrumb: [
        {
          text: 'Testimonial',
          to: '/testimonial'
        },
        {
          text: 'Add Testimonial',
          active: true
        },
      ]
    },
  },
  {
    path: '/edit-testimonial/:slug',
    name: 'edit-testimonial',
    component: () => import('@/views/testimonial/AddTestimonial.vue'),
    meta: {
      pageTitle: 'Edit Testimonial',
      breadcrumb: [
        {
          text: 'Testimonial',
          to: '/testimonial'
        },
        {
          text: 'Edit Testimonial',
          active: true
        },
      ]
    },
  },
  {
    path: '/shipping',
    name: 'Shipping',
    component: () => import('@/views/shipping/shipping.vue'),
    meta: {
      pageTitle: 'Shipping',
      breadcrumb: [
        {
          text: 'Shipping',
          active: true
        }
      ]
    },
  },
  {
    path: '/add-shipping',
    name: 'add-shipping',
    component: () => import('@/views/shipping/Addship.vue'),
    meta: {
      pageTitle: 'Add Shipping',
      breadcrumb: [
        {
          text: 'Shipping',
          to: '/shipping'
        },
        {
          text: 'Add Shipping',
          active: true
        },
      ]
    },
  },
  {
    path: '/edit-shipping/:id',
    name: 'edit-shipping',
    component: () => import('@/views/shipping/Addship.vue'),
    meta: {
      pageTitle: 'Edit Shipping',
      breadcrumb: [
        {
          text: 'Shipping',
          to: '/shipping'
        },
        {
          text: 'Edit Shipping',
          active: true
        },
      ]
    },
  },
  {
    path: '/coupons/',
    name: 'Coupons',
    component: () => import('@/views/Coupons/coupon.vue'),
    meta: {
      pageTitle: 'Coupons',
      breadcrumb: [
        {
          text: 'Coupon',
          to: '/Coupons'
        },
        {
          text: 'Coupons',
          active: true
        },
      ]
    },
  },
  {
    path: '/add-coupons',
    name: 'add-Coupons',
    component: () => import('@/views/Coupons/Addcoupon.vue'),
    meta: {
      pageTitle: 'Add Coupons',
      breadcrumb: [
        {
          text: 'Coupons',
          to: '/Coupons'
        },
        {
          text: 'Add Coupons',
          active: true
        },
      ]
    },
  },
  {
    path: '/edit-coupons/:slug',
    name: 'edit-Coupons',
    component: () => import('@/views/Coupons/Addcoupon.vue'),
    meta: {
      pageTitle: 'Edit Coupons',
      breadcrumb: [
        {
          text: 'Coupons',
          to: '/Coupons'
        },
        {
          text: 'Edit Coupon',
          active: true
        },
      ]
    },
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: () => import('@/views/gallery/Gallery.vue'),
    meta: {
      pageTitle: 'Gallery',
      breadcrumb: [
        {
          text: 'Gallery',
          active:true
        },
      ]
    },
  },
  {
    path: '/country',
    name: 'country',
    component: () => import('@/views/location/Country.vue'),
    meta: {
      pageTitle: 'Country',
      breadcrumb: [
        {
          text: 'Country',
          active: true
        },
      ]
    },
  },
  {
    path: '/state',
    name: 'state',
    component: () => import('@/views/location/State.vue'),
    meta: {
      pageTitle: 'State',
      breadcrumb: [
        {
          text: 'State',
          active: true
        },
      ]
    },
  },
  {
    path: '/city',
    name: 'city',
    component: () => import('@/views/location/City.vue'),
    meta: {
      pageTitle: 'City',
      breadcrumb: [
        {
          text: 'City',
          active: true
        },
      ]
    },
  },
  {
    path: '/currency',
    name: 'currency',
    component: () => import('@/views/location/Currency.vue'),
    meta: {
      pageTitle: 'Currency',
      breadcrumb: [
        {
          text: 'Currency',
          active: true
        },
      ]
    },
  },
  {
    path: '/notify-users',
    name: 'notify-users',
    component: () => import('@/views/users/NotifyUser.vue'),
    meta: {
      pageTitle: 'Notify User',
      breadcrumb: [
        {
          text: 'Notify User',
          active: true
        },
      ]
    },
  },



  {
    path: '/emailtemplate',
    name: 'emailtemplate',
    component: () => import('@/views/emailtemplate/EmailTemplate.vue'),
    meta: {
      pageTitle: 'Email Template',
      breadcrumb: [
        {
          text: 'Email Template',
          active: true
        }
      ]
    },
  },
  {
    path: '/add-emailtemplate',
    name: 'add-emailtemplate',
    component: () => import('@/views/emailtemplate/AddEmailTemplate.vue'),
    meta: {
      pageTitle: 'Add Email Template',
      breadcrumb: [
        {
          text: 'Email Template',
          to: '/emailtemplate'
        },
        {
          text: 'Add Email Template',
          active: true
        },
      ]
    },
  },
  {
    path: '/edit-emailtemplate/:id',
    name: 'edit-emailtemplate',
    component: () => import('@/views/emailtemplate/AddEmailTemplate.vue'),
    meta: {
      pageTitle: 'Edit Email Template',
      breadcrumb: [
        {
          text: 'Email Template',
          to: '/emailtemplate'
        },
        {
          text: 'Edit Email Template',
          active: true
        },
      ]
    },
  },


  {
    path: '/marquee',
    name: 'marquee',
    component: () => import('@/views/marquee/Marquee.vue'),
    meta: {
      pageTitle: 'Marquee',
      breadcrumb: [
        {
          text: 'Marquee',
          active: true
        }
      ]
    },
  },

]




