


<template>
  <div class="order">
    <b-card>
      <b-row class="justify-content-end">
        <b-col md="6">
          <b-input-group class="input-group-merge">
            <b-form-input
              id="login-password"
              v-model="search"
              class="form-control-merge"
              type="text"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>

    <div class="orderCards">
      <b-row>
        <b-col
          md="4"
          lg="3"
          sm="6"
          class="products"
          v-for="(product, index) in allProducts"
          :key="index"
        >
          <b-card>
            <div class="w-100">
              <img :src="product.image.thumbnail" alt="" class="w-100" />
            </div>
            <div class="description">
              <h4 class="price">
                ₹ {{ product.max_price ? product.max_price.toLocaleString("en-IN") :'' }}
              </h4>
              <h3 class="productname">{{ product.name }}</h3>
            </div>
            <div class="orderButton d-flex justify-content-center">
              <div
                class="d-flex align-items-center justify-content-between w-100"
                v-if="product.in_stock"
              >
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-danger"
                  style="font-size: 30px; padding-top: 3px; padding-bottom: 3px"
                  :disabled="product.count && product.count > 0?false:true"
                  @click="handleRemove(product, index)"
                >
                  -
                </b-button>
                <span style="font-size: 15px">
                  {{ product.count ? product.count : "Add" }}
                </span>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-success"
                  style="font-size: 30px; padding-top: 3px; padding-bottom: 3px"
                  :disabled="product.count >= product.in_stock"
                  @click="handleAdd(product, index)"
                >
                  +
                </b-button>
              </div>
              <div v-else>
                <b-badge variant="light-danger" class="outofstock">
                  OUT OF STOCK
                </b-badge>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div class="d-flex justify-content-end flex-wrap">
      <!-- <div class="d-flex align-items-center mb-0 mt-1">
        <b-form-select
          v-model="pageLength"
          :options="['5', '10', '20', '50', '100', '500']"
          class="mx-1"
          @input="changePagination()"
        />
        <span class="text-nowrap">
          Showing {{ paginationStart }} to {{ paginationEnd }} of
          {{ paginationTotal }}</span
        >
      </div> -->
      <div>
        <b-pagination
          v-model="paginationValue"
          :total-rows="paginationTotal"
          :per-page="pageLength"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @input="changePagination('change')"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
    <div class="customizer d-md-block" :class="{ open: isCustomizerOpen }">
      <div
        class="customizer-toggle totalItems cursor-pointer"
        v-if="selectedItems.items.length > 0 && !isCustomizerOpen"
        @click="isCustomizerOpen = !isCustomizerOpen"
      >
        <div class="d-flex justify-content-around align-items-center">
          <feather-icon icon="ShoppingBagIcon" size="21" />
          <div>{{ selectedItems.items.length }} Items</div>
        </div>
        <div class="totalPrice">
          ₹ {{ selectedItems.totalPrice?selectedItems.totalPrice.toLocaleString("en-IN"):'' }}
        </div>
      </div>
      <div
        class="customizer-section d-flex justify-content-between align-items-center p-2"
      >
        <div
          class="d-flex justify-content-around align-items-center font-weight-bolder"
        >
          <feather-icon icon="ShoppingBagIcon" size="21" />
          <div class="ml-2" style="font-size: 18px">
            {{ selectedItems.items.length }} Items
          </div>
        </div>
        <feather-icon
          icon="XIcon"
          size="18"
          class="cursor-pointer"
          @click="isCustomizerOpen = !isCustomizerOpen"
        />
      </div>
      <!-- Header -->

      <div style="height: 89vh; overflow: auto">
        <div class="selectedItems">
          <div class="">
            <div
              v-for="(item, index) in selectedItems.items"
              :key="item.id"
              class="d-flex selectedItem"
            >
              <div
                class="d-flex flex-column align-items-center justify-content-between"
                style="width: 12%"
              >
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-danger"
                  style="font-size: 30px; padding-top: 3px; padding-bottom: 3px"
                  :disabled="item.count <= 0"
                  @click="handleRemoveSelected(item, index)"
                >
                  -
                </b-button>
                <span style="font-size: 15px">
                  {{ item.count ? item.count : "Add" }}
                </span>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-success"
                  style="font-size: 30px; ; padding-top: 3px; padding-bottom: 3px"
                  :disabled="item.count >= item.in_stock"
                  @click="handleAddSelected(item, index)"
                >
                  +
                </b-button>
              </div>
              <div style="width: 20%" class="h-100 d-flex align-items-center">
                <img :src="item.image.thumbnail" alt="" class="w-100" />
              </div>
              <div style="width: 40%; padding: 10px">
                <h5>
                  {{ item.name }}
                </h5>
                <h5 class="font-weight-bolder">
                  ₹ {{ item.max_price ? item.max_price.toLocaleString("en-IN"):'' }}
                </h5>
              </div>
              <div
                style="width: 20%"
                class="d-flex align-items-center justify-content-center"
              >
                <h4>
                  ₹ {{ ((item.max_price?item.max_price:0)  * (item.count?item.count:0)).toLocaleString("en-IN") }}
                </h4>
              </div>
              <div style="width: 10%" class="d-flex align-items-center">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-danger"
                  style="font-size: 20px; padding:10px;border-radius:50%"
                  :disabled="item.count <= 0"
                  @click="handleRemoveSelectedItem(item, index)"
                >
                  x
                </b-button>
              </div>
            </div>
          </div>
          <div class="checkout d-flex justify-content-center w-100" >
            <div style="width:80%" >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                class="d-flex justify-content-between align-items-center"
                style="padding:2px; border-radius:25px"
              >
                <div class="w-50">Check out</div>
                <div class="checkOutPrice w-50">
                  ₹ {{ selectedItems.totalPrice }}
                </div>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReportTable from "../../components/ReportTable.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import axios from "@/components/axios";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import {
  BFormInput,
  BFormGroup,
  BInputGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BInputGroupAppend,
  BPagination,
  BFormSelect,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

import vSelect from "vue-select";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    VuePerfectScrollbar,
    BInputGroup,
    BInputGroupAppend,
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BFormInput,
    Form,
    BPagination,
    BFormSelect,
    BBadge,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      search: "",
      pageLength: 10,
      paginationStart: 1,
      paginationEnd: 1,
      paginationTotal: 1,
      paginationValue: 1,
      allProducts: [],
      isCustomizerOpen: false,
      selectedItems: {
        items: [],
        totalPrice: 0,
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    };
  },
  mounted() {
    this.getAllProducts();
  },
  methods: {
    handleRemoveSelectedItem(item, index) {
      let idx = this.allProducts.indexOf(item);
      this.selectedItems.items.splice(index, 1);
      this.selectedItems.totalPrice -= (item.max_price * item.count);
      if (this.selectedItems.items.length == 0) {
        this.isCustomizerOpen = false;
      }
      this.allProducts[idx].count = 0;
      this.$forceUpdate();
    },
    handleRemove(item, index) {
      let idx = this.selectedItems.items.indexOf(item);
      if (item.count > 1) {
        this.selectedItems.items[idx].count--;
      } else {
        this.selectedItems.items.splice(idx, 1);
        this.allProducts[index].count = 0;
      }
      this.selectedItems.totalPrice -= item.max_price;
      this.$forceUpdate();
    },
    handleAddSelected(item, index) {
      this.selectedItems.items[idx].count++;
      this.selectedItems.totalPrice += item.max_price;
      this.$forceUpdate();
    },
    handleRemoveSelected(item, index) {
      let idx = this.allProducts.indexOf(item);
      if (item.count > 1) {
        this.allProducts[idx].count--;
      } else {
        this.selectedItems.items.splice(index, 1);
        this.allProducts[idx].count = 0;
      }
      this.selectedItems.totalPrice -= item.max_price;

      if (this.selectedItems.items.length == 0) {
        this.isCustomizerOpen = false;
      }
      this.$forceUpdate();
    },
    handleAdd(item, index) {
      let idx = this.selectedItems.items.indexOf(item);
      if (idx == -1) {
        item.count = 1;
        this.selectedItems.items.push(item);
      } else {
        this.selectedItems.items[idx].count++;
      }
      this.selectedItems.totalPrice += item.max_price;
      this.$forceUpdate();
    },
    changePagination() {
      this.getAllProducts();
    },
    async getAllProducts() {
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/products?page=${this.paginationValue}&limit=${this.pageLength}`,
      };
      await axios(request).then((res) => {
        this.allProducts = res.data.data;
        this.paginationTotal = res.data.total;
        this.paginationStart = res.data.from;
        this.paginationEnd = res.data.to;
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
<style scoped>
.productname {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;
}
.description {
  padding: 10px;
}
.price {
  color: black;
}
.outofstock {
  height: 30px;
  display: grid;
  place-items: center;
}
.totalItems {
  background: #7367f0;
  width: 120px;
  height: 100px;
  color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  left: -120px;
  font-weight: bolder;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.totalPrice {
  height: 50px;
  background: white;
  color: #7367f0;
  font-weight: bolder;
  border-radius: 10px;
  display: grid;
  place-items: center;
}
.order .customizer.open {
  right: 0;
}
.order .customizer {
  width: 500px;
  right: -500px;
}
@media (max-width:499px){
  .order .customizer {
    width: 100vw;
    right: -100vw;
  }
}
.selectedItems {
  padding: 2px;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  align-items: center;
  height: 100%;
}
.selectedItem {
  margin-top: 10px;
  display: flex;
}
.checkOutPrice{
  background: white;
  color:#7367f0;
  height:40px;
  border-radius:25px;
  display: grid;
  place-items:center;
  font-size:20px
}
</style>

