export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    route: 'dashboard-analytics',
    tagVariant: 'light-warning',
    // children: [
    //   {
    //     title: 'eCommerce',
    //     route: 'dashboard-ecommerce'
    //   },
    //   {
    //     title: 'Analytics',
    //     route: 'dashboard-analytics'
    //   }
    // ]
  }
]
