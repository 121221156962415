<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>

          <!-- <b-col md="4">
            <b-form-group>
              <label>Groups</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Group"
              >
                <v-select
                  v-model="groups"
                  placeholder="None"
                  label="name"
                  @input="getCategories"
                  :clearable="false"
                  :options="groupsOption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="4" class="categoryIndex">
            <b-form-group>
              <label>Categories</label>
              <label style="color: red !important">*</label>
              <v-select

                v-model="categories"
                placeholder="None"
                multiple
                label="name"
                :options="CategoriesOptions"
                @input="handleSubCategory($event)"
              />

              <small class="text-danger" v-if="category_message">Please select at least one category</small>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Sub Category</label>
              <v-select
                v-model="subCategory"
                placeholder="None"
                label="name"
                :options="subCategoriesOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Edits</label>
              <v-select
                v-model="edits"
                placeholder="None"
                label="name"
                :options="editsOptions"
                multiple
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Name"
              >
                <b-form-input v-model="name" placeholder="Enter Name"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="4">
              <b-form-group>
                <label>CGST (%)</label>
                  <b-form-input v-model="cgst" placeholder="Enter CGST"/>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label>SGST</label>
                  <b-form-input v-model="sgst" placeholder="Enter SGST"/>
              </b-form-group>
            </b-col>
             <b-col md="4">
                <b-form-group>
                  <label>IGST</label>
                    <b-form-input v-model="igst" placeholder="Enter IGST"/>
                </b-form-group>
              </b-col> -->
          <!-- <b-col v-if="ifEdit" md="4">
            <b-form-group>
              <label>Slug</label>
              <b-form-input v-model="slug" disabled placeholder="Slug"/>
            </b-form-group>
          </b-col> -->
          <b-col md="4">
            <b-form-group>
              <label>Size</label>
                <b-form-input v-model="size" placeholder="Size"/>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Description</label>
              <b-form-textarea v-model="description" placeholder="Description"/>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>You Want to add this product in new?</label>

              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="You Want to add this product in new?"
              >
                <v-select
                  v-model="itIsNew"
                  placeholder="None"
                  :options="['Yes', 'No']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Price</label>

                <label style="color: red !important">*</label>
                <validation-provider
                  #default="{ errors }"
                  rules="integer|required"
                  name="Price"
                >
                <b-form-input v-model="price" placeholder="Enter Price" type="number" @input='handleSellPrice($event)'/>
                 <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Sale Price</label>
              <label style="color: red !important">*</label>
                <validation-provider
                  #default="{ errors }"
                  rules="integer|required"
                  name="Sale Price"
                >
                <b-form-input v-model="sale_price" placeholder="Enter Sales Price"
                  type="number"/>
                 <small class="text-danger">{{ errors[0] }}</small>
                   </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Quantity</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required|integer|min_value:0"
                name="Quantity"
              >
                <b-form-input v-model="quantity" required placeholder="Enter Quantity"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Code</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Code"
              >
                <b-form-input v-model="code" placeholder="Enter Code"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
              <b-form-group>
                <label>HSN Code</label>
                  <b-form-input v-model="hsncode" placeholder="Enter Code"/>
              </b-form-group>
            </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Weight</label>
              <validation-provider
                #default="{ errors }"
                rules="integer"
                name="Weight"
              >
                <b-form-input v-model="weight" placeholder="Enter Weight"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" class="d-flex align-items-center">
            <b-form-checkbox v-model="is_bestseller" inline>
                  Is best seller?
            </b-form-checkbox>
            <!-- <b-form-checkbox v-if="is_bestseller" v-model="is_topfive" inline>
                    Top 5?
              </b-form-checkbox> -->
          </b-col>
           <b-col md="4" v-if="is_bestseller">
              <b-form-group>
                <label>Order In Top 5</label>
                  <b-form-input v-model="bestseller_order" placeholder="Enter Order Number"/>
              </b-form-group>
            </b-col>
            <b-col md="12" class="mt-2">
              <b-form-group>
                <label>Shipping Details</label>
                <quill-editor v-model="shipping_product" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <label>Featured Image</label>
              <div  @click="openmodal('featuredImage')" class="fileuploader cursor-pointer d-flex align-items-center" style="border:1px solid silver;padding:10px;border-radius:5px;display:flex;justify-content:space-between">
                <div><feather-icon icon="ImageIcon" size="20" /></div>
                <div>Browse</div>
              </div>
              <attachment v-if="featuredImage && (featuredImage.wep_thumbnail || featuredImage.thumbnail )" :data="featuredImage.wep_thumbnail ? featuredImage.wep_thumbnail : featuredImage.thumbnail" />
              <gallary-image-model v-if="modelValue" :modelValue="modelValue" :openmodal="openmodal" @setImage="setImage" :selectedIndex="selectedIndex"/>
            </b-col>

            <b-col md="4">
              <b-form-group>
                <label>Meta Title</label>
                  <b-form-input v-model="meta_title" placeholder="Enter Meta Title"/>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group>
                <label>Meta Description</label>
                  <b-form-input v-model="meta_description" placeholder="Enter Meta Description"/>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-card-code title="Gallery" no-body />
            </b-col>
            <b-col md="12">
              <div style="width: 100%; overflow: auto; max-height: 500px">
                <table
                  class="table table-bordered"
                  style="min-width: 15cm; max-height: 15cm"
                >
                  <thead class="text">
                    <tr>
                      <th></th>
                      <th>Images</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, id) in gallery"
                      :key="id"
                      style="max-height: 100px"
                    >
                      <td>{{ id + 1 }}</td>
                      <td style="max-height: 50px">
                        <!-- <b-form-file
                        v-model="item.thumbnail"
                        placeholder="Select Image"
                        drop-placeholder="Drop file here..."
                        @input="handleFileChange($event, 'gallery', id + 1)"
                      /> -->
                        <div  @click="openmodal('gallery', id + 1)" class="fileuploader cursor-pointer d-flex align-items-center" style="border:1px solid silver;padding:10px;border-radius:5px;display:flex;justify-content:space-between">
                          <div><feather-icon icon="ImageIcon" size="20" /></div>
                          <div>Browse</div>
                        </div>
                        <attachment v-if="item && (item.webp_thumbnail || item.thumbnail)" :data="item.webp_thumbnail ? item.webp_thumbnail : item.thumbnail" />
                      </td>
                      <td>
                        <b-button
                          variant="outline-danger"
                          @click="removeGalleryRow(id)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <b-button variant="primary" @click="addGalleryRow" class="my-1">
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add More</span>
              </b-button>
            </b-col>
          <!-- submit button -->
          <b-col cols="12" class="mt-1">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              class="mr-4"
              :disabled="check"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import gallaryImageModel from "../../components/gallaryImageModel.vue";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import { quillEditor } from "vue-quill-editor";

import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../components/Attechment.vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";

export default {
  components: {
    flatPickr,
    quillEditor,
    BFormCheckboxGroup,
    gallaryImageModel,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BFormCheckbox,
  },
  data() {
    return {
      required,
      subCategoriesOptions: [],
      gallery: [
        {
          original: "",
          thumbnail: "",
          file_name: "",
          id: "",
        },
      ],
      is_bestseller:false,
      modelValue:false,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      featuredImage: {
        original: "",
        thumbnail: "",
        file_name: "",
        id: "",
      },
      groups: "",
      categories: [],
      name: "",
      slug: "",
      size: "",
      ifEdit: false,
      check: false,
      description: "",
      itIsNew: "",
      price: "",
      sale_price: "",
      quantity: "",
      code: "",
      hsncode:'7113',
      weight: "",
      groupsOption: [],
      CategoriesOptions: [],
      subCategory: "",
      editsOptions: [],
      edits: '',
      selectedField: '',
      selectedIndex: false,
      selected: '',
      is_new: false,
      shipping_product: '',
      cgst:0,
      sgst:0,
      igst: 0,
      category_message: false,
      bestseller_order: '',
      is_topfive: false,
      meta_description:'',
      meta_title: '',
      sale_price:'',
    };
  },
  async mounted() {
    await this.getCategories();
    this.ifEdit = !!this.$route.params.slug;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    handleSellPrice(e){
      this.sale_price =  parseFloat(e)
      this.$forceUpdate()
    },
    openmodal(name,index, field) {
      this.modelValue = !this.modelValue
      this.selected = name
      this.selectedField = field ? field:false
      this.selectedIndex = index
      this.$forceUpdate()
    },
    setImage(image) {
      if (this.selectedIndex  && this.selectedField) {
        image.map((item, index) => {
          if (!this[this.selected][this.selectedIndex - 1 + index]) {
            this[this.selected].push({ ...this[this.selected][0] })
            for (let key in this[this.selected][0]) {
              this[this.selected][this.selectedIndex - 1 + index][key] = ''
            }
          }
          this[this.selected][this.selectedIndex - 1 + index][this.selectedField] = item
        })
      }
      else if (this.selectedIndex) {
        image.map((item, index) => {
          if (!this[this.selected][this.selectedIndex - 1 + index]) {
            this[this.selected].push({})
          }
          this[this.selected][this.selectedIndex-1 + index] = item
        })
      }
      else {
        this[this.selected] =image
      }
    },
    handleSubCategory($event) {
      this.subCategoriesOptions = [];
      $event.map((item) => {
        if (item.children) {
          this.subCategoriesOptions = [
            ...this.subCategoriesOptions,
            ...item.children,
          ];
        }
      });

      if (this.categories.length == 0) {
        this.category_message=true
      }
      else {
        this.category_message = false
      }
    },
    async getEditValue() {
      // console.log(this.$route.params.id, "id====");
      await axios({
        method: "GET",
        url: `${this.baseApi}/products/${this.$route.params.slug}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.id = item.id;
      this.categories = item.categories;
      (this.description = item.description),
      (this.gallery = item.gallery ? item.gallery : []),
      (this.featuredImage = item.image),
      (this.name = item.name),
      (this.slug = item.slug),
      (this.price = item.price),
      (this.quantity = item.quantity),
      (this.sale_price = item.sale_price ?item.sale_price:item.price),
        (this.code = item.sku),
      this.hsncode = item.hsncode,
      (this.itIsNew = item.status == "draft" ? "Yes" : "No");
      this.groups = item.type;
      (this.size = item.size),
      (this.weight = item.weight);
      this.is_bestseller =item.is_bestseller?true: false
      this.falseis_new = item.falseis_new ? true : false
      this.shipping_product = item.shipping_product;
      this.bestseller_order = item.bestseller_order
      this.meta_description = item.meta_description
      this.meta_title = item.meta_title
      // this.cgst = item.cgst;
      // this.sgst = item.sgst;
      // this.igst = item.igst;
      this.getSubCategory();

       setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },

    getSubCategory() {
      let categories = [];
      this.edits = [];
      this.categories.map((item) => {
        if (!item.parent && !item.edits ) {
          categories.push(item);
        }
        else if (!item.edits) {
          this.subCategory = item;
        }
        if (item.edits) {
          this.edits.push(item)
        }
      });
      this.categories = categories;
      this.CategoriesOptions.map((item) => {
        this.categories.map((inner) => {
          if (inner.id == item.id) {
            this.subCategoriesOptions=[...this.subCategoriesOptions,...item.children]
          }
        })
      })
    },
    submitForm() {
      if (this.categories.length == 0) {
        this.category_message= true
      }
      else {
        this.category_message = false
      }
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          let categories = this.categories.map((i) => {
            return i.id;
          });
          if (this.subCategory && this.subCategory.id) {
            categories.push(this.subCategory.id);
          }
          if (this.edits && this.edits.length > 0) {
            this.edits.map((item) => {
              categories.push(item.id);
            })
          }

          data = {
            categories: categories,
            description: this.description,
            gallery: this.gallery,
            image: this.featuredImage,
            name: this.name,
            price: this.price,
            quantity: this.quantity,
            sale_price: this.sale_price,
            sku: this.code,
            hsncode:this.hsncode,
            status: this.itIsNew == "Yes" ? "draft" : "publish",
            type_id: this.groups?this.groups.id:8,
            size: this.size,
            weight: this.weight,
            is_bestseller:this.is_bestseller?true:false,
            falseis_new: this.falseis_new ? true : false,
            shipping_product: this.shipping_product,
            bestseller_order: this.is_bestseller ? this.bestseller_order : '',
            meta_description:this.meta_description,
            meta_title:this.meta_title,
            // cgst:this.cgst,
            // sgst:this.sgst,
            // igst:this.igst,
          };
          if (this.ifEdit) {
            data.slug = this.convertToSlug(this.name);
          }
        }
        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false || this.categories.length == 0) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success && this.categories.length != 0) {
          this.check = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/products/${this.$route.params.slug}`
              : `${baseApi}/products`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.check = false;
              this.$router.push(`/edit-product/${json.data.id}`)
              this.ifEdit=true
              this.$forceUpdate()
              this.$swal({
                title: "Submited",
                text: "Successfully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              // this.$router.push("/product");
            })
            .catch((error) => {
              let html = ''
              if (error.response && error.response.data) {
                Object.values(error.response.data).map((item) => {
                  item.map((inner) => {
                    html+=`${inner} \n`
                  })
                })
              }
              this.check = false;

              this.$swal({
                title: "Error!",
                // text: `${error}`,
                html: html,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    // async getGroups() {
    //   const request = {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${this.accessToken}`,
    //     },
    //     url: `${this.baseApi}/types`,
    //   };
    //   axios(request).then((json) => {
    //     console.log(json);
    //     this.groupsOption = json.data;
    //   });
    // },
    async getCategories() {
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/categories?limit=1000`,
      };
      await axios(request).then((json) => {
        let CategoriesOptions = json.data.data;
        this.editsOptions = []
        this.CategoriesOptions = []
        CategoriesOptions.map((item) => {
          if (item.edits) {
            this.editsOptions.push(item)
          }
          else if (!item.parent) {
            this.CategoriesOptions.push(item)
          }
          if (item.children && item.children.length > 0) {
            item.children.map((child) => {
              if (child.edits) {
                this.editsOptions.push(child)
              }
            })
          }
        })



      });
    },
    removeGalleryRow(index) {
      this.gallery.splice(index, 1);
    },
    addGalleryRow() {
      this.gallery.push({
        original: "",
        thumbnail: "",
        file_name: "",
        id: "",
      });
    },
    async handleFileChange(e, name, id, idName) {
      this.check = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("attachment[]", e);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachments`,
      };
      await axios(requestOptions)
        .then((response) => {
          if (id) {
            this[name][id - 1].thumbnail = response.data[0].thumbnail;
            this[name][id - 1].file_name = e.name;
            this[name][id - 1].id = response.data[0].id;
            this[name][id - 1].original = response.data[0].original;
          } else {
            this[name].thumbnail = response.data[0].thumbnail;
            this[name].file_name = e.name;
            this[name].id = response.data[0].id;
            this[name].original = response.data[0].original;
          }
          this.check = false;
        })
        .catch((error) => {
          this.check = false;
        });
    },
    onClickBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";

</style>



