<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <!-- <b-col md="12">
            <b-card-code title="Information" no-body />
          </b-col> -->
          <b-col md="4">
            <b-form-group>
              <label>Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Name"
              >
                <b-form-input v-model="name" placeholder="Enter Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Rate</label>
                <b-form-input v-model="rate" placeholder="Enter Rate" type="number"/>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Country</label>
              <b-form-input v-model="country" placeholder="Enter Country Name" @input="handleCountry"/>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>City</label>
              <b-form-input v-model="city" placeholder="Enter City"/>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>State</label>
              <b-form-input v-model="state" placeholder="Enter State" @input="handleState"/>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>ZIP</label>
              <b-form-input v-model="zip" placeholder="Enter ZIP"/>
            </b-form-group>
          </b-col>
            <b-col md="4">
              <b-form-group>
                <label>IGST %</label>
                <label style="color: red !important">*</label>
                <validation-provider #default="{ errors }" rules="required" name="IGST">
                  <b-form-input v-model="igst" placeholder="Enter IGST"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label>CGST %</label>
                <b-form-input v-model="cgst" placeholder="Enter CGST"/>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label>SGST %</label>
                <b-form-input v-model="sgst" placeholder="Enter SGST"/>
              </b-form-group>
            </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              class="mr-4"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../components/Attechment.vue";
import {
  required,
} from "@validations";

export default {
  components: {
    flatPickr,
    BFormCheckboxGroup,

    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BFormCheckbox,
  },
  data() {
    return {required,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      name: '',
      rate: 0,
      state: '',
      country: '',
      city: '',
      zip: '',
      ifEdit: false,
       igst: '',
      cgst: '',
      sgst: ''
    };
  },
  async mounted() {
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    handleState() {
      if (this.state.toLocaleLowerCase() == 'gujarat') {
        this.sgst = 1.5
        this.cgst = 1.5
        this.igst = ''
      }
      else {
        this.igst = 3
      }
    },
    handleCountry() {
      if (this.country.toLocaleLowerCase() != 'india') {
        this.igst = 2
      }
      else {
        this.igst = ''
      }
    },
    async getEditValue() {
      // console.log(this.$route.params.id, "id====");
      await axios({
        method: "GET",
        url: `${this.baseApi}/taxes/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.id = item.id;
      this.name = item.name;
      this.city = item.city;
      this.state = item.state;
      this.country = item.country;
      this.zip = item.zip;
      this.rate = item.rate;
      this.igst = item.igst;
      this.cgst = item.cgst;
      this.igst = item.igst;
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          data = {
            name: this.name,
            rate: this.rate,
            zip: this.zip,
            city: this.city,
            state: this.state,
            country: this.country,
            igst: this.igst,
            cgst: this.cgst,
            sgst: this.sgst,
          };
        }

        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/taxes/${this.id}`
              : `${baseApi}/taxes`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.$swal({
                title: "Submited",
                text: "Successfully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/taxes");
            })
            .catch((error) => {

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    onClickBack() {
      this.$router.push("/taxes");
    },
  },
};
</script>
