<template>
  <div>
    <b-card>
      <b-row class="justify-content-end order">
        <b-col md="6" class="mt-1">
          <b-input-group class="input-group-merge">
            <b-form-input id="search" v-model="searchTerm" class="form-control-merge" @input="searchApply" type="text" />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <div class="col-6 mt-1">
          <b-button variant="primary" @click="$router.push('/add-shipping')" class="btn btn-primary float-right mr-1">
            Add Shipping
          </b-button>
        </div>
      </b-row>
    </b-card>

    <vue-good-table class="table" :columns="fields" :rows="allData" :rtl="direction" :isLoading="loadData"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: false,
        selectAllByGroup: false,

      }" :pagination-options="pagination">
      <template slot="table-row" slot-scope="props">

        <span v-if="props.column.field === 'action'" style="width: fit-content; display: flex;" class='position-relative'>
          <b-badge variant="warning" :id="'edit' + props.row.id" class="mr-50 cursor-pointer"
            @click="handleEdit(props.row)">
            <feather-icon icon="Edit2Icon" />
          </b-badge>
          <b-badge variant="danger" :id="'delete' + props.row.id" class="cursor-pointer" @click="handleDelete(props.row)">
            <feather-icon icon="TrashIcon" />
          </b-badge>
          <b-tooltip placement="top"  :target="'edit' + props.row.id" triggers="hover">
            Edit
          </b-tooltip>
          <b-tooltip  placement="top"  :target="'delete' + props.row.id" triggers="hover">
            Delete
          </b-tooltip>
        </span>

        <div v-else>
          {{ props.row[props.column.field] }}
        </div>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
          </div>
          <div>
            <b-pagination v-model="paginationValue" :total-rows="paginationTotal" :per-page="pageLength" first-number
              last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
              @input="changePagination('change')">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import axios from '@/components/axios';
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";

import moment from "moment";
import { VueGoodTable } from "vue-good-table";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormSelect,
  BPagination,
  BButton,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
  BFormDatepicker,
  BImg,
  BImgLazy,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BTooltip,

} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormGroup,
    BForm,
    VueGoodTable,
    BRow,
    BBadge,
    BFormInput,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BCol,
    BImg,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BTooltip,

  },
  data() {
    return {

      loadData: true,
      pagination: {
        enabled: true,
        mode: 'records',
        perPage: 500,
        position: 'bottom',
        perPageDropdown: [3, 7, 9],
        dropdownAllowAll: false,
        setCurrentPage: 2,
        jumpFirstOrLast: true,
        firstLabel: 'First Page',
        lastLabel: 'Last Page',
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page', // for 'pages' mode
        allLabel: 'All',
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`,
      },
      pageLength: 50,
      paginationStart: 0,
      category: '',
      paginationValue: 1,
      paginationTotal: 0,
      paginationEnd: 0,
      searchTerm: '',
      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "name",
          label: "Name"
        },
        {
          field: "from",
          label: "From",
        },
        {
          field: "to",
          label: "To",
        },
        {
          field: "charge",
          label: "Charges",
        },
        {
          field: "action",
          label: "Actions",
        },
      ],
      allData: [],
      pageLength: 20,
      paginationStart: 0,
      paginationValue: 1,
      paginationTotal: 0,
      paginationEnd: 0,
      params: '',

    };
  },
  computed: {

    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    }
  },
  mounted() {
    this.getAllData();
  },
  methods: {
    searchApply: _.debounce(function () {
      this.params = `&searchJoin=and&search=name:${this.searchTerm}`;
      this.getAllData();
    }, 1000),
    handleEdit(row) {
      this.$router.push(`/edit-shipping/${row.id}`)
    },
    handleDelete(row) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            const request = {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
              url: `${this.baseApi}/shippings/${row.id}`,
            };
            axios(request).then((json) => {
              this.getAllData();
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "Your data has been deleted.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });

          }
        })
        .catch((error) => console.log(error, "error"));

    },
    async ToggleChanged(id, e) {
      let tmpData = this.allData.findIndex((item) => item.id == id)
      await this.Setting();
      const request = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: {
          is_approved: e,
          name: tmpData.name,
          language: tmpData.image,
          description: tmpData.description,

        },
        url: `${this.baseApi}/shippings/${id}`,
      };
      await axios(request).then((json) => {
        this.getAllData();
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Successfully Updated`,
            icon: "CheckIcon",
            variant: "success",
            text: `You have successfully Changed Status of ${json.data.name}.`,
          },
        });
      });
    },
    changePagination() {
      this.getAllData();
    },
    async getAllData() {
      await this.Setting();
        this.loadData = true;

      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/shippings?page=${this.paginationValue}&limit=${this.pageLength}${this.params}`,
      };
      await axios(request).then((json) => {
        this.allData = json.data;
        // this.paginationTotal = json.data.total
        // this.paginationStart = json.data.from
        // this.paginationEnd = json.data.to
      })
        .finally((res) => {
          this.loadData = false
        })
    },
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
