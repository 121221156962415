<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Card Image</label>
              <!-- <b-form-file
                v-model="image.thumbnail"
                placeholder="Select Image... "
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFileChange($event, 'image')"
              /> -->
              <div  @click="openmodal('image')" class="fileuploader cursor-pointer d-flex align-items-center" style="border:1px solid silver;padding:10px;border-radius:5px;display:flex;justify-content:space-between">
                    <div><feather-icon icon="ImageIcon" size="20" /></div>
                    <div>Browse</div>
                  </div>
            </b-form-group>
            <attachment v-if="image && image.thumbnail"  :data="image.thumbnail" />
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Banner Image</label>
              <!-- <b-form-file
                v-model="cover_image.thumbnail"
                placeholder="Select Image... "
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFileChange($event, 'cover_image')"
              /> -->
              <div  @click="openmodal('cover_image')" class="fileuploader cursor-pointer d-flex align-items-center" style="border:1px solid silver;padding:10px;border-radius:5px;display:flex;justify-content:space-between">
                    <div><feather-icon icon="ImageIcon" size="20" /></div>
                    <div>Browse</div>
                  </div>
            </b-form-group>
            <attachment  v-if="cover_image && cover_image.thumbnail" :data="cover_image.thumbnail" />
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Name</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }"  rules="required" name="Name">
                  <b-form-input v-model="name"  placeholder="Enter Name"/>
                  <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label>Description</label>
              <quill-editor v-model="blog_data" />
            </b-form-group>
          </b-col>

            <b-col md="6">
              <b-form-group>
                <label>Meta Title</label>
                <b-form-input v-model="meta_title"  placeholder="Meta Title"/>
              </b-form-group>
            </b-col>

              <b-col md="6">
                <b-form-group>
                  <label>Meta Keyword</label>
                  <b-form-input v-model="meta_keyword"  placeholder="Meta Keyword"/>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group>
                  <label>Meta Description</label>
                  <b-form-input v-model="meta_description"  placeholder="Meta Description"/>
                </b-form-group>
              </b-col>
          <!-- submit button -->
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              class="mr-4"
              :disabled="check"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
     <gallary-image-model v-if="modelValue" :modelValue="modelValue" :openmodal="openmodal" @setImage="setImage"/>

  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";
import gallaryImageModel from "../../components/gallaryImageModel.vue";


import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../components/Attechment.vue";
import {
  required,
} from "@validations";
export default {
  components: {
    quillEditor,
    flatPickr,
    BFormCheckboxGroup,
    gallaryImageModel,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BFormCheckbox,
  },
  data() {
    return {
      required,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      cover_image: {
        id: "",
        thumbnail: "",
        original: "",
      },
      image: {
        id: "",
        thumbnail: "",
        original: "",
      },
      name: "",
      blog_data: "",
      check: false,
      ifEdit: false,
      id: '',
      selectedField: '',
      selectedIndex: false,
      selected: '',
      modelValue: '',
      meta_description:'',
      meta_title:'',
      meta_keyword: '',
    };
  },
  async mounted() {
    this.ifEdit = !!this.$route.params.slug;
    this.ifEdit && this.getEditValue();
    this.getType()
  },
  methods: {
    openmodal(name, index, field) {
      this.modelValue = !this.modelValue
      this.selected = name
      this.selectedField = field ? field : false
      this.selectedIndex = index
      this.$forceUpdate()
    },
    setImage(image) {
      if (this.selectedIndex && this.selectedField) {
        this[this.selected][this.selectedIndex - 1][this.selectedField] = image
      }
      else if (this.selectedIndex) {
        this[this.selected][this.selectedIndex - 1] = image
      }
      else {
        this[this.selected] = image
      }
    },
    async getType() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/types?searchJoin=and&limit=200&language=en&search=`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.type_id=res.data[0].id
          }
          else {
            this.type_id=8
          }
      })
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/manufacturers/${this.$route.params.slug}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.cover_image = item.cover_image;
      this.image = item.image;
      this.name = item.name;
      this.blog_data = item.blog_data
      this.id = item.id
      this.meta_description = item.meta_description
      this.meta_title = item.meta_title
      this.meta_keyword = item.meta_keyword

        setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);

    },
     convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          data = {
            cover_image : this.cover_image,
            image : this.image,
            name : this.name,
            blog_data: this.blog_data,
            type_id: this.type_id ? this.type_id : 8,
            meta_description:this.meta_description,
            meta_title:this.meta_title,
            meta_keyword:this.meta_keyword,

          };
          if (this.ifEdit) {
            data.slug= this.convertToSlug(this.name)
          }
        }
        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.check = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/manufacturers/${this.id}`
              : `${baseApi}/manufacturers`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.check = false;
              this.$swal({
                title: "Submited",
                text: this.ifEdit?"Successfully updated":"Successfully added",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push('/gazette');
            })
            .catch((error) => {
              this.check = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    async handleFileChange(e, name) {
      this.check = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("attachment[]", e);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachments`,
      };
      await axios(requestOptions)
        .then((response) => {
            this[name].thumbnail = response.data[0].thumbnail;
            this[name].file_name = e.name;
            this[name].id = response.data[0].id;
            this[name].original = response.data[0].original;
          this.check = false;
        })
        .catch((error) => {
          this.check = false;
        });
    },
    onClickBack() {
      this.$router.push("/gazette");
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>

