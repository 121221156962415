<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="6">
            <b-form-group style="margin:15px">
              <label>Name</label>
              <b-form-input v-model="name" placeholder="Enter Name" />
            </b-form-group>
          </b-col>


          <b-col md="6">
            <b-form-group style="margin:15px">
              <label>From</label>
              <b-form-input type="number" v-model="from" placeholder="Enter Amount" />
            </b-form-group>
          </b-col>
          <b-col md="6">
              <b-form-group style="margin:15px">
                <label>To</label>
                <b-form-input type="number" v-model="to" placeholder="Enter Amount" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group style="margin:15px">
                <label>Charges</label>
                <b-form-input type="number" v-model="charge" placeholder="Enter Charges" />
              </b-form-group>
            </b-col>


          <!-- <b-col md="6" >
            <b-form-group style="margin-left:20px">
              <label >Type</label> <br>
              <b-form-radio v-model="type" danger value="free">Free</b-form-radio> <br>
              <b-form-radio v-model="type" danger value="fixed">Fixed</b-form-radio> <br>
              <b-form-radio v-model="type" danger value="percentage">Percentage</b-form-radio> <br>
            </b-form-group>
          </b-col> -->

          <!-- <b-col md="12">
              <b-card-code title="Charges" no-body />
            </b-col> -->
            <!-- <b-col md="12">
              <div style="width: 100%; overflow: auto; max-height: 500px">
                <table
                  class="table table-bordered"
                  style="min-width: 15cm; max-height: 15cm"
                >
                  <thead class="text">
                    <tr>
                      <th></th>
                      <th>From</th>
                      <th>To</th>
                      <th>Charges</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, id) in charges"
                      :key="id"
                      style="max-height: 100px"
                    >
                      <td>{{ id + 1 }}</td>
                      <td>
                        <b-form-input
                          v-model="item.from"
                        />
                      </td>
                      <td>
                          <b-form-input
                            v-model="item.to"
                          />
                        </td>
                      <td>
                          <b-form-input
                            v-model="item.charges"
                          />
                        </td>
                      <td>
                        <b-button
                          variant="outline-danger"
                          @click="removeRow(id)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
            <b-button
              variant="primary"
              @click="addRow()"
              class="my-1"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button> -->


          <b-col cols="12" class="mt-2">
            <b-button variant="primary" type="submit" @click.prevent="submitForm" class="mr-4" :disabled="check">
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back</b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";

import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormRadio
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../components/Attechment.vue";

export default {
  components: {
    quillEditor,
    flatPickr,
    BFormCheckboxGroup,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BFormCheckbox,
    BFormRadio
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      name: "",
      amount:"",
      type:"fixed",
      check: false,
      description: "",
      id: '',
      from:0,
      to:0,
      charge:0
    };
  },
  async mounted() {
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/shippings/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.id = item.id,
      this.name = item.name;
      this.type = item.type;
      this.amount = item.amount;
      this.description = item.description;
      this.from = item.from;
      this.to = item.to;
      this.charge = item.charge;

    },
    submitForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          data = {
            type:this.type,
            name: this.name,
            amount:this.amount,
            description: this.description,
            charge:parseFloat(this.charge),
            from: parseFloat(this.from),
            to: parseFloat(this.to)

          };
        }

        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.check = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/shippings/${this.id}`
              : `${baseApi}/shippings`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.check = false;
              this.$swal({
                title: "Submited",
                text: "Successfully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/shipping");
            })
            .catch((error) => {
              this.check = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    onClickBack() {
      this.$router.push('/shipping');
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>

