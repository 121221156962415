<style>
.navbar-container {
  padding: 0.5rem 1rem !important;
}
</style>
<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- <b-link
      class="navbar-brand d-flex"
      to="/"
    >
      <span class="brand-logo">
        <b-img :src="logo" style="width: 40px; height: 40px" alt=" logo" />
      </span>
      <h2 class="brand-text"  :class="isMobile < 500 ? 'd-none' : ''">
        <b-img
          :src="logotxt"
          style="width: 5cm; height: 40px; margin-top: 5px; margin-left: 10px"
          alt=" logo"
        />
      </h2>
    </b-link> -->
    <!-- Nav Menu Toggler -->
    <ul
      class="nav navbar-nav d-xl-none"
    >
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    > -->
      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
    <!-- </div> -->

    <b-navbar-nav class="nav align-items-center ml-auto navCustome">
      <!-- <locale /> -->
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <!-- <search-bar /> -->
      <!-- <cart-dropdown /> -->
      <!-- <notification-dropdown /> -->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BImg,BBadge, BNavbarNav } from "bootstrap-vue";
import Bookmarks from "./components/Bookmarks.vue";
import Locale from "./components/Locale.vue";
import SearchBar from "./components/SearchBar.vue";
import DarkToggler from "./components/DarkToggler.vue";
// import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import axios from '@/components/axios';
import logo from "../../../../../public/logo.png";
import logotxt from "../../../../../src/assets/images/logo/ngerplogotxt.png";

export default {
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      parentName: "",
      logo,
      logotxt,
      isMobile:window.innerWidth
    };
  },
  components: {
    BLink,
    BImg,
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    BBadge,
    DarkToggler,
    // CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
<style>

.navCustome{
  min-height: 40px !important;
}
</style>
