<template>
  <div>
    <b-card>
      <vue-good-table class="table" :columns="fields" :rows="allBackup"
        :isLoading="loadData" :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false,
          selectAllByGroup: false,

        }"
        >
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field === 'action'" >
            <b-badge
              variant="success" :id="'download' + props.row.id" class="mr-50 cursor-pointer" @click="handledownload(props.row.file)">
              <feather-icon icon="ArrowDownIcon" />
            </b-badge>
             <b-tooltip  placement="top"  :target="'download' + props.row.id" triggers="hover">
                    Edit
                  </b-tooltip>
          </div>
          <div v-else>
            {{props.row[props.column.field]}}
          </div>
          </template>
          </vue-good-table>
    </b-card>
  </div>
</template>
<script>
import axios from "@/components/axios";
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import moment from "moment";
import { VueGoodTable } from "vue-good-table";

import {
  BRow,
  BCol,
  BButton,
  BBadge,
  BCard,
    BTooltip,

} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCardCode,
    BCard,
  BBadge,
    VueGoodTable,
    BTooltip,

  },
  data() {
    return {
      fields: [
        {
          field: "name",
          label: "Name",
        },
        {
          field: "action",
          label: "Actions",
        },
      ],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      loadData:false,
      allBackup:[]
    };
  },
  async mounted() {
    this.getAllBackup();
  },
  methods: {
    async getAllBackup() {
        this.loadData = true;
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/get-all-backup`,
      };
      await axios(request).then((json) => {
        this.loadData = false;
        this.allBackup=[]
        json.data.download.map((item,index)=>{
          this.allBackup.push({file:item,id:index,name:item.split('/').reverse()[0]})
        })
      });
    },
    handledownload(file){
      const fURL = document.createElement("a");

      fURL.href = file;
      fURL.setAttribute("download", `${file}`);
      document.body.appendChild(fURL);

      fURL.click();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
