<template>
  <div class="">
    <b-card>
      <b-row class="justify-content-end order">
        <b-col md="6">
          <b-input-group class="input-group-merge">
            <b-form-input id="search" v-model="searchTerm" class="form-control-merge" @input="searchApply" type="text" />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <div class="col-6">
          <b-button variant="primary" @click="$router.push('/add-coupons')"
            class="btn btn-primary float-right mr-1">
            Add Coupons
          </b-button>
        </div>
      </b-row>
    </b-card>
    <b-card>

    <vue-good-table
      class="table"
      :columns="fields"
      :rows="allCoupons"
      :rtl="direction"
      :isLoading="loadData"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: false,
        selectAllByGroup: false,
      }"
      :pagination-options="pagination"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'image'">
          <!-- {{ props.row.image.thumbnail }} -->

          <b-img  v-if="props.row.image"
          :src="props.row.image.thumbnail" fluid style="height: 100px" />
        </span>
        <div v-else-if="props.column.field === 'minimum_cart_amount'">
          {{
            props.row.minimum_cart_amount
              ? "₹" + props.row.minimum_cart_amount.toLocaleString("en-IN")
              : "₹" + "0.00"
          }}
        </div>
        <div v-else-if="props.column.field === 'amount'">
          {{
            props.row.amount
              ? "₹" + props.row.amount.toLocaleString("en-IN")
              : "₹" + 0.0
          }}
        </div>
        <div
          v-else-if="props.column.field === 'action'"
          style="display: flex; justify-content: center"
          class="w-100 h-100"
        >

        <span v-if="props.column.field === 'action'" style="width: fit-content; display: flex" class='position-relative p-1'>
          <b-badge
            variant="warning" :id="'edit' + props.row.id" class="mr-50 cursor-pointer" @click="handleEdit(props.row)">
            <feather-icon icon="Edit2Icon" />
          </b-badge>
          <b-badge
            variant="danger" :id="'delete' + props.row.id" class="cursor-pointer" @click="handleDelete(props.row)">
            <feather-icon icon="TrashIcon" />
          </b-badge>
          </span>
           <b-tooltip placement="top" :target="'edit' + props.row.id" triggers="hover">
                Edit
          </b-tooltip>
          <b-tooltip placement="top" :target="'delete' + props.row.id" triggers="hover">
            Delete
          </b-tooltip>
        </div>
        <div v-else>
          {{ props.row[props.column.field] }}
        </div>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <!-- <b-form-select v-model="pageLength" :options="['5', '10', '20', '50', '100', '500']" class="mx-1"
                @input="changePagination()" />
              <span class="text-nowrap"> Showing {{ paginationStart }} to {{ paginationEnd }} of {{ paginationTotal
              }}</span> -->
          </div>
          <div>
            <b-pagination
              v-model="paginationValue"
              :total-rows="paginationTotal"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="changePagination('change')"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    </b-card>

  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import axios from "@/components/axios";
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import { VueGoodTable } from "vue-good-table";
import {
  BFormInput,
  BInputGroupAppend,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormSelect,
  BPagination,
  BButton,
  BFormCheckbox,
  BBadge,
  BImg,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BTooltip,

  BCard,
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";

export default {
  components: {
    BFormGroup,
    BInputGroupAppend,
    BCard,
    BForm,
  BTooltip,

    VueGoodTable,
    BRow,
    BBadge,
    BFormInput,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BCol,
    BImg,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    BInputGroup,
  BDropdown,
  BDropdownItem,
  },

  data() {
    return {
      branchOptions: [
        { name: "Channel Partner", id: "channelpartner" },
        { name: "Franchise", id: "franchise" },
        { name: "Admin", id: "admin" },
      ],
      leadsource: "",
      loadData: true,
      pagination: {
        enabled: true,
        mode: "records",
        perPage: 500,
        position: "bottom",
        perPageDropdown: [3, 7, 9],
        dropdownAllowAll: false,
        setCurrentPage: 2,
        jumpFirstOrLast: true,
        firstLabel: "First Page",
        lastLabel: "Last Page",
        nextLabel: "next",
        prevLabel: "prev",
        rowsPerPageLabel: "Rows per page",
        ofLabel: "of",
        pageLabel: "page", // for 'pages' mode
        allLabel: "All",
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`,
      },
      employee: "",
      pageLength: 20,
      employeeoption: [],
      paginationStart: 0,
      paginationValue: 1,
      paginationTotal: 0,
      paginationEnd: 0,
      subemployee: "",
      subemployeeoption: [],
      searchTerm: "",
      leadsourceOptions: [],
      active: "",
      expired: "",
      result: "",
      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "image",
          label: "Banner",
        },
        {
          field: "code",
          label: "Code",
        },
        {
          field: "amount",
          label: "Amount",
        },
        {
          field: "minimum_cart_amount",
          label: "Minimum Amount",
        },
        {
          field: "date",
          label: "Active",
        },
        {
          field: "expired",
          label: "Expired",
        },
        {
          field: "action",
          label: "Actions",
        },
      ],
      show: {},
      params: "",
      allCoupons:[]
    };
  },
  computed: {
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  mounted() {
    this.getCoupons();
  },
  methods: {
    searchApply: _.debounce(function () {
      this.params = `&searchJoin=and&search=code:${this.searchTerm}`;
      this.getCoupons();
    }, 1000),
    changePagination() {
      this.getCoupons();
    },
    handleEdit(row) {
      this.$router.push(`/edit-coupons/${row.id}`);
    },
    handleDelete(row) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            const request = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
          },
        url: `${this.baseApi}/coupons/${row.id}`,
      };
     axios(request).then((json) => {
       this.getCoupons();
       this.$swal({
              icon: "success",
              title: "Deleted!",
              text: "Your data has been deleted.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            });

          }
        })
        .catch((error) => console.log(error, "error"));

    },
    async getCoupons() {
        this.loadData = true;

      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/coupons?page=${this.paginationValue}&limit=${this.pageLength}${this.params}`,
      };
      await axios(request).then((json) => {
        this.loadData = false;
        this.allCoupons = json.data.data;
        this.allCoupons.map((item) => {
          item.date = moment(item.active_from).fromNow();
          item.expired = moment(item.expire_at).fromNow();
        });
        this.paginationTotal = json.data.total;
        this.paginationStart = json.data.from;
        this.paginationEnd = json.data.to;
        this.$forceUpdate();
      });
    },
  },


};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
<style>
.order .dropdown-menu.show{
  left:-57px !important
}
.rc-table-row-expand-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.rc-table-row-expand-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  color: #aaa;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid;
  cursor: pointer;
}
.rc-table-row-expand-icon.rc-table-row-collapsed:after {
  content: "+";
}
.rc-table-row-expand-icon.rc-table-row-expanded:after {
  content: "-";
}
</style>
