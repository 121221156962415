<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Banner Image</label>
              <div
                @click="openmodal('banner')"
                class="fileuploader cursor-pointer d-flex align-items-center"
                style="
                  border: 1px solid silver;
                  padding: 10px;
                  border-radius: 5px;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <div><feather-icon icon="ImageIcon" size="20" /></div>
                <div>Browse</div>
              </div>
            </b-form-group>
            <attachment
              v-if="banner && banner.thumbnail"
              :data="banner.thumbnail"
            />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Thumbnail Image</label>
              <div
                @click="openmodal('image')"
                class="fileuploader cursor-pointer d-flex align-items-center"
                style="
                  border: 1px solid silver;
                  padding: 10px;
                  border-radius: 5px;
                  display: flex;
                  justify-content: space-between;
                "
              >
                <div><feather-icon icon="ImageIcon" size="20" /></div>
                <div>Browse</div>
              </div>
            </b-form-group>
            <attachment
              v-if="image && image.thumbnail"
              :data="image.thumbnail"
            />
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Name"
              >
                <b-form-input v-model="name" placeholder="Enter Name" />
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Redirect URL</label>
              <b-form-input v-model="redirecturl" placeholder="Enter URL" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Parent Category</label>
              <v-select
                v-model="categories"
                placeholder="None"
                label="name"
                :options="CategoriesOptions"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Color Picker</label>
              <div>
                <input v-model="colorpicker" type="color" />
              </div>
            </b-form-group>
          </b-col>
         

          <b-col md="6">
            <b-form-group>
              <label>Meta Title</label>
              <b-form-input v-model="meta_title" placeholder="Meta Title" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Meta Description</label>
              <b-form-input
                v-model="meta_description"
                placeholder="Meta Description"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <div class="d-flex flex-column">
                <label>Is this edits ?</label>
                <b-form-checkbox
                  v-model="edits"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  switch
                  inline
                />
              </div>
            </b-form-group>
          </b-col>
          <!-- submit button -->

          <b-col md="12">
            <b-form-group>
              <label>Description</label>
              <quill-editor
                ref="myQuillEditor"
                v-model="details"
                class="quill-hight"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              class="mr-4"
              :disabled="check"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <gallary-image-model
      v-if="modelValue"
      :modelValue="modelValue"
      :openmodal="openmodal"
      @setImage="setImage"
    />
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import gallaryImageModel from "../../components/gallaryImageModel.vue";
import { quillEditor } from "vue-quill-editor";

import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../components/Attechment.vue";
import { extend } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    quillEditor,
    flatPickr,
    BFormCheckboxGroup,
    gallaryImageModel,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BFormCheckbox,
  },
  data() {
    return {
      required,
      gallery: [
        {
          original: "",
          thumbnail: "",
          file_name: "",
          id: "",
        },
      ],
      details: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      image: {
        original: "",
        thumbnail: "",
        file_name: "",
        id: "",
      },
      redirecturl: "",
      banner: {
        original: "",
        thumbnail: "",
        file_name: "",
        id: "",
      },
      type: "",
      categories: "",
      name: "",
      ifEdit: false,
      check: false,
      typeOption: [],
      CategoriesOptions: [],
      slug: "",
      edits: false,
      selectedField: "",
      selectedIndex: false,
      selected: "",
      modelValue: "",
      colorpicker: "",
      oldName: "",
      meta_title: "",
      meta_description: "",
    };
  },
  async mounted() {
    this.getCategories();
    this.ifEdit = !!this.$route.params.slug;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    openmodal(name, index, field) {
      this.modelValue = !this.modelValue;
      this.selected = name;
      this.selectedField = field ? field : false;
      this.selectedIndex = index;
      this.$forceUpdate();
    },
    setImage(image) {
      if (this.selectedIndex && this.selectedField) {
        this[this.selected][this.selectedIndex - 1][this.selectedField] = image;
      } else if (this.selectedIndex) {
        this[this.selected][this.selectedIndex - 1] = image;
      } else {
        this[this.selected] = image;
      }
    },
    async getEditValue() {
      this.getCategories();
      // console.log(this.$route.params.id, "id====");
      await axios({
        method: "GET",
        url: `${this.baseApi}/categories/${this.$route.params.slug}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.id = item.id;
      this.categories = item.parent;
      this.image = item.image;
      this.banner = item.banner;
      this.name = item.name;
      this.type = item.type;
      this.edits = item.edits ? true : false;
      this.colorpicker = item.colorpicker;
      this.oldName = this.name;
      this.redirecturl = item.redirecturl;
      this.details = item.details;
      this.meta_title = item.meta_title;
      this.meta_description = item.meta_description;
    },
    convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          data = {
            parent: this.categories ? this.categories.id : "",
            language: "en",
            image: this.image,
            banner: this.banner,
            name: this.name,
            type_id: 8,
            slug: this.convertToSlug(
              (this.categories ? this.categories.name + " " : "") + this.name
            ),
            edits: this.edits ? 1 : 0,
            colorpicker: this.colorpicker,
            redirecturl: this.redirecturl,
            details: this.details,
            meta_title: this.meta_title,
            meta_description: this.meta_description,
          };
          // if (this.oldName.toLowerCase() == this.name.toLowerCase()) {
          //   delete data.slug
          // }
        }

        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          let result;
          if (this.ifEdit) {
            if (this.categories) {
              this.CategoriesOptions.map((item) => {
                if (item.id == this.categories.id) {
                  result = item.children.filter((item) => {
                    if (item.id !== this.id) {
                      if (
                        item.name.toLowerCase().trim() ==
                        this.name.toLowerCase().trim()
                      ) {
                        return true;
                      }
                      return false;
                    }
                    return false;
                  });
                }
              });
            } else {
              //  result = this.CategoriesOptions.filter(item => item.name.toLowerCase().trim() == this.name.toLowerCase().trim());
              result = this.CategoriesOptions.filter((item) => {
                if (item.id !== this.id) {
                  if (
                    item.name.toLowerCase().trim() ==
                    this.name.toLowerCase().trim()
                  ) {
                    return true;
                  }
                  return false;
                }
                return false;
              });
            }
          } else {
            if (this.categories) {
              this.CategoriesOptions.map((item) => {
                if (item.id == this.categories.id) {
                  result = item.children.filter(
                    (item) =>
                      item.name.toLowerCase().trim() ==
                      this.name.toLowerCase().trim()
                  );
                }
              });
            } else {
              result = this.CategoriesOptions.filter(
                (item) =>
                  item.name.toLowerCase().trim() ==
                  this.name.toLowerCase().trim()
              );
            }
          }
          if (result.length > 0) {
            this.$swal({
              title: "Warning",
              timer: 5000,
              text: "Name Already Exits",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            this.check = true;
            const accessToken = localStorage.getItem("accessToken");
            const baseApi = process.env.VUE_APP_APIENDPOINT;
            await axios({
              method: `${this.ifEdit ? "put" : "post"}`,
              url: this.ifEdit
                ? `${baseApi}/categories/${this.id}`
                : `${baseApi}/categories`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              data: JSON.stringify(data),
            })
              .then((json) => {
                this.check = false;
                this.$swal({
                  title: "Submited",
                  text: "Successfully",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                this.$router.push("/categories");
              })
              .catch((error) => {
                this.check = false;

                this.$swal({
                  title: "Error!",
                  text: `${error}`,
                  icon: "error",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              });
          }
        }
      });
    },
    // async getGroups() {
    //    const request = {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${this.accessToken}`,
    //     },
    //     url: `${this.baseApi}/types`,
    //   };
    //    axios(request).then((json) => {
    //     console.log(json)
    //     this.typeOption = json.data
    //   });
    // },
    getCategories() {
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/categories?limit=1000&search=type.slug:jewellery`,
      };
      axios(request).then((json) => {
        this.CategoriesOptions = json.data.data;
      });
    },
    removeGalleryRow(index) {
      this.gallery.splice(index, 1);
    },
    addGalleryRow() {
      this.gallery.push({
        original: "",
        thumbnail: "",
        file_name: "",
        id: "",
      });
    },
    async handleFileChange(e, name, id, idName) {
      this.check = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("attachment[]", e);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachments`,
      };
      await axios(requestOptions)
        .then((response) => {
          if (id) {
            this[name][id - 1].thumbnail = response.data[0].thumbnail;
            this[name][id - 1].file_name = e.name;
            this[name][id - 1].id = response.data[0].id;
            this[name][id - 1].original = response.data[0].original;
          } else {
            this[name].thumbnail = response.data[0].thumbnail;
            this[name].file_name = e.name;
            this[name].id = response.data[0].id;
            this[name].original = response.data[0].original;
          }
          this.check = false;
        })
        .catch((error) => {
          this.check = false;
        });
    },
    onClickBack() {
      this.$router.push("/categories");
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>

