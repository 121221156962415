<template>
  <div>
     <b-card>
          <b-row class="justify-content-end order">
            <b-col md="6">
              <label> Product</label>
              <v-select :options="productData" v-model="product"
                @input="searchApply" label="name"></v-select>
            </b-col>
          </b-row>
        </b-card>
    <b-card>

      <vue-good-table class="table" :columns="fields" :rows="allUser" :isLoading="loadData" :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: false,
        selectAllByGroup: false,
      }" :pagination-options="pagination">
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field === 'is_mail'">
            <b-badge :variant="props.row.is_email?'success':'secondary'">
              {{ props.row.is_email?'Email Sent':'Email Pending' }}
            </b-badge>
          </div>
           <div v-else-if="props.column.field === 'product'">
            {{ props.row.product ? props.row.product.name:''  }}
            </div>
          <span v-else-if="props.column.field === 'action'" style="width: fit-content; display: flex" class='position-relative'>
            <b-badge variant="warning" :id="'mail' + props.row.id" class="mr-50 cursor-pointer"
              @click="handleSendMail(props.row)">
              <feather-icon icon="MailIcon" />
            </b-badge>
            <b-tooltip
               placement="top"
               :target="'mail' + props.row.id"
               triggers="hover"
             >
               Send Mail
             </b-tooltip>
          </span>
          <div v-else>
            {{ props.row[props.column.field] }}
          </div>
        </template>
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <!-- <b-form-select v-model="pageLength" :options="['5', '10', '20', '50', '100', '500']" class="mx-1"
                @input="changePagination()" />
              <span class="text-nowrap"> Showing {{ paginationStart }} to {{ paginationEnd }} of {{ paginationTotal
              }}</span> -->
            </div>
            <div>
              <b-pagination v-if='showPagination' v-model="paginationValue" :total-rows="paginationTotal"
                :per-page="pageLength" first-number last-number align="right" prev-class="prev-item"
                next-class="next-item" class="mt-1 mb-0" @input="changePagination('change')">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import axios from "@/components/axios";
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import moment from "moment";
import { VueGoodTable } from "vue-good-table";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BInputGroup,
  BRow,
  BCol,
  BFormSelect,
  BPagination,
  BButton,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
  BFormDatepicker,
  BImg,
  BImgLazy,
  BInputGroupAppend,
  BTooltip,
  BCard
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  mixins: [togglePasswordVisibility],
  components: {
    BFormGroup,
    BForm,
    BTooltip,
    VueGoodTable,
    BRow,
    BBadge,
    BFormInput,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BInputGroup,
    BCol,
    BImg,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    BInputGroupAppend,
    BCard

  },
  data() {
    return {
      loadData: true,
      city: '',
      state: '',
      pagination: {
        enabled: true,
        mode: "records",
        perPage: 500,
        position: "bottom",
        perPageDropdown: [3, 7, 9],
        dropdownAllowAll: false,
        setCurrentPage: 2,
        jumpFirstOrLast: true,
        firstLabel: "First Page",
        lastLabel: "Last Page",
        nextLabel: "next",
        prevLabel: "prev",
        rowsPerPageLabel: "Rows per page",
        ofLabel: "of",
        pageLabel: "page", // for 'pages' mode
        allLabel: "All",
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`,
      },
      selectedId: 0,

      pageLength: 15,
      employeeoption: [],
      paginationStart: 0,

      paginationValue: 1,
      paginationTotal: 0,

      paginationEnd: 0,

      searchTerm: "",
      result: "",
      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        // {
        //   field: "name",
        //   label: "Name",
        // },
        {
          field: "email",
          label: "Email",
        },
        {
          field: "product",
          label: "Product",
        },
        {
          label: "Status",
          field: "is_mail",
        },
        {
          field: "action",
          label: "Actions",
        },
      ],
      allUser: [],
      userId: 0,
      addPopup: false,
      walletPoint: 0,
      email: "",
      name: "",
      password: "",
      showPagination: false,
      selectedRow: {},
      stateOptions: [],
      productData: [],
      product: ''

    };
  },
  mounted() {
    this.getAllUsers();
    this.getAllProduct()
  },
  methods: {
     searchApply: _.debounce(function () {
      this.getAllUsers();
    }, 1000),
    async handleSendMail(row) {
       const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/product-available-notify/${row.id}`,
       };

      await axios(request).then((json) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Successfully Sent Mail`,
            icon: "CheckIcon",
            variant: "success",
            text: `You have successfully Sent mail  to ${row.name}.`,
          },
        });
      });
    },
     async getAllProduct() {
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/products?limit=10000000`,
      };
      await axios(request).then((json) => {
        this.productData = json.data.data
      });
    },
    searchApply: _.debounce(function () {
      this.getAllUsers();
    }, 1000),
    changePagination() {
      this.getAllUsers();
    },
    async getAllUsers() {
      this.loadData = true;

      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/get-notify-user?page=${this.paginationValue}&limit=${this.pageLength
          }${this.product && this.product.id ? ('&search=product_id:' + this.product.id) : ''}`,
      };
      await axios(request).then((json) => {
        this.loadData = false;
        this.allUser = json.data.data;
        this.paginationTotal = json.data.total;
        this.paginationStart = json.data.from;
        this.paginationEnd = json.data.to;
        this.showPagination = true
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

