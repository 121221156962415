<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Avatar</label>
              <b-form-file
                v-model="avatar.thumbnail"
                placeholder="Select Featured Image"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFileChange($event, 'avatar')"
              />
            </b-form-group>
            <attachment :data="avatar.thumbnail" />
          </b-col>
          <b-col md="12">
            <b-card-code title="Email Notification" no-body />
          </b-col>

          <b-col md="6">
            <b-form-group>
              <label>Notification Email</label>
              <b-form-input v-model="email" placeholder="Enter Email" />
            </b-form-group>
          </b-col>
          <!-- <b-col md="6">
              <label>Enable Notification</label>
            <b-form-checkbox
              class="custom-control-primary"
              name="check-button"
              v-model="emailEnable"
              switch
            >
            </b-form-checkbox>
          </b-col> -->

          <b-col md="12">
            <b-card-code title="Information" no-body />
          </b-col>


          <b-col md="6">
            <b-form-group>
              <label>Name</label>
                <b-form-input v-model="name"  placeholder="Enter Name"/>
            </b-form-group>
          </b-col>
           <b-col md="6">
            <b-form-group>
              <label>Bio</label>
                <b-form-textarea v-model="bio"  placeholder="Enter Bio"/>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Contact Number</label>
              <b-form-input v-model="contact" placeholder="Enter Contact Number"/>
            </b-form-group>
          </b-col>
          <!-- submit button -->
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              class="mr-4"
              :disabled="check"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../../components/Attechment.vue";

export default {
  components: {
    flatPickr,
    BFormCheckboxGroup,

    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BFormCheckbox,
  },
  data() {
    return {
      subCategoriesOptions: [],
      avatar: {
        original: "",
        thumbnail: "",
        file_name: "",
        id: "",
      },
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      email: '',
      emailEnable: false,
      name: '',
      bio: '',
      contact: '',
      id: '',
      check:false
    };
  },
  async mounted() {
    this.getEditValue();
  },
  methods: {
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/me`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.id = item.id;
      this.name = item.name;
      this.email = item.profile.notifications.email;
      this.bio = item.profile.bio;
      this.contact = item.profile.contact;
      this.avatar = item.profile.avatar
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          data = {
            name: this.name,
            profile: {
              id: this.id,
              avatar: this.avatar,
              bio: this.bio,

              // emailEnable: this.emailEnable,
              contact:this.contact,
              notifications: {
                email:this.email
              }
            }
          };
        }

        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.check = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method:  "put",
            url:  `${baseApi}/users/${this.id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.check = false;
              this.$swal({
                title: "Submited",
                text: "Successfully updated!",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/");
            })
            .catch((error) => {
              this.check = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    async handleFileChange(e, name, id, idName) {
      this.check = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("attachment[]", e);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachments`,
      };
      await axios(requestOptions)
        .then((response) => {
            this[name].thumbnail = response.data[0].thumbnail;

            this[name].file_name = e.name;
            this[name].id = response.data[0].id;
            this[name].original = response.data[0].original;
            this.check = false;
        })
        .catch((error) => {
          this.check = false;
        });
    },
    onClickBack() {
      this.$router.push("/");
    },
  },
};
</script>
