<template>
  <b-card no-body>
    <b-card-body class="d-flex justify-content-between align-items-center" style="height:160px">
      <div class="truncate">
        <h4 class="mb-50 font-weight-bolder">
          {{ statistic }}
        </h4>
        <span>{{ statisticTitle }}</span>
        <h3 class="my-1"><b>{{ subtitle }}</b></h3>
      </div>
      <b-avatar class="mb-1" :variant="`light-${color}`" size="45">
      <svg
          v-if="icon == 'rupee'"
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          fill="currentColor"
          class="bi bi-currency-rupee"
          viewBox="0 0 16 16"
        >
          <path
            d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4v1.06Z"
          />
        </svg>
      <feather-icon v-else size="17" :icon="icon" />
    </b-avatar>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    statistic: {
      type: [Number, String],
      required: true
    },
    statisticTitle: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    },
    subtitle:{
      default: 'primary'
    }
  }
}
</script>
