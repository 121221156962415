<template>
  <div>
    <b-card>

    <div class="row ">
      <!-- <div class="col-md-1">
          </div> -->
      <div class="col-md-6 mt-1">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="search"
            v-model="searchTerm"
            class="form-control-merge"
            @input="searchApply"
            type="text"
          />
          <b-input-group-append is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="col-md-6 mt-1">
        <b-button
          variant="primary"
          @click="$router.push('/add-gazette')"
          class="btn btn-primary float-right mr-1"
        >
          Add Gazette
        </b-button>
      </div>
    </div>
    </b-card>
    <b-card>

    <vue-good-table
      class="table"
      :columns="fields"
      :rows="getGazette"
      :rtl="direction"
      :isLoading="loadData"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: false,
        selectAllByGroup: false,
      }"
      :pagination-options="pagination"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'image'">
          <!-- {{ props.row.image.thumbnail }} -->

          <b-img
            :src="props.row.image.thumbnail"
            fluid
            v-bind="{ width: 75, height: 75 }"
          />
        </span>
        <div v-else-if="props.column.field === 'is_approved'">
          <b-form-checkbox
            :checked="props.row.is_approved ? true : false"
            class="custom-control-primary"
            name="check-button"
            @change="ToggleChanged(props.row.id, $event)"
            switch
          >
          </b-form-checkbox>
        </div>
        <span
          v-else-if="props.column.field === 'action'"
          style="width: fit-content; display: flex"

        >
          <b-badge
            variant="warning"
            :id="'edit' + props.row.id"
            class="mr-50 cursor-pointer"
            @click="handleEdit(props.row)"
          >
            <feather-icon icon="Edit2Icon" />
          </b-badge>
          <b-badge
            variant="danger"
            :id="'delete' + props.row.id"
            class="cursor-pointer"
            @click="handleDelete(props.row)"
          >
            <feather-icon icon="TrashIcon" />
          </b-badge>
          <b-tooltip placement="top"  :target="'edit' + props.row.id" triggers="hover">
            Edit
          </b-tooltip>
          <b-tooltip placement="top"  :target="'delete' + props.row.id" triggers="hover">
          Delete
          </b-tooltip>
        </span>
        <div v-else>
          {{ props.row[props.column.field] }}
        </div>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <!-- <b-form-select v-model="pageLength" :options="['5', '10', '20', '50', '100', '500']" class="mx-1"
                @input="changePagination()" />
              <span class="text-nowrap"> Showing {{ paginationStart }} to {{ paginationEnd }} of {{ paginationTotal
              }}</span> -->
          </div>
          <div>
            <b-pagination
              v-model="paginationValue"
              :total-rows="paginationTotal"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="changePagination('change')"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    </b-card>

  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import axios from "@/components/axios";
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import { VueGoodTable } from "vue-good-table";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormSelect,
  BPagination,
  BButton,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
  BFormDatepicker,
  BImg,
  BImgLazy,
  BInputGroup,
  BInputGroupAppend,
  BCard,
    BTooltip,
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormGroup,
    BForm,
    VueGoodTable,
    BRow,
    BBadge,
    BFormInput,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BCol,
    BImg,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    BInputGroup,
    BInputGroupAppend,
    BCard,
      BTooltip,
  },
  data() {
    return {
      loadData: true,
      pagination: {
        enabled: true,
        mode: "records",
        perPage: 500,
        position: "bottom",
        perPageDropdown: [3, 7, 9],
        dropdownAllowAll: false,
        setCurrentPage: 2,
        jumpFirstOrLast: true,
        firstLabel: "First Page",
        lastLabel: "Last Page",
        nextLabel: "next",
        prevLabel: "prev",
        rowsPerPageLabel: "Rows per page",
        ofLabel: "of",
        pageLabel: "page", // for 'pages' mode
        allLabel: "All",
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`,
      },

      pageLength: 50,

      paginationStart: 0,

      paginationValue: 1,
      paginationTotal: 0,

      paginationEnd: 0,

      searchTerm: "",

      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "image",
          label: "Image",
          hidden: false,
        },
        {
          field: "name",
          label: "Name",
        },
        {
          field: "is_approved",
          label: "Approval Action",
        },
        {
          field: "action",
          label: "Actions",
        },
      ],
      getGazette: [],
    };
  },
  computed: {
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  mounted() {
    this.getAllGazette();
  },
  methods: {
    handleEdit(row) {
      this.$router.push(`/edit-gazette/${row.slug}`);
    },
    handleDelete(row) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            const request = {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
              url: `${this.baseApi}/manufacturers/${row.id}`,
            };
            axios(request).then((json) => {
              this.getAllGazette();
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "Your data has been deleted.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async ToggleChanged(id, e) {
      let tempId = this.getGazette.findIndex((i)=> i.id== id);
      let tmpData = this.getGazette[tempId]
      await this.Setting();
      const request = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: {
          is_approved: e,
          name: tmpData.name,
          language: tmpData.language,
          type_id: tmpData.type_id
        },
        url: `${this.baseApi}/manufacturers/${id}`,
      };
      await axios(request).then((json) => {
        this.getAllGazette();
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Successfully Updated`,
            icon: "CheckIcon",
            variant: "success",
            text: `You have successfully Changed Status of ${json.data.name}.`,
          },
        });
      });
    },
    searchApply: _.debounce(function () {
      this.getAllGazette();
    }, 1000),
    changePagination() {
      this.getAllGazette();
    },
    async getAllGazette() {
      await this.Setting();
      this.loadData = true;

      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/manufacturers?currency=${
          this.$store.state.app.currency
        }&page=${this.paginationValue}&limit=20&search=${
          this.searchTerm ? "name:"+ this.searchTerm : ""
        }`,
      };
      await axios(request).then((json) => {
        this.loadData = false;
        this.getGazette = json.data.data;
        this.paginationTotal = json.data.total;
        this.paginationStart = json.data.from;
        this.paginationEnd = json.data.to;
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

