<template>
  <div class="viewOrder">
    <b-card>
      <div>
        <div class="headerStatus">
          <b-row>
            <b-col sm="6" class="mt-1">
              <span
                >Order Status :
                <b-badge :variant="setColor(order.order_status)">
                  {{ order.order_status }}
                </b-badge>
              </span>
            </b-col>
            <b-col sm="6" class="mt-1 d-sm-flex justify-content-end">
              <span
                >Payment Status :
                <b-badge variant="light-success">
                  {{ order.payment_status }}
                </b-badge>
              </span>
            </b-col>
            <b-col cols="12" class="d-flex justify-content-end mt-1">
              <b-button variant="flat-warning" @click="handlePrint">
                <feather-icon icon="ArrowDownIcon" class="mr-50" />
                Download invoice
              </b-button>
            </b-col>
            <b-col md="6" class="mt-1">
              <div
                class="font-weight-bolder text-md-left text-center d-flex align-items-center"
              >
                <h3>Order ID - {{ order.tracking_number }}</h3>
              </div>
              <div
                class="font-weight-bolder text-md-left text-center d-flex align-items-center"
              >
                <h5>Order Date - {{ order.orderdate }}</h5>
              </div>
            </b-col>
            <b-col md="6" class="d-flex w-100 justify-content-between mt-1">
              <div class="statusSelect">
                <v-select
                  v-model="status"
                  :clearable="true"
                  placeholder="Select..."
                  label="name"
                  :options="statusOptions"
                />
              </div>
              <b-button
                variant="primary"
                style="width: 180px"
                @click="handleStatus()"
              >
                Change Status
              </b-button>
            </b-col>
          </b-row>
          <b-row style="margin:120px 0px" class="d-flex justify-content-center">
            <app-timeline :class=" isMobile>700?'verticalTimeline':''">
              <app-timeline-item :class="isMobile>700?'verticalTimelineItem':''"
            :icon="order.order_status == 'Order Processing' || order.order_status == 'Order At Local Facility'||order.order_status == 'Order Out For Delivery'||order.order_status == 'Order Completed' ?'CheckIcon':'CalendarIcon'"
            :fillBorder="order.order_status == 'Order Processing' || order.order_status == 'Order At Local Facility'||order.order_status == 'Order Out For Delivery'||order.order_status == 'Order Completed' "
            variant="warning"

            > Order Processing </app-timeline-item>
              <app-timeline-item :class="isMobile>700?'verticalTimelineItem':''"
            :icon="order.order_status == 'Order At Local Facility'||order.order_status == 'Order Out For Delivery'||order.order_status == 'Order Completed' ?'CheckIcon':'CalendarIcon'"

            :fillBorder="order.order_status == 'Order At Local Facility'||order.order_status == 'Order Out For Delivery'||order.order_status == 'Order Completed' "

            variant="info"

            > Order At Local Facility </app-timeline-item>
              <app-timeline-item :class="isMobile>700?'verticalTimelineItem':''"
              :icon="order.order_status == 'Order Out For Delivery'||order.order_status == 'Order Completed' ?'CheckIcon':'CalendarIcon'"

            :fillBorder="order.order_status == 'Order Out For Delivery'||order.order_status == 'Order Completed' "

            variant="primary"

            > Order Out For Delivery </app-timeline-item>
              <app-timeline-item :class="isMobile>700?'verticalTimelineItem':''"
              :icon="order.order_status == 'Order Completed' ?'CheckIcon':'CalendarIcon'"

            :fillBorder="order.order_status == 'Order Completed' "

            variant="success"

            > Order Completed </app-timeline-item>
            </app-timeline>
          </b-row>
        </div>
        <b-row class="justify-content-end mt-2">
          <b-col md="12">
            <div class="w-100 overflow-auto">
            <table class="table table-bordered"
             style="min-width: 15cm;">
              <thead class="text">
                <tr>
                  <th style="width: 1cm"></th>
                  <th>Product</th>
                  <th>QTY</th>
                  <th>Rate</th>

                  <th>Total</th>
                  <th>CGST Rate</th>
                  <th>CGST Amount</th>
                  <th>SGST Rate</th>
                  <th>SGST Amount</th>
                  <th>IGST Rate</th>
                  <th>IGST Amount</th>


                  <th class="text-center">Payable Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, id) in order.products" :key="id">
                  <td>{{ id + 1 }}</td>
                  <td>
                    {{ item.name }}
                  </td>
                   <td>
                      {{ item.pivot.order_quantity }}
                    </td>
                     <td>
                      {{ item.pivot.unit_price ? item.pivot.unit_price.toLocaleString('en-IN') : ""  }}
                    </td>
                     <td>
                      {{ item.subtotal? item.subtotal.toLocaleString('en-IN'): item.subtotal}}
                    </td>
                     <td>
                      {{ item.cgst }}
                    </td>
                     <td>
                      {{ item.cgst_amount?item.cgst_amount.toLocaleString('en-IN'): item.sgst_amount }}
                    </td>
                    <td>
                        {{ item.sgst }}
                      </td>
                       <td>
                        {{ item.sgst_amount?item.sgst_amount.toLocaleString('en-IN'):item.sgst_amount }}
                      </td>
                      <td>
                        {{ item.igst }}
                      </td>
                       <td>
                        {{ item.igst_amount ? item.igst_amount.toLocaleString('en-IN') : item.igst_amount }}
                      </td>
                  <td class="text-right">
                   ₹ {{ item.total_amount?item.total_amount.toLocaleString('en-IN'):'' }}
                  </td>
                </tr>
                <tr v-if="order.products && order.products.length == 0">
                  <td colspan="3" class="text-center">No data found</td>
                </tr>
              </tbody>
            </table>
            </div>

          </b-col>
          <b-col md="8" v-if="order.orderpayment" class="mt-1 order-2 order-md-1">
            <div class="gap-1 d-flex justify-content-start mt-1 gap-1"  v-if="order.orderpayment.payment_mode">
              <span><b>Payment Mode : </b></span>
              <span> {{ order.orderpayment.payment_mode }}</span>
            </div>

            <div class="gap-1 d-flex justify-content-start mt-1" v-if="order.orderpayment.card_name">
              <span><b>{{order.orderpayment.card_name == 'UPI'?'Payment Through' :'Card Name'}} : </b></span>
              <span> {{ order.orderpayment.card_name }}</span>
            </div>

            <div class="gap-1 d-flex justify-content-start mt-1" v-if="order.orderpayment.bank_ref_no">
              <span><b>Bank Ref. No : </b></span>
              <span> {{ order.orderpayment.bank_ref_no }}</span>
            </div>

            <div class="gap-1 d-flex justify-content-start mt-1" v-if="order.orderpayment.bin_country">
              <span><b>Country : </b></span>
              <span> {{ order.orderpayment.bin_country }}</span>
            </div>

            <div class="gap-1 d-flex justify-content-start mt-1" v-if="order.orderpayment.trans_date">
              <span><b>Transaction Date : </b></span>
              <span> {{ moment(order.orderpayment.trans_date).format('DD/MM/yyyy hh:mm A') }}</span>
            </div>

          </b-col>
          <b-col md="4" class="order-md-2 order-1">
            <div class="d-flex justify-content-between mt-1">
              <span>Sub Total</span>
              <span> ₹ {{ order.invoice_subtotal?order.invoice_subtotal.toLocaleString('en-IN'):'' }}</span>
            </div>
            <!-- <div class="d-flex justify-content-between mt-1">
              <span>Tax</span>
              <span> ₹ {{ order.sales_tax }}</span>
            </div> -->
            <div class="d-flex justify-content-between mt-1">
              <span>Delivery fee</span>
              <span> ₹ {{ order.delivery_fee }}</span>
            </div>
            <div class="font-weight-bolder d-flex justify-content-between mt-1">
              <span>Total</span>
              <span> ₹ {{ order.invoice_total }}</span>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col md="6" class="mt-2">
            <div class="addLable">
              <h3 class="font-semibold border-b border-border-200 text-heading">
                Billing Address
              </h3>
            </div>
            <div class="address">
              <span>{{ order.customer_name }}</span>
            </div>
            <div class="address">
              <span>{{ order.billing_address_text }}</span>
            </div>
            <div class="address">
              <span>{{ order.customer_contact }}</span>
            </div>
          </b-col>
          <b-col
            md="6"
            class="text-md-right text-left d-md-flex justify-content-end flex-wrap mt-2"
          >
            <div class="addLable w-100">
              <h3 class="font-semibold border-b border-border-200 text-heading">
                Shipping Address
              </h3>
            </div>
            <div class="address">
              <span>{{ order.customer_name }}</span>
            </div>
            <div class="address">
              <span>{{ order.shipping_address_text }}</span>
            </div>
            <div class="address">
              <span>{{ order.customer_contact }}</span>
            </div></b-col
          >
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Form from "@/components/form/Form.vue";
import axios from "@/components/axios";
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormSelect,
  BPagination,
  BButton,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
  BFormDatepicker,
  BImg,
  BImgLazy,
  BCard,
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";

export default {
  components: {
    BFormGroup,
    BForm,
    VueGoodTable,
    BRow,
    BBadge,
    BFormInput,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BCol,
    AppTimeline,
    AppTimelineItem,
    BImg,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    BCard,
  },
  data() {
    return {
      moment,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      order: [],
      status: "",
      statusOptions: [
        { name: "Order Processing", value: "order-processing" },
        { name: "Order Pending", value: "order-pending" },
        { name: "Order At Local Facility", value: "order-at-local-facility" },
        { name: "Order Out For Delivery", value: "order-out-for-delivery" },
        { name: "Order Completed", value: "order-completed" },
        { name: "Order Cancelled", value: "order-cancelled" },
      ],
       isMobile:window.innerWidth
    };
  },
  mounted() {
    this.getOrder();
  },
  computed: {
    setColor() {
      const statusColor = {
        "Order Processing": "warning",
        "Order Pending": "secondary",
        "Order At Local Facility": "info",
        "Order Out For Delivery": "primary",
        "Order Completed": "success",
        "Order Cancelled": "danger",
      };
      return (status) => statusColor[status];
    },
  },
  methods: {
    async handlePrint() {
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/generateInvoice/${this.$route.params.id}`,
      };
      await axios(request).then((json) => {
        window.open(json.data.pdf);
      });
    },
    async handleStatus() {
      if (this.status && this.status.value) {
        let data = {
          order_status: this.status.value,
        };
        const request = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/orders/${this.$route.params.id}`,
          data: data,
        };
        await axios(request).then((json) => {
          this.status = "";
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              variant: "success",
              text: "Successfully Updated!",
              icon: "CheckIcon",
            },
          });
          this.getOrder();
        });
      }
    },
    async getOrder() {
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/orders/${this.$route.params.id}`,
      };
      await axios(request).then((json) => {
        this.order = json.data;
        let status = this.order.order_status.split("-");
        this.order.amount = this.order.amount.toLocaleString("en-IN");
        this.order.sales_tax = this.order.sales_tax.toLocaleString("en-IN");

        this.order.discount = this.order.discount.toLocaleString("en-IN");
        this.order.total = this.order.total.toLocaleString("en-IN");
        this.order.orderdate = moment(this.order.created_at).format('DD/MM/yyyy hh:mm A')

        let status2 = status.map((item) => {
          return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
        });
        this.order.order_status = status2.join(" ");
        status = this.order.payment_status.split("-");
        status2 = status.map((item) => {
          return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
        });
        this.order.payment_status = status2.join(" ");

        let shipping_address_text = "";
        shipping_address_text += this.order.shipping_address.street_address;
        shipping_address_text += this.order.shipping_address.city
          ? "," + this.order.shipping_address.city
          : "";

        shipping_address_text += this.order.shipping_address.state
          ? "," + this.order.shipping_address.state
          : "";
        shipping_address_text += this.order.shipping_address.zip
          ? "," + this.order.shipping_address.zip
          : "";
        shipping_address_text += this.order.shipping_address.country
          ? "," + this.order.shipping_address.country
          : "";
        this.order.shipping_address_text = shipping_address_text;

        let billing_address_text = "";
        billing_address_text += this.order.billing_address.street_address;
        billing_address_text += this.order.billing_address.city
          ? "," + this.order.billing_address.city
          : "";

        billing_address_text += this.order.billing_address.state
          ? "," + this.order.billing_address.state
          : "";
        billing_address_text += this.order.billing_address.zip
          ? "," + this.order.billing_address.zip
          : "";
        billing_address_text += this.order.billing_address.country
          ? "," + this.order.billing_address.country
          : "";
        this.order.billing_address_text = billing_address_text;

        this.order.invoice_subtotal = 0

        this.order.products.map((item) => {
          item.subtotal = item.pivot.order_quantity * item.pivot.unit_price;
          item.cgst_amount = item.subtotal * item.cgst / 100
          item.sgst_amount = item.subtotal * item.sgst / 100
          item.igst_amount = item.subtotal * item.igst / 100
          item.total_amount = item.subtotal + item.cgst_amount + item.sgst_amount + item.igst_amount
          this.order.invoice_subtotal += item.total_amount
        })
          this.order.invoice_total = (parseFloat(this.order.invoice_subtotal) + parseFloat(this.order.delivery_fee)).toLocaleString("en-IN")
          this.order.delivery_fee = this.order.delivery_fee.toLocaleString("en-IN");
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
<style>
.headerStatus {
  font-weight: bolder;
}
.statusSelect {
  width: calc(100% - 200px);
}
.addLable {
  border-bottom: 1px solid #ebe9f1;
}
.address {
  width: 70%;
}
.viewOrder {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}
.verticalTimeline{
  display: flex;
  width:100%
}
.verticalTimelineItem{
  border: none !important;
  border-top: 5px solid #cccbce !important;
  width:25%;
  padding-left: 80px !important;

}
.verticalTimelineItem .timeline-item-icon{
  position: relative;
  top: -40px !important;
}
.gap-1{
  gap:10px
}
</style>

