var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('div',{staticClass:"row  align-items-end justify-content-between"},[_c('div',{staticClass:"col-md-5 mt-1"},[_c('label',[_vm._v(" Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},on:{"input":_vm.searchApply},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('div',{staticClass:"col-md-3 mr-md-2 mt-1"},[_c('b-button',{staticClass:"btn btn-primary float-right",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push('/add-emailtemplate')}}},[_vm._v(" Add Email Template ")])],1)])]),_c('b-card',[_c('vue-good-table',{staticClass:"table",attrs:{"columns":_vm.fields,"rows":_vm.allData,"rtl":_vm.direction,"isLoading":_vm.loadData,"select-options":{
        enabled: false,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: false,
        selectAllByGroup: false,
      },"pagination-options":_vm.pagination},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',{staticClass:"position-relative p-1",staticStyle:{"width":"fit-content","display":"flex"}},[_c('b-badge',{staticClass:"mr-50 cursor-pointer",attrs:{"variant":"warning","id":'edit' + props.row.id},on:{"click":function($event){return _vm.handleEdit(props.row)}}},[_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1),_c('b-tooltip',{attrs:{"placement":"top","target":'edit' + props.row.id,"triggers":"hover"}},[_vm._v(" Edit ")])],1):_c('div',[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"}),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"total-rows":_vm.paginationTotal,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function($event){return _vm.changePagination('change')}},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true),model:{value:(_vm.paginationValue),callback:function ($$v) {_vm.paginationValue=$$v},expression:"paginationValue"}})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }