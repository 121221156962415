<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>

          <b-col md="6" >
            <label>Image</label>
            <b-form-group>
             <div  @click="openmodal('image')" class="fileuploader cursor-pointer d-flex align-items-center" style="border:1px solid silver;padding:10px;border-radius:5px;display:flex;justify-content:space-between">
                <div><feather-icon icon="ImageIcon" size="20" /></div>
                <div>Browse</div>
              </div>
            </b-form-group>
            <attachment :data="image.thumbnail" />
          </b-col>

          <b-col md="6">
            <b-form-group >
              <label>Code</label>
               <label style="color: red !important">*</label>
                <validation-provider #default="{ errors }"  rules="required" name="Code">
              <b-form-input v-model="code" placeholder="Enter Code" />
              <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
            </b-form-group>
          </b-col>


          <b-col md="6">
            <b-form-group  >
              <label>Description</label>
              <b-form-textarea v-model="description" placeholder="Enter Description" />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group  v-if="type !== 'free'">
              <label v-if="type !== 'percentage'">Coupon discount amount (INR)</label>
              <label v-else >Coupon discount rate (%)</label>
              <label style="color: red !important">*</label>
                  <validation-provider #default="{ errors }"  rules="required" name="Discount Amount">
                    <b-form-input v-model="amount" placeholder="Enter Amount"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group >
              <label>Minimum cart amount (INR)</label>
               <label style="color: red !important">*</label>
                    <validation-provider #default="{ errors }"  rules="required" name="Minimum cart amount (INR)">
                      <b-form-input v-model="minimum_cart_amount" placeholder="Enter Amount"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group >
              <label>Active From</label>
               <label style="color: red !important">*</label>
                  <validation-provider #default="{ errors }"  rules="required" name="Active From">
                    <flat-pickr
                      v-model="active_from"
                      :config="{
                        dateFormat: 'd/m/Y',
                      }"
                      class="form-control bg-transparent"
                      placeholder="Enter Date"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group>
              <label>Will Expire</label>
                 <label style="color: red !important">*</label>
                    <validation-provider #default="{ errors }"  rules="required" name="Will Expire">
                        <flat-pickr
                            v-model="expire_at"
                            :config="{
                              dateFormat: 'd/m/Y',
                              minDate:active_from
                            }"
                            :disabled="!active_from"
                            class="form-control "
                            :class="active_from?'bg-transparent':''"
                            placeholder="Enter Date"
                          />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6" >
            <b-form-group >
              <label >Type</label> <br>
              <b-form-radio v-model="type" danger value="free">Free Shipping</b-form-radio> <br>
              <b-form-radio v-model="type" danger value="fixed">Fixed</b-form-radio> <br>
              <b-form-radio v-model="type" danger value="percentage">Percentage</b-form-radio> <br>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-2">
            <b-button variant="primary" type="submit" @click.prevent="submitForm" class="mr-4" :disabled="check">
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back</b-button>
          </b-col>
     <gallary-image-model v-if="modelValue" :modelValue="modelValue" :openmodal="openmodal" @setImage="setImage"/>

        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";
import gallaryImageModel from "../../components/gallaryImageModel.vue";
import {
  required,
} from "@validations";

import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormRadio
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../components/Attechment.vue";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    quillEditor,
    flatPickr,
    BFormCheckboxGroup,
    BFormFile,

    gallaryImageModel,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BFormCheckbox,
    BFormRadio
  },
  data() {
    return {
      required,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      code: "",
      description:"",
      type:"fixed",
      check: false,
      description: "",
      id:'',
      image: {
          original: "",
          thumbnail: "",
          id: "",
          file_name: ''
        },
      amount:"",
      minimum_cart_amount:"",
      active_from:"",
      expire_at: "",
      selectedField: '',
      selectedIndex: false,
      selected: '',
      modelValue: '',
    };
  },
  async mounted() {
    this.ifEdit = !!this.$route.params.slug;
    this.ifEdit && this.getEditValue();
  },
  methods: {
     openmodal(name, index, field) {
      this.modelValue = !this.modelValue
      this.selected = name
      this.selectedField = field ? field : false
      this.selectedIndex = index
      this.$forceUpdate()
    },
    setImage(image) {
      if (this.selectedIndex && this.selectedField) {
        this[this.selected][this.selectedIndex - 1][this.selectedField] = image
      }
      else if (this.selectedIndex) {
        this[this.selected][this.selectedIndex - 1] = image
      }
      else {
        this[this.selected] = image
      }
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/coupons/${this.$route.params.slug}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.image = item.image ? item.image : {
          original: "",
          thumbnail: "",
          id: "",
          file_name: ''
      };
      this.amount= item.amount;
      this.minimum_cart_amount=item.minimum_cart_amount;
      this.active_from= item.active_from ? moment(item.active_from).format('DD/MM/yyyy') : null;
      this.expire_at=item.expire_at ? moment(item.expire_at).format('DD/MM/yyyy'):null;
      this.code = item.code;
      this.type = item.type;
      this.description = item.description;

    },
    submitForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          data = {
            code:this.code,
            amount:this.amount,
            minimum_cart_amount:this.minimum_cart_amount,
            active_from:moment(this.active_from,'DD/MM/yyyy').format('yyyy-MM-DD'),
            expire_at: moment(this.expire_at, 'DD/MM/yyyy').format('yyyy-MM-DD'),
            type:this.type,
            code: this.code,
            description: this.description,
          };
        }

        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.check = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/coupons/${this.$route.params.slug}`
              : `${baseApi}/coupons`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.check = false;
              this.$swal({
                title: "Submited",
                text: "Successfully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/coupons");
            })
            .catch((error) => {
              this.check = false;
              let message = '';
              if (error.response && error.response.data) {
                Object.values(error.response.data).map((item) => {
                    message +=`${item[0]} \n`
                });
                if(error)
                this.$swal({
                  title: "Error!",
                  html: message,
                  icon: "error",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
              else {

                this.$swal({
                  title: "Error!",
                  html: 'Some error occured',
                  icon: "error",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }

            });
        }
      });
    },
    onClickBack() {
      this.$router.push('/coupons');
    },
  },
};
</script>

