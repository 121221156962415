var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('vue-good-table',{staticClass:"table",attrs:{"columns":_vm.fields,"rows":_vm.allBackup,"isLoading":_vm.loadData,"select-options":{
        enabled: false,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: false,
        selectAllByGroup: false,

      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('div',[_c('b-badge',{staticClass:"mr-50 cursor-pointer",attrs:{"variant":"success","id":'download' + props.row.id},on:{"click":function($event){return _vm.handledownload(props.row.file)}}},[_c('feather-icon',{attrs:{"icon":"ArrowDownIcon"}})],1),_c('b-tooltip',{attrs:{"placement":"top","target":'download' + props.row.id,"triggers":"hover"}},[_vm._v(" Edit ")])],1):_c('div',[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }