<template>
  <div>
    <b-card>

      <div class="row  align-items-end justify-content-between">
        <!-- <div class="col-md-1">
          </div> -->
        <div class="col-md-5 mt-1">
          <label> Search</label>
          <b-form-input v-model="searchTerm" placeholder="Search" @input="searchApply" type="text"
            class="d-inline-block" />
        </div>
        <div class="col-md-3 mr-md-2 mt-1">
          <b-button variant="primary" @click="$router.push('/add-emailtemplate')" class="btn btn-primary float-right">
            Add Email Template
          </b-button>
        </div>
      </div>
    </b-card>
    <b-card>

      <vue-good-table class="table" :columns="fields" :rows="allData" :rtl="direction" :isLoading="loadData"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false,
          selectAllByGroup: false,
        }" :pagination-options="pagination">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'action'" style="width: fit-content; display: flex"
            class='position-relative p-1'>
            <b-badge variant="warning" :id="'edit' + props.row.id" class="mr-50 cursor-pointer"
              @click="handleEdit(props.row)">
              <feather-icon icon="Edit2Icon" />
            </b-badge>
            <!-- <b-badge variant="danger" :id="'delete' + props.row.id" class="cursor-pointer"
              @click="handleDelete(props.row)">
              <feather-icon icon="TrashIcon" />
            </b-badge> -->
            <b-tooltip placement="top" :target="'edit' + props.row.id" triggers="hover">
              Edit
            </b-tooltip>
            <!-- <b-tooltip placement="top" :target="'delete' + props.row.id" triggers="hover">
              Delete
            </b-tooltip> -->
          </span>
          <div v-else>
            {{ props.row[props.column.field] }}
          </div>
        </template>
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <!-- <b-form-select v-model="pageLength" :options="['5', '10', '20', '50', '100', '500']" class="mx-1"
                @input="changePagination()" />
              <span class="text-nowrap"> Showing {{ paginationStart }} to {{ paginationEnd }} of {{ paginationTotal
              }}</span> -->
            </div>
            <div>
              <b-pagination v-model="paginationValue" :total-rows="paginationTotal" :per-page="pageLength" first-number
                last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="changePagination('change')">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>

  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import axios from "@/components/axios";
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import { VueGoodTable } from "vue-good-table";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormSelect,
  BPagination,
  BButton,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
  BFormDatepicker,
  BImg,
  BImgLazy,
  BTooltip,
  BCard
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormGroup,
    BForm,
    VueGoodTable,
    BRow,
    BBadge,
    BFormInput,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BCol,
    BImg,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    BTooltip,
    BCard
  },
  data() {
    return {
      loadData: true,
      pagination: {
        enabled: true,
        mode: "records",
        perPage: 500,
        position: "bottom",
        perPageDropdown: [3, 7, 9],
        dropdownAllowAll: false,
        setCurrentPage: 2,
        jumpFirstOrLast: true,
        firstLabel: "First Page",
        lastLabel: "Last Page",
        nextLabel: "next",
        prevLabel: "prev",
        rowsPerPageLabel: "Rows per page",
        ofLabel: "of",
        pageLabel: "page", // for 'pages' mode
        allLabel: "All",
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`,
      },

      pageLength: 50,

      paginationStart: 0,

      paginationValue: 1,
      paginationTotal: 0,

      paginationEnd: 0,

      searchTerm: "",


      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [

        {
          field: "type",
          label: "Type",
        },
        {
          field: "subject",
          label: "Subject",
        },
        {
          field: "action",
          label: "Actions",
        },
      ],
      allData: [],
    };
  },
  computed: {
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  mounted() {
    this.paginationValue = this.$route.query.page ? this.$route.query.page : 1
    this.searchTerm = this.$route.query.search ? this.$route.query.search : ''
    this.getAllData();
  },
  methods: {
    handleEdit(row) {
      this.$router.push(`/edit-emailtemplate/${row.id}`);
    },
    handleDelete(row) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            const request = {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
              url: `${this.baseApi}/emailtemplate/${row.id}`,
            };
            axios(request).then((json) => {
              this.getAllData();
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "Your data has been deleted.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    searchApply: _.debounce(function () {
      this.getAllData();
    }, 1000),
    changePagination() {
      this.getAllData();
    },
    async getAllData() {
      await this.Setting();
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/emailtemplate?&page=${this.paginationValue}&limit=20&search=${this.searchTerm
            ? "name:" + this.searchTerm
            : ""
          }`,
      };
      await axios(request).then((json) => {
        this.loadData = false;
        this.allData = json.data.data;
        this.paginationTotal = json.data.total;
        this.paginationStart = json.data.from;
        this.paginationEnd = json.data.to;
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

