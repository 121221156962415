<template>
  <div>
    <b-card>

      <div class="row justify-content-between align-items-end">
        <!-- <div class="col-md-1">
          </div> -->
        <div class="col-md-6 mt-1">
          <b-input-group class="input-group-merge">
            <b-form-input id="search" v-model="searchTerm" class="form-control-merge" @input="searchApply" type="text" />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="col-md-6 mt-1">
          <b-button variant="primary" @click="addPopup = !addPopup" class="btn btn-primary float-right mr-1">
            Add Country
          </b-button>
        </div>
      </div>
    </b-card>
    <b-card>

      <vue-good-table class="table" :columns="fields" :rows="allUser" :rtl="direction" :isLoading="loadData"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false,
          selectAllByGroup: false,
        }" :pagination-options="pagination">
        <template slot="table-row" slot-scope="props">
           <div v-if="props.column.field === 'status'">
              <b-form-checkbox :checked="props.row.status ? true : false" class="custom-control-primary"
                name="check-button" @change="ToggleChanged(props.row, $event)" switch>
              </b-form-checkbox>
            </div>
            <span v-else-if="props.column.field === 'action'" style="width: fit-content; display: flex" class='position-relative'>
              <b-badge variant="warning" :id="'edit' + props.row.id" class="mr-50 cursor-pointer"
                @click="handleEdit(props.row)">
                <feather-icon icon="Edit2Icon" />
              </b-badge>

              <b-badge variant="danger" :id="'delete' + props.row.id" class="cursor-pointer"
                @click="handleDelete(props.row)">
                <feather-icon icon="TrashIcon" />
              </b-badge>
              <b-tooltip placement="top"  :target="'edit' + props.row.id" triggers="hover">
                Edit
              </b-tooltip>
              <b-tooltip placement="top"  :target="'delete' + props.row.id" triggers="hover">
                Delete
              </b-tooltip>
            </span>
          <div v-else>
            {{ props.row[props.column.field] }}
          </div>
        </template>
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <!-- <b-form-select v-model="pageLength" :options="['5', '10', '20', '50', '100', '500']" class="mx-1"
                @input="changePagination()" />
              <span class="text-nowrap"> Showing {{ paginationStart }} to {{ paginationEnd }} of {{ paginationTotal
              }}</span> -->
            </div>
            <div>
              <b-pagination v-if='showPagination' v-model="paginationValue" :total-rows="paginationTotal"
                :per-page="pageLength" first-number last-number align="right" prev-class="prev-item"
                next-class="next-item" class="mt-1 mb-0" @input="changePagination('change')">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>

    <b-modal id="modal-form" v-model="addPopup" @close="handleReset"  size="sm" cancel-variant="outline-secondary" ok-title="Submit"
      cancel-title="Close" title="Country" no-close-on-backdrop hide-footer>

        <validation-observer ref="simpleRules">
          <b-row>

            <b-col md="12">
                <b-form-group>
                  <label>Country Name</label>
                    <label style="color: red !important">*</label>
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Country"
                        >
                        <b-form-input v-model="country" placeholder="Enter Country Name"></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>
                 <b-col md="12">
                <b-form-group>
                  <label>Country Code</label>
                    <label style="color: red !important">*</label>
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Country Code"
                        >
                        <b-form-input v-model="country_code" placeholder="Enter Country Code"></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
              </b-col>
            <b-col md="12" class="text-right">
              <b-button variant="primary" class="mt-1" @click="submit"
                >Submit</b-button
              >
            </b-col>
          </b-row>
        </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import axios from "@/components/axios";
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import moment from "moment";
import { VueGoodTable } from "vue-good-table";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BInputGroup,
  BRow,
  BCol,
  BFormSelect,
  BPagination,
  BButton,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
  BFormDatepicker,
  BImg,
  BImgLazy,
  BInputGroupAppend,
  BTooltip,
  BCard
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  required,
} from "@validations";

export default {
  mixins: [togglePasswordVisibility],
  components: {
    BFormGroup,
    BForm,
    BTooltip,
    VueGoodTable,
    BRow,
    BBadge,
    BFormInput,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BInputGroup,
    BCol,
    BImg,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    BInputGroupAppend,
    BCard

  },
  data() {
    return {
      required,
      loadData: true,
      country:'',
      pagination: {
        enabled: true,
        mode: "records",
        perPage: 500,
        position: "bottom",
        perPageDropdown: [3, 7, 9],
        dropdownAllowAll: false,
        setCurrentPage: 2,
        jumpFirstOrLast: true,
        firstLabel: "First Page",
        lastLabel: "Last Page",
        nextLabel: "next",
        prevLabel: "prev",
        rowsPerPageLabel: "Rows per page",
        ofLabel: "of",
        pageLabel: "page", // for 'pages' mode
        allLabel: "All",
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`,
      },
      selectedId:0,

      pageLength: 20,
      employeeoption: [],
      paginationStart: 0,

      paginationValue: 1,
      paginationTotal: 0,

      paginationEnd: 0,

      searchTerm: "",
      result: "",
      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "name",
          label: "Name",
        },
        {
          field: "country_code",
          label: "Country Code",
        },
        {
          field: "status",
          label: "Status",
        },
        {
          field: "action",
          label: "Actions",
        },
      ],
      allUser: [],
      userId: 0,
      addPopup: false,
      walletPoint: 0,
      email: "",
      name: "",
      password: "",
      showPagination: false,
      selectedRow: {},
      country_code:''
    };
  },
  computed: {
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  mounted() {
    this.paginationValue = this.$route.query.page ? this.$route.query.page : 1
    this.searchTerm = this.$route.query.search ? this.$route.query.search : ''
    this.getAllCountry();
  },
  methods: {
    submit() {
      let data = {
        name: this.country,
        status: this.selectedId ? (this.selectedRow.status) : true,
        country_code : this.country_code
      };
      const request = {
        method: this.selectedId?'PUT':"POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url:
          this.selectedId
          ? `${this.baseApi}/country/${this.selectedId}`
          :`${this.baseApi}/country`,
        data: data,
      };
      axios(request).then((json) => {
        this.addPopup = false;
        this.country = ''
        this.country_code = ''
        this.getAllCountry();
        this.selectedId = 0;
        this.selectedRow = {};
        this.$swal({
          icon: "success",
          title: "Success",
          text: "Successfully added!",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      });
    },
    handleEdit(row) {
        const request = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/country/${row.id}`,
        };
        axios(request).then((json) => {
          this.addPopup = true
          this.country = json.data.name
          this.country_code = json.data.country_code
          this.selectedRow = json.data
          this.selectedId = json.data.id

        });
    },
    handleReset() {
      this.country=''
      this.country_code = ''
    },
    handleDelete(row) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            const request = {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
              url: `${this.baseApi}/country/${row.id}`,
            };
            axios(request).then((json) => {
              this.getAllCountry();
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "Your data has been deleted.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async ToggleChanged(row, e) {
      const request = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: {
          status: e ? 1 : 0,
          name:row.name
        },
        url: `${this.baseApi}/country/${row.id}`,
      };
      await axios(request).then((json) => {
        this.getAllCountry();
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Successfully Updated`,
            icon: "CheckIcon",
            variant: "success",
            text: `You have successfully Changed Status of ${json.data.name}.`,
          },
        });
      });
    },
    searchApply: _.debounce(function () {
      const url = new URL(window.location.href);
      if (this.searchTerm) {
        url.searchParams.set('search', this.searchTerm);
      }
      else {
        url.searchParams.delete('search')
      }
      window.history.replaceState({}, '', url);
      this.getAllCountry();
    }, 1000),
    changePagination() {
      const url = new URL(window.location.href);
      url.searchParams.set('page', this.paginationValue);
      window.history.replaceState({}, '', url);
      this.getAllCountry();
    },
    async getAllCountry() {
        this.loadData = true;

      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/country?page=${this.paginationValue}&limit=${this.pageLength
          }&search=${this.searchTerm
            ? "name:" + this.searchTerm : ""
          }`,
      };
      await axios(request).then((json) => {
        this.loadData = false;
        this.allUser = json.data.data;

        this.paginationTotal = json.data.total;
        this.paginationStart = json.data.from;
        this.paginationEnd = json.data.to;
        this.showPagination = true

      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

