<template>
  <div>
     <b-card>
        <b-row class="justify-content-end order">
          <b-col md="6">
            <label> Product</label>
            <v-select :options="productData" v-model="product"
              @input="searchApply" label="name"></v-select>
          </b-col>
        </b-row>
      </b-card>
    <b-card>
      <vue-good-table
        class="table mt-2"
        :columns="fields"
        :rows="allReviews"
        :rtl="direction"
        :isLoading="loadData"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false,
          selectAllByGroup: false,
        }"
        :pagination-options="pagination"
      >
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field === 'image'">
            <!-- {{ props.row.image.thumbnail }} -->
            <b-img
              v-if="
                props.row.product &&
                props.row.product.image &&
                props.row.product.image.thumbnail
              "
              :src="props.row.product.image.thumbnail"
              fluid
              v-bind="{ width: 100, height: 100 }"
            />
          </div>
          <div v-else-if="props.column.field === 'product'">
            <div v-if="props.row.product">
              {{ props.row.product.name }}
            </div>
          </div>
          <div v-else-if="props.column.field === 'customer_review'">
            <div v-if="props.row.user">
              By <b> {{ props.row.user.name }}</b>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 13 13"
                class="text-gray-700 ml-1"
                style="width: 1rem"
              >
                <g data-name="Group 36431" fill="currentColor">
                  <path
                    data-name="Path 22671"
                    d="M6.5,0A6.5,6.5,0,1,0,13,6.5,6.508,6.508,0,0,0,6.5,0Zm3.633,4.789L5.979,8.911a.639.639,0,0,1-.9.016l-2.2-2a.661.661,0,0,1-.049-.912.644.644,0,0,1,.912-.033l1.743,1.6L9.2,3.861a.657.657,0,0,1,.929.929Z"
                  ></path>
                </g>
              </svg>
            </div>
            <div style="margin: 5px">
              <b> {{ props.row.comment }} </b>
            </div>
          </div>
          <div v-else-if="props.column.field === 'rating'">
            <div class="rating">
              {{ props.row.rating }}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 25.056 24"
                class="ms-1 text-warning"
                style="width: 0.75rem; height: 0.75rem"
              >
                <g data-name="Group 36413" fill="currentColor">
                  <path
                    id="Path_22667"
                    data-name="Path 22667"
                    d="M19.474,34.679l-6.946-4.346L5.583,34.679a.734.734,0,0,1-1.1-.8L6.469,25.93.263,20.668a.735.735,0,0,1,.421-1.3l8.1-.566,3.064-7.6a.765.765,0,0,1,1.362,0l3.064,7.6,8.1.566a.735.735,0,0,1,.421,1.3L18.588,25.93l1.987,7.949a.734.734,0,0,1-1.1.8Z"
                    transform="translate(0 -10.792)"
                  ></path>
                </g>
              </svg>
            </div>
          </div>
          <div v-else-if="props.column.field === 'is_approved'">
            <b-form-checkbox
              :checked="props.row.is_approved ? true : false"
              class="custom-control-primary"
              name="check-button"
              @change="ToggleChanged(props.row.id, $event)"
              switch
            >
            </b-form-checkbox>
          </div>
          <span
            v-else-if="props.column.field === 'action'"
            style="width: fit-content; display: flex"
            class='position-relative'
          >
            <b-badge
              variant="danger"
              :id="'delete' + props.row.id"
              class="cursor-pointer"
              @click="handleDelete(props.row)"
            >
              <feather-icon icon="TrashIcon" />
            </b-badge>
            <b-tooltip placement="top"  :target="'delete' + props.row.id" triggers="hover">
              Delete
            </b-tooltip>
          </span>
          <div v-else>
            {{ props.row[props.column.field] }}
          </div>
        </template>
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <!-- <b-form-select v-model="pageLength" :options="['5', '10', '20', '50', '100', '500']" class="mx-1"
                @input="changePagination()" />
              <span class="text-nowrap"> Showing {{ paginationStart }} to {{ paginationEnd }} of {{ paginationTotal
              }}</span> -->
            </div>
            <div>
              <b-pagination
                v-model="paginationValue"
                :total-rows="paginationTotal"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="changePagination('change')"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import axios from "@/components/axios";
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import { VueGoodTable } from "vue-good-table";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormSelect,
  BPagination,
  BButton,
  BFormCheckbox,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BBadge,
  BFormDatepicker,
  BImg,
  BImgLazy,
  BCard,
  BTooltip
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormGroup,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    VueGoodTable,
    BRow,
    BBadge,
    BFormInput,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BCol,
    BImg,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    BCard,
    BTooltip
  },
  data() {
    return {
      branchOptions: [
        { name: "Channel Partner", id: "channelpartner" },
        { name: "Franchise", id: "franchise" },
        { name: "Admin", id: "admin" },
      ],
      searchTerm:'',
      leadsource: "",
      loadData: true,
      pagination: {
        enabled: true,
        mode: "records",
        perPage: 20,
        position: "bottom",
        perPageDropdown: [3, 7, 9],
        dropdownAllowAll: false,
        setCurrentPage: 2,
        jumpFirstOrLast: true,
        firstLabel: "First Page",
        lastLabel: "Last Page",
        nextLabel: "next",
        prevLabel: "prev",
        rowsPerPageLabel: "Rows per page",
        ofLabel: "of",
        pageLabel: "page", // for 'pages' mode
        allLabel: "All",
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`,
      },
      employee: "",
      pageLength: 20,
      employeeoption: [],
      paginationStart: 0,
      category: "",
      paginationValue: 1,
      paginationTotal: 0,
      categoryData: [],
      paginationEnd: 0,
      subemployee: "",
      subemployeeoption: [],
      searchTerm: "",
      leadsourceOptions: [],
      startdate: "",
      enddate: "",
      result: "",
      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "image",
          label: "Image",
          hidden: false,
        },
        {
          field: "customer_review",
          label: "Customer Review",
        },
        {
          field: "rating",
          label: "Rating",
        },
        {
          field: "product",
          label: "Product",
        },
        {
          field: "abusive_reports_count",
          label: "Reports",
        },
        {
          field: "date",
          label: "Date",
        },
        {
          field: "action",
          label: "Actions",
        },
      ],
      allReviews: [],
      productData: [],
      product: ''
    };
  },
  computed: {
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  mounted() {
    this.getAllReviews();
    this.getAllProduct()
  },
  methods: {
     searchApply: _.debounce(function () {
      this.getAllReviews();
     }, 1000),
    async getAllProduct() {
       const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/products?limit=10000000`,
      };
      await axios(request).then((json) => {
        this.productData= json.data.data
      });
    },
    handleDelete(row) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            const request = {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
              url: `${this.baseApi}/reviews/${row.id}`,
            };
            axios(request).then((json) => {
              this.getAllReviews();
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "Your data has been deleted.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async getAllReviews() {
      await this.Setting();
        this.loadData = true;

      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/reviews?with= product;user&page=${this.paginationValue}&limit=20&orderBy=created_at&sortedBy=desc${this.product && this.product.id ? ('&search=product_id:'+ this.product.id):''}`,
      };
      await axios(request).then((json) => {
        this.loadData = false;
        this.allReviews = json.data.data;
        this.allReviews.map((item) => {
          item.date = moment(item.created_at).fromNow();
        });
        this.paginationTotal = json.data.total;
        this.paginationStart = json.data.from;
        this.paginationEnd = json.data.to;
      });
    },
    changePagination() {
      this.getAllReviews();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.rating {
  border: 1px solid #6e6b7b;
  border-radius: 10px;
  display: flex;
  padding: 5px;
  justify-content: space-evenly;
  align-items: center;
  font-size: 17px;
}
</style>

