<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>


          <b-col md="6">
            <b-form-group>
              <label>Title</label>
                  <b-form-input v-model="name" />
            </b-form-group>
          </b-col>
          <b-col md="6">
                  <b-form-group>
                    <label>Banner redirect url</label>
                        <b-form-input v-model="redirecturl" placeholder="Enter url"/>
                  </b-form-group>
                </b-col>
       
          <b-col md="6">
                <b-form-group>
                  <label>Banner Image</label>
                  <div  @click="openmodal('cover_image')" class="fileuploader cursor-pointer d-flex align-items-center" style="border:1px solid silver;padding:10px;border-radius:5px;display:flex;justify-content:space-between">
                    <div><feather-icon icon="ImageIcon" size="20" /></div>
                    <div>Browse</div>
                  </div>
                </b-form-group>

              <attachment  v-if="cover_image && cover_image.thumbnail"  :data="cover_image.thumbnail" />
            </b-col>
             <b-col md="6">
                <b-form-group>
                  <label>Meta Title</label>
                  <b-form-input v-model="meta_title"  placeholder="Meta Title"/>
                </b-form-group>
              </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label>Meta Description</label>
                    <b-form-input v-model="meta_description"  placeholder="Meta Description"/>
                  </b-form-group>
                </b-col>

              <b-col md="12">
              <b-card-code title="Image" no-body />
            </b-col>
            <b-col md="12">
              <div style="width: 100%; overflow: auto; max-height: 500px">
                <table class="table table-bordered" style="min-width: 15cm; max-height: 15cm">
                  <thead class="text">
                    <tr>
                      <th></th>
                      <th>Images</th>
                      <th>Redirect Url</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, id) in gallery" :key="id" style="max-height: 100px">
                      <td>{{ id + 1 }}</td>
                      <td style="max-height: 50px">
                        <!-- <b-form-file v-model="item.thumbnail" placeholder="Select Image"
                        drop-placeholder="Drop file here..." @input="handleFileChange($event, 'gallery', id)" /> -->
                           <div  @click="openmodal('gallery', id + 1)" class="fileuploader cursor-pointer d-flex align-items-center" style="border:1px solid silver;padding:10px;border-radius:5px;display:flex;justify-content:space-between">
                        <div><feather-icon icon="ImageIcon" size="20" /></div>
                        <div>Browse</div>
                      </div>
                        <attachment v-if="item && item.thumbnail"  :data="item.thumbnail" />
                      </td>
                      <td>
                        <b-form-input v-model="item.redirecturl" placeholder="Enter url"/>
                      </td>
                      <td>
                        <b-button variant="outline-danger" @click="removeRow('gallery', id)">
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <b-button variant="primary" @click="addRow('gallery')" class="my-1">
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add More</span>
              </b-button>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label>Description</label>
                <quill-editor v-model="description" />
              </b-form-group>
            </b-col>


          <b-col cols="12" class="mt-2">
            <b-button variant="primary" type="submit" @click.prevent="submitForm" class="mr-4" :disabled="check">
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back</b-button>
          </b-col>
        </b-row>
      </b-form>
     <gallary-image-model v-if="modelValue" :modelValue="modelValue" :openmodal="openmodal" @setImage="setImage" :selectedIndex="selectedIndex"/>

    </validation-observer>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";


import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../components/Attechment.vue";
import gallaryImageModel from "../../components/gallaryImageModel.vue";
import {
  required,
} from "@validations";


export default {
  components: {
    quillEditor,
    flatPickr,
    BFormCheckboxGroup,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BFormCheckbox,
    gallaryImageModel
  },
  data() {
    return {
  required,

      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      name: "",
      check: false,
      cover_image:{
        original: "",
          thumbnail: "",
          id: "",
          file_name: ''
      },
      gallery: [
        {
          original: "",
          thumbnail: "",
          id: "",
          file_name: '',
          redirecturl:''
        },
      ],
      redirecturl:'',
      title: "",
      description: "",
      id: '',
      selectedField: '',
      selectedIndex: false,
      selected: '',
      modelValue: '',
      meta_title:'',
      meta_description:'',
    };
  },
  async mounted() {
    this.ifEdit = !!this.$route.params.slug;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    openmodal(name, index, field) {
      this.modelValue = !this.modelValue
      this.selected = name
      this.selectedField = field ? field : false
      this.selectedIndex = index
      this.$forceUpdate()
    },
    setImage(image) {
      if (this.selectedIndex && this.selectedField) {
        image.map((item, index) => {
          if (!this[this.selected][this.selectedIndex - 1 + index]) {
            this[this.selected].push({ ...this[this.selected][0] })
            for (let key in this[this.selected][0]) {
              this[this.selected][this.selectedIndex - 1 + index][key] = ''
            }
          }
          this[this.selected][this.selectedIndex - 1 + index][this.selectedField] = item
        })
      }
      else if (this.selectedIndex) {
        image.map((item, index) => {
          if (!this[this.selected][this.selectedIndex - 1 + index]) {
            this[this.selected].push({})
          }
          this[this.selected][this.selectedIndex - 1 + index] = item
        })
      }
      else {
        this[this.selected] = image
      }
    },
     convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/authors/${this.$route.params.slug}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.id = item.id
      this.name = item.name;
      this.description = item.description;
      this.gallery = item.gallery;
      this.redirecturl = item.redirecturl
      this.id = item.id;
      this.meta_title = item.meta_title
      this.meta_description = item.meta_description
      this.cover_image = item.cover_image?item.cover_image:{
        original: "",
          thumbnail: "",
          id: "",
          file_name: ''
      };

        setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
    submitForm() {
      if (this.gallery.length == 0) {
        this.$swal({
          title: "Warning",
          timer: 5000,
          text: "Plese Add Atleast One Banner",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return
      }
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          data = {
            id:this.id,
            name: this.name,
            description: this.description,
            gallery: this.gallery,
            cover_image: this.cover_image,
            redirecturl: this.redirecturl,
            meta_title:this.meta_title,
            meta_description:this.meta_description,
          };
           if (this.ifEdit) {
            data.slug= this.convertToSlug(this.name)
          }
        }

        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.check = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/authors/${this.id}`
              : `${baseApi}/authors`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.check = false;
              this.$swal({
                title: "Submited",
                text: "Successfully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/contentmanagement");
            })
            .catch((error) => {
              this.check = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    removeRow(name, index) {
      const vue = this;
      vue[name].splice(index, 1);
    },
    addRow(name) {
      const vue = this;
      vue[name].push({
        id: "",
        thumbnail: "",
        original: "",
        file_name: '',
        redirecturl:''
      });
    },
    async handleFileChange(e, name, id, idName) {
      this.check = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("attachment[]", e);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachments`,
      };
      await axios(requestOptions)
        .then((response) => {
          if (id == 0 || id) {
            this[name][id].thumbnail = response.data[0].thumbnail;
            this[name][id].id = response.data[0].id;
            this[name][id].original = response.data[0].original;
          }
          else {
            this[name].thumbnail = response.data[0].thumbnail
            this[name].file_name = e.name
            this[name].id = response.data[0].id
            this[name].original = response.data[0].original
          }
          this.check = false;
        })
        .catch((error) => {
          this.check = false;
        });
    },
    onClickBack() {
      this.$router.push('/contentmanagement');
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>
