<template>
  <div class="">
    <b-card>
      <b-row class="justify-content-end order">
        <b-col md="6">
          <b-input-group class="input-group-merge">
            <b-form-input
              id="search"
              v-model="searchTerm"
              class="form-control-merge"
              @input="searchApply"
              type="text"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <!-- <b-dropdown
          variant="link"
          toggle-class="text-decoration-none p-0"
          no-caret
        >
          <template v-slot:button-content>
            <feather-icon
              width="30"
              height="30"
              icon="AlignRightIcon"
              size="16"
              class="text-body align-middle mr-25"
            />
          </template>
          <b-dropdown-item
            variant="primary"
          style="left: -123px;"
          @click="handleExport"
          >
            <b>
              <feather-icon icon="ArrowDownIcon" class="mr-50" />
              Export Orders
            </b>
          </b-dropdown-item>
        </b-dropdown> -->
      </b-row>
    </b-card>
    <b-card>

    <vue-good-table
      class="table"
      :columns="fields"
      :rows="allOrders"
      :rtl="direction"
      :isLoading="loadData"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: false,
        selectAllByGroup: false,
      }"
      :pagination-options="pagination"
    >
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field === 'delivery_fee'">
          {{
            props.row.delivery_fee
              ? "₹" + props.row.delivery_fee.toLocaleString("en-IN")
              : "₹" + "0.00"
          }}
        </div>
         <div v-else-if="props.column.field === 'tracking_number'" class="pl-2">
            {{ props.row.tracking_number }}
        </div>
        <div v-else-if="props.column.field === 'status'">
          <b-badge :variant="setColor(props.row.order_status)">
            {{ props.row.order_status }}
          </b-badge>
        </div>
         <div v-else-if="props.column.field === 'payment_status'">
          <b-badge :variant="setColor(props.row.payment_status)">
            {{ props.row.payment_status }}
          </b-badge>
        </div>
        <div v-else-if="props.column.field === 'total'">
          {{
            props.row.total
              ? "₹" + props.row.total.toLocaleString("en-IN")
              : "₹" + 0.0
          }}
        </div>
        <div
          v-else-if="props.column.field === 'action'"
          style="display: flex; justify-content: center"
          class="w-100 h-100 position-relative p-1"
        >

          <b-badge
            variant="warning"
            :id="'view' + props.row.id"
            class="mr-50 cursor-pointer"
            @click="handleView(props.row)"
          >
            <feather-icon icon="EyeIcon" />
          </b-badge>
          <b-tooltip placement="top"  :target="'view' + props.row.id" triggers="hover">
            View
          </b-tooltip>
          <b-tooltip placement="top"  :target="'delete' + props.row.id" triggers="hover">
          Delete
          </b-tooltip>
        </div>
        <div v-else>
          {{ props.row[props.column.field] }}
        </div>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <!-- <b-form-select v-model="pageLength" :options="['5', '10', '20', '50', '100', '500']" class="mx-1"
                @input="changePagination()" />
              <span class="text-nowrap"> Showing {{ paginationStart }} to {{ paginationEnd }} of {{ paginationTotal
              }}</span> -->
          </div>
          <div>
            <b-pagination
              v-if="showPagination"
              v-model="paginationValue"
              :total-rows="paginationTotal"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="changePagination('change')"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    </b-card>

  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import axios from "@/components/axios";
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import { VueGoodTable } from "vue-good-table";
import {
  BFormInput,
  BInputGroupAppend,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormSelect,
  BPagination,
  BButton,
  BFormCheckbox,
  BBadge,
  BImg,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BCard,
  BTooltip
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";

export default {
  components: {
    BFormGroup,
    BInputGroupAppend,
    BCard,
    BForm,
    VueGoodTable,
    BRow,
    BBadge,
    BFormInput,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BCol,
    BImg,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BTooltip
  },

  data() {
    return {
      branchOptions: [
        { name: "Channel Partner", id: "channelpartner" },
        { name: "Franchise", id: "franchise" },
        { name: "Admin", id: "admin" },
      ],
      leadsource: "",
      loadData: true,
      pagination: {
        enabled: true,
        mode: "records",
        perPage: 500,
        position: "bottom",
        perPageDropdown: [3, 7, 9],
        dropdownAllowAll: false,
        setCurrentPage: 2,
        jumpFirstOrLast: true,
        firstLabel: "First Page",
        lastLabel: "Last Page",
        nextLabel: "next",
        prevLabel: "prev",
        rowsPerPageLabel: "Rows per page",
        ofLabel: "of",
        pageLabel: "page", // for 'pages' mode
        allLabel: "All",
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`,
      },
      employee: "",
      pageLength: 20,
      employeeoption: [],
      paginationStart: 0,
      paginationValue: 1,
      paginationTotal: 0,
      paginationEnd: 0,
      subemployee: "",
      subemployeeoption: [],
      searchTerm: "",
      leadsourceOptions: [],
      startdate: "",
      enddate: "",
      result: "",
      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "tracking_number",
          label: "Tracking Number",
        },
        {
          field: "delivery_fee",
          label: "Delivery Fee",
        },
        {
          field: "total",
          label: "Total",
        },
        {
          field: "order_date",
          label: "Order Date",
        },
        {
          field: "status",
          label: "Status",
        },
        {
          field: "payment_status",
          label: "Payment Status",
        },
        {
          field: "shipping_address",
          label: "Shipping Address",
        },
        {
          field: "action",
          label: "Actions",
        },
      ],
      allOrders: [],
      show: {},
      params: "",
      showPagination:false
    };
  },
  computed: {
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    setColor() {
      const statusColor = {
        "Order Processing": "warning",
        "Order Pending": "secondary",
        "Order At Local Facility": "info",
        "Order Out For Delivery": "primary",
        "Order Completed": "success",
        "Order Cancelled": "danger",
        'Payment Processing': "warning",
        'Payment Success': "success",

      };
      return (status) => statusColor[status];
    },
  },
  mounted() {
    this.paginationValue = this.$route.query.page ? this.$route.query.page : 1
    this.searchTerm = this.$route.query.search ? this.$route.query.search : ''
    this.getOrders();
  },
  methods: {
    async handleExport() {
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/export-order-url`,
      };
      await axios(request).then((json) => {
        window.open(json.data,'_blank')
      });
    },
    async handleMessage(row) {
      let data = {
        shop_id: row.shop_id,
        from: "admin",
      };
      const request = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/conversation`,
        data:data
      };
      await axios(request).then((json) => {});
    },
    searchApply: _.debounce(function () {
      const url = new URL(window.location.href);
      if (this.searchTerm) {
        url.searchParams.set('search', this.searchTerm);
      }
      else {
        url.searchParams.delete('search')
      }
      window.history.replaceState({}, '', url);

      this.getOrders();
    }, 1000),
    changePagination() {
      const url = new URL(window.location.href);
      url.searchParams.set('page', this.paginationValue);
      window.history.replaceState({}, '', url);
      this.getOrders();
    },
    handleView(row) {
      this.$router.push(`/orders/${row.id}`);
    },
    async getOrders() {
      this.loadData = true;

      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/orders?page=${this.paginationValue}&limit=${this.pageLength}${this.searchTerm?'&search=tracking_number:'+ this.searchTerm : ''}`,
      };
      await axios(request).then((json) => {
        this.loadData = false;
        this.allOrders = json.data.data;
        this.allOrders.map((item) => {
          item.order_date = moment(item.created_at).fromNow();

          let status = item.order_status.split("-");
          let paymentStatus = item.payment_status.split("-");

          let paymentStatus2 = paymentStatus.map((item) => {
            this.show[item.id] = false;
            return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
          });
          item.payment_status = paymentStatus2.join(" ");


          let status2 = status.map((item) => {
            this.show[item.id] = false;
            return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
          });
          item.order_status = status2.join(" ");

          let shipping_address = "";
          if (item.shipping_address) {

          shipping_address += item.shipping_address.street_address;
          shipping_address += item.shipping_address.city
            ? ", " + item.shipping_address.city
            : "";

          shipping_address += item.shipping_address.state
            ? ", " + item.shipping_address.state
            : "";
          shipping_address += item.shipping_address.zip
            ? ", " + item.shipping_address.zip
            : "";
          shipping_address += item.shipping_address.country
            ? ", " + item.shipping_address.country
            : "";
            item.shipping_address = shipping_address;
          }
        });
        this.paginationTotal = json.data.total;
        this.paginationStart = json.data.from;
        this.paginationEnd = json.data.to;
        this.showPagination=true
        this.$forceUpdate();
      });
    },
    // handleChild(item) {
    //   item.children.map((child) => {
    //     child.order_date = moment(child.created_at).fromNow();

    //     let status = child.order_status.split("-");
    //     let status2 = status.map((item1) => {
    //       return item1.charAt(0).toUpperCase() + item1.slice(1).toLowerCase();
    //     });
    //     child.order_status = status2.join(" ");

    //     let shipping_address = "";
    //     if (child.shipping_address) {

    //     shipping_address += child.shipping_address.street_address;
    //     shipping_address += (
    //       child.shipping_address.city ? "," + child.shipping_address.city : ""
    //     ).toString();
    //     shipping_address += child.shipping_address.state
    //       ? "," + child.shipping_address.state
    //       : "";
    //     shipping_address += child.shipping_address.zip
    //       ? "," + child.shipping_address.zip
    //       : "";
    //     shipping_address += child.shipping_address.country
    //       ? "," + child.shipping_address.country
    //       : "";

    //       child.shipping_address = shipping_address;
    //     }

    //   });
    // },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
<style>
.order .dropdown-menu.show{
  left:-57px !important
}
.rc-table-row-expand-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.rc-table-row-expand-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  color: #aaa;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid;
  cursor: pointer;
}
.rc-table-row-expand-icon.rc-table-row-collapsed:after {
  content: "+";
}
.rc-table-row-expand-icon.rc-table-row-expanded:after {
  content: "-";
}
</style>
