<style>
.img-fluid {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.carousel-inner {
  width: 100%;
  height: 100%;
}

.brand-logo {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  height: 160px;
  background-color: #fff !important;
  /* box-shadow: 0 0 18px 3px #000; */
}
</style>
<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 justify-content-center align-items-center" >
      <!-- <b-link class="brand-logo">
        <b-img
          :src="require('@/assets/images/logo/ngerplogo.png')"
          alt="logo"
          width="240px"
          height="135px"
        />
      </b-link> -->

      <!-- <b-col
        lg="8"
        style="width: 100%; height: 100%"
        class="p-0 d-none d-lg-flex align-items-center"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center"
          style="width: 100%; height: 100%"
        >
          <b-carousel
            style="width: 100%; height: 100%"
            id=" carousel-interval"
            controls
            indicators
            :interval="3000"
          >
            <b-carousel-slide
              v-for="itemImage in banner"
              :key="itemImage.id"
              :img-src="imagePath + itemImage"
              style="width: 100%; height: 100%"
            />
          </b-carousel>
        </div>
      </b-col> -->
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" md="6" class="d-flex align-items-center auth-bg px-2 p-lg-2" style="box-shadow:0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;min-height:460px; height:60%;font-size:20px" >
        <b-col sm="9" md="12" lg="12" class="px-xl-2 mx-auto">
          <div class="w-100" align="center">
            <b-img
            src="/anmol.png"
            alt="logo"
            width="240px"
            height="135px"
            />
          </div>
          <b-card-text class="mb-2" align="center">
          Login to admin
          </b-card-text>

          <!-- <b-alert variant="primary" show>
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"
                  ><span class="font-weight-bold">Admin:</span> admin@demo.com |
                  admin</small
                >
              </p>
              <p>
                <small class="mr-50"
                  ><span class="font-weight-bold">Client:</span> client@demo.com
                  | client</small
                >
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10"
            />
          </b-alert> -->

          <!-- form -->
          <validation-observer ref="loginForm">
            <b-form class="auth-login-form mt-2" @submit.prevent="login" >
              <!-- email -->
              <b-form-group label-for="login-email">
                  <label for="login-password" style="font-size:16px !important; ">Email</label>

                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password" style="font-size:16px !important; ">Password</label>
                  <!-- <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>Forgot Password?</small>
                  </b-link> -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  v-model="rememberMe"
                  @change="handelCheckbox($event)"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->
                    <!-- <b-modal
                    v-model="visible"
                    modal-class="modal-success"
                    centered
                    hide-footer
                    title="Agreement"
                  >
                    <b-card-text>
                    I hereby declare that all the information provided by me above is true to the best of my knowledge and belief and I am submitting this registration form to register with Project 	 of Nestoria Group as a Channel Partner and I agree and shall abide by the terms and conditions specified on the reverse of the page.
                    </b-card-text>
                    <div class="d-flex justify-content-end">
                    <b-button
                      variant="danger"
                      size="sm"
                      class=""
                      @click="handelClickAgreement('decline')">
                      Decline
                      </b-button>
                    <b-button
                      variant="success"
                      size="sm"
                      class="ml-2"
                      @click="handelClickAgreement('accept')">
                      Accept
                      </b-button>
                    </div>
                  </b-modal> -->

              <!-- submit buttons -->
              <b-button type="submit" variant="primary"

              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block>
                <!-- :disabled="invaalid" -->
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">or</div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from 'axios';
import VueCookies from 'vue-cookies';
import VuexyLogo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";



import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BCarousel,
  BCarouselSlide,
  BButton,
  BAlert,BModal,
  VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {

  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BModal,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BCarousel,
    BCarouselSlide,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      rememberMe: false,
      visible: false,
      status: false,
      password: "",
      userEmail: "",
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      logo: require("@/assets/images/logo/ngerplogo.png"),

      // validation rules
      required,
      banner: [],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      email,
    };
  },
  created(){
    const rememberedUsername = VueCookies.get('username');
    const rememberedPassword = VueCookies.get('password');

    if (rememberedUsername && rememberedPassword) {
      this.userEmail = rememberedUsername;
      this.password = rememberedPassword;
      this.rememberMe = true;
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    logoUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.logo = require("@/assets/images/logo/ngerplogo.png");
        return this.logo;
      }
      return this.logo;
    },
  },
  methods: {
    handelClickAgreement(value){
     if(value == 'decline'){
      this.visible = false
      this.rememberMe = false
      this.status = false
     }else if(value == 'accept'){
      this.visible = false
      this.rememberMe = true
      this.status = true
     }
    },
    handelCheckbox(e){
      this.visible = this.rememberMe
      // this.rememberMe =false
    },
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            // .catch((error) => {
            //   console.log(error, "error-");
            //   alert("Please Enter a Valid Password");
            //   this.$refs.loginForm.setErrors(error.response.data.error);
            // })
            .then((response) => {
              if (response.data.token) {
                useJwt.setToken(response.data.token);
                useJwt.setRefreshToken(response.data.token);
                localStorage.setItem("userData", JSON.stringify(response.permissions));
                this.$ability.update([
                  {
                    action: "manage",
                    subject: "all",
                  },
                ]);
                this.$router.replace(getHomeRouteForLoggedInUser('admin'));
                if (this.rememberMe) {
                  VueCookies.set('username', this.userEmail, '30d'); // Store the username for 30 days
                  VueCookies.set('password', this.password, '30d'); // Store the password for 30 days (Note: Not recommended for actual password storage)
                } else {
                  VueCookies.remove('username');
                  VueCookies.remove('password');
                }
                // this.$toast({
                //   component: ToastificationContent,
                //   position: "top-right",
                //   props: {
                //     title: `Welcome ${user.name || user.name}`,
                //     icon: "CoffeeIcon",
                //     variant: "success",
                //     text: `You have successfully logged in as ${user.role}. Now you can start to explore!`,
                //   },
                // });
              } else {
                  alert('Invalid Credentials');
                  this.$refs.loginForm.setErrors(error.response.data.error);
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
