<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <!-- <span class="user-status">
          {{ parentName.toUpperCase() }}
        </span> -->
        <!-- <span class="user-status">{{ userData.name }} {{userData.surname}} </span> -->
        <span class="user-status"
          >Admin</span
        >
        <!-- <span class="user-status" v-else
          >{{ userData.position ?userData.position.name:''}}</span
        > -->
      </div>
      <b-avatar
        size="40"
        :src="profile"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <b-img
          rounded
          :src="profile"
          style="width: 100%; height: 100%"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'pages-profile' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>Profile</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'backup' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="ArrowDownIcon" class="mr-50" />
      <span>Backup</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown
  >
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BImg,
  BMedia,
  BMediaAside,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import axios from '@/components/axios';
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg,
    BMedia,
    BMediaAside,
  },
  data() {
    return {
      parentName: "",
      accessToken: localStorage.getItem("accessToken"),
      userRole: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      profile: "",
      role:'',
      type:"",
      userType:'',

      avatarText,
    };
  },
  mounted() {
    if(this.accessToken){
      this.getUser()
    }
    // let arr=this.userRole.split('')
    // arr[0]= arr[0].toUpperCase()
    // this.role =arr.join('')

    // let arr2=this.userType.split('')
    // arr2[0]= arr2[0].toUpperCase()
    // this.type =arr2.join('')
  },
  methods: {
    async getUser() {
      const requestOptionsUsers = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/me`,
      };
      await axios(requestOptionsUsers).then((response) => {
        this.userData = response.data
        this.profile=this.userData.profile.avatar.thumbnail
      })
    },
    async logout() {
      // const requestOptionsUsers = {
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${this.accessToken}`,
      //   },
      //   url: `${this.baseApi}/logout`,
      // };

      // await axios(requestOptionsUsers).then((response) => {
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

        // Remove userData from localStorage

        const keys = Object.keys(localStorage);
        let i = keys.length;

        while (i--) {
          localStorage.removeItem(`${keys[i]}`);
        }
        // Reset ability
        this.$ability.update(initialAbility);

        // Redirect to login page
        this.$router.push({ name: "auth-login" });
      // });
    },
  },
};
</script>
