<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Name</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Name"
              >
              <b-form-input v-model="name" placeholder="Enter Name" />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
           <b-col md="4">
            <b-form-group>
              <label>Meta Title</label>
              <b-form-input v-model="meta_title"  placeholder="Meta Title"/>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <label>Meta Description</label>
                <b-form-input v-model="meta_description"  placeholder="Meta Description"/>
              </b-form-group>
            </b-col>
          <b-col md="12">
            <b-card-code title="Home Banner" no-body />
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto; max-height: 500px">
              <table
                class="table table-bordered"
                style="min-width: 40cm; max-height: 15cm"
              >
                <thead class="text">
                  <tr>
                    <th></th>
                    <th>Title
                    <label style="color: red !important">*</label>
                    </th>
                    <th>Images</th>
                    <th>Redirect URL</th>
                    <th style="width:500px">Text</th>
                    <th style="width:200px">Position</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, id) in banners"
                    :key="id"
                    style="max-height: 100px"
                  >
                    <td>{{ id + 1 }}</td>
                    <td>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="Banner Title"
                      >
                        <b-form-input
                          v-model="item.title"
                          placeholder="Enter Title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </td>
                    <td style="max-height: 50px">
                      <!-- <b-form-file
                        v-model="item.image.thumbnail"
                        placeholder="Select Image"
                        drop-placeholder="Drop file here..."
                        @input="handleFileChange($event, 'banners', id)"
                      /> -->

                      <div  @click="openmodal('banners',id+1,'image')" class="fileuploader cursor-pointer d-flex align-items-center" style="border:1px solid silver;padding:10px;border-radius:5px;display:flex;justify-content:space-between">
                        <div><feather-icon icon="ImageIcon" size="20" /></div>
                        <div>Browse</div>
                      </div>
                      <attachment v-if="item.image && item.image.thumbnail " :data="item.image.thumbnail"  />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.redirecturl"
                        placeholder="Enter URL"
                      />
                      </td>
                      <td>
                          <quill-editor v-model="item.bannertext" />
                        </td>
                        <td>
                          <v-select
                            v-model="item.position"
                            placeholder="Select Position"
                            :options="['Top Right','Top Left', 'Bottom Right', 'Bottom Left' ,'Center']"
                          />
                          </td>
                    <td>
                      <b-button
                        variant="outline-danger"
                        @click="removeRow('banners', id)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <small class="text-danger" v-if="banners.length==0" >Add at least one banner</small>
            </div>
            <b-button variant="primary" @click="addRow('banners')" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>
          <b-col md="12">
            <b-card-code title="Gazette Banners" no-body />
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto; max-height: 500px">
              <table
                class="table table-bordered"
                style="min-width: 15cm; max-height: 15cm"
              >
                <thead class="text">
                  <tr>
                    <th></th>
                    <th>Title
              <label style="color: red !important">*</label>

                    </th>
                    <th>Images</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, id) in gazettes"
                    :key="id"
                    style="max-height: 100px"
                  >
                    <td>{{ id + 1 }}</td>
                    <td>
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="Gazettes Title"
                        >
                      <b-form-input
                        v-model="item.title"
                        placeholder="Enter Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </td>
                    <td style="max-height: 50px">
                      <!-- <b-form-file
                        v-model="item.image.thumbnail"
                        placeholder="Select Image"
                        drop-placeholder="Drop file here..."
                        @input="handleFileChange($event, 'gazettes', id)"
                      /> -->
                       <div  @click="openmodal('gazettes', id + 1, 'image')" class="fileuploader cursor-pointer d-flex align-items-center" style="border:1px solid silver;padding:10px;border-radius:5px;display:flex;justify-content:space-between">
                          <div><feather-icon icon="ImageIcon" size="20" /></div>
                          <div>Browse</div>
                        </div>
                      <attachment v-if=" item.image && item.image.thumbnail" :data="item.image.thumbnail" />
                    </td>
                    <td>
                      <b-button
                        variant="outline-danger"
                        @click="removeRow('gazettes', id)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button
              variant="primary"
              @click="addRow('gazettes')"
              class="my-1"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>

          <b-col md="12">
            <b-card-code title="New Banner" no-body />
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto; max-height: 500px">
              <table
                class="table table-bordered"
                style="min-width: 15cm; max-height: 15cm"
              >
                <thead class="text">
                  <tr>
                    <th></th>
                    <th>Title
              <label style="color: red !important">*</label>

                    </th>
                    <th>Images</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, id) in newbanners"
                    :key="id"
                    style="max-height: 100px"
                  >
                    <td>{{ id + 1 }}</td>
                    <td>
                       <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="New Banner Title"
                          >
                      <b-form-input
                        v-model="item.title"
                        placeholder="Enter Title"
                      />
                       <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                    </td>
                    <td style="max-height: 50px">
                      <!-- <b-form-file
                        v-model="item.image.thumbnail"
                        placeholder="Select Image"
                        drop-placeholder="Drop file here..."
                        @input="handleFileChange($event, 'newbanners', id)"
                      /> -->
                      <div  @click="openmodal('newbanners', id + 1, 'image')" class="fileuploader cursor-pointer d-flex align-items-center" style="border:1px solid silver;padding:10px;border-radius:5px;display:flex;justify-content:space-between">
                            <div><feather-icon icon="ImageIcon" size="20" /></div>
                            <div>Browse</div>
                          </div>
                      <attachment v-if="item.image && item.image.thumbnail" :data="item.image.thumbnail" />
                    </td>
                    <td>
                      <b-button
                        variant="outline-danger"
                        @click="removeRow('newbanners', id)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button
              variant="primary"
              @click="addRow('newbanners')"
              class="my-1"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>

          <b-col md="12">
            <b-card-code title="About Us Banner" no-body />
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto; max-height: 500px">
              <table
                class="table table-bordered"
                style="min-width: 15cm; max-height: 15cm"
              >
                <thead class="text">
                  <tr>
                    <th></th>
                    <th>Title
              <label style="color: red !important">*</label>

                    </th>
                    <th>description</th>
                    <th>Images</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, id) in aboutus"
                    :key="id"
                    style="max-height: 100px"
                  >
                    <td>{{ id + 1 }}</td>
                    <td>
                       <validation-provider
                              #default="{ errors }"
                              rules="required"
                              name="About Us Title"
                            >
                      <b-form-input
                        v-model="item.title"
                        placeholder="Enter Title"
                      />
                       <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                    </td>
                    <td>
                      <b-form-textarea
                      rows="5"
                        v-model="item.description"
                        placeholder="Enter Description"
                      />
                    </td>
                    <td style="max-height: 50px">
                      <!-- <b-form-file
                        v-model="item.image.thumbnail"
                        placeholder="Select Image"
                        drop-placeholder="Drop file here..."
                        @input="handleFileChange($event, 'aboutus', id)"
                      /> -->
                      <div  @click="openmodal('aboutus', id + 1, 'image')" class="fileuploader cursor-pointer d-flex align-items-center" style="border:1px solid silver;padding:10px;border-radius:5px;display:flex;justify-content:space-between">
                              <div><feather-icon icon="ImageIcon" size="20" /></div>
                              <div>Browse</div>
                            </div>
                      <attachment  v-if="item.image && item.image.thumbnail" :data="item.image.thumbnail" />
                    </td>
                    <td>
                      <b-button
                        variant="outline-danger"
                        @click="removeRow('aboutus', id)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addRow('aboutus')" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>

          <b-col md="12">
            <b-card-code title="Shop Banner" no-body />
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto; max-height: 500px">
              <table
                class="table table-bordered"
                style="min-width: 15cm; max-height: 15cm"
              >
                <thead class="text">
                  <tr>
                    <th></th>
                    <th>Title
              <label style="color: red !important">*</label>

                    </th>
                    <th>Images</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, id) in shopbanner"
                    :key="id"
                    style="max-height: 100px"
                  >
                    <td>{{ id + 1 }}</td>
                    <td>
                       <validation-provider
                          #default="{ errors }"
                          rules="required"
                          name="About Us Title"
                        >
                      <b-form-input
                        v-model="item.title"
                        placeholder="Shop Banner Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                    </td>
                    <td style="max-height: 50px">
                      <!-- <b-form-file
                        v-model="item.image.thumbnail"
                        placeholder="Select Image"
                        drop-placeholder="Drop file here..."
                        @input="handleFileChange($event, 'shopbanner', id)"
                      /> -->
                       <div  @click="openmodal('shopbanner', id + 1, 'image')" class="fileuploader cursor-pointer d-flex align-items-center" style="border:1px solid silver;padding:10px;border-radius:5px;display:flex;justify-content:space-between">
                          <div><feather-icon icon="ImageIcon" size="20" /></div>
                          <div>Browse</div>
                        </div>
                      <attachment v-if="item.image && item.image.thumbnail" :data="item.image.thumbnail" />
                    </td>
                    <td>
                      <b-button
                        variant="outline-danger"
                        @click="removeRow('shopbanner', id)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button
              variant="primary"
              @click="addRow('shopbanner')"
              class="my-1"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>

          <b-col md="12" class="mt-2">
            <b-card-code title="Terms & conditions" no-body />
          </b-col>
          <b-col md="12">
            <validation-provider
                #default="{ errors }"
                rules="required"
                name="Terms & conditions"
              >
            <quill-editor v-model="termsconditions" />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-col>

          <b-col md="12" class="mt-4">
            <b-card-code title="Privacy policy" no-body />

          </b-col>
            <b-col md="12">

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Privacy policy"
              >
                <quill-editor v-model="privacypolicy" />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-col>
          <b-col cols="12" class="mt-2">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              class="mr-4"
              :disabled="check"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
     <gallary-image-model v-if="modelValue" :modelValue="modelValue" :openmodal="openmodal" @setImage="setImage" :selectedIndex="selectedIndex"/>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";

import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../components/Attechment.vue";
import gallaryImageModel from "../../components/gallaryImageModel.vue";
import {
  required,
} from "@validations";




export default {
  components: {
    quillEditor,
    flatPickr,
    BFormCheckboxGroup,
    gallaryImageModel,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BFormCheckbox,
  },
  data() {
    return {
      required,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      name: "",
      meta_title:'',
      meta_description:'',
      check: false,
      banners: [
        {
          title: "",
          image: {
            original: "",
            thumbnail: "",
            id: "",
          },
          redirecturl: '',
          bannertext:'',
          position:''
        },
      ],
      gazettes: [
        {
          title: "",
          image: {
            original: "",
            thumbnail: "",
            id: "",
          },
        },
      ],
      newbanners: [
        {
          title: "",
          image: {
            original: "",
            thumbnail: "",
            id: "",
          },
        },
      ],
      aboutus: [
        {
          title: "",
          image: {
            original: "",
            thumbnail: "",
            id: "",
          },
          description: "",
        },
      ],
      shopbanner: [
        {
          title: "",
          image: {
            original: "",
            thumbnail: "",
            id: "",
          },
        },
      ],
      termsconditions: "",
      privacypolicy: "",
      id: '',
       selectedField: '',
      selectedIndex: false,
      selected: '',
      modelValue:''

    };
  },
  async mounted() {
    this.ifEdit = !!this.$route.params.slug;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    openmodal(name, index, field) {
      this.modelValue = !this.modelValue
      this.selected = name
      this.selectedField = field ? field : false
      this.selectedIndex = index
      this.$forceUpdate()
    },
    setImage(image) {
      if (this.selectedIndex && this.selectedField) {
        image.map((item, index) => {
          if (!this[this.selected][this.selectedIndex - 1 + index]) {
            this[this.selected].push({...this[this.selected][0]})
            for (let key in this[this.selected][0]) {
              this[this.selected][this.selectedIndex - 1 + index][key]= ''
            }
          }
          this[this.selected][this.selectedIndex - 1 + index][this.selectedField] = item
        })

      }
      else if (this.selectedIndex) {
        image.map((item, index) => {
          if (!this[this.selected][this.selectedIndex - 1 + index]) {
            this[this.selected].push({})
          }
          this[this.selected][this.selectedIndex - 1 + index][this.selectedField] = item

        })
      }
      else {
        this[this.selected] = image
      }
    },
    async getEditValue() {
      // console.log(this.$route.params.id, "id====");
      await axios({
        method: "GET",
        url: `${this.baseApi}/types/${this.$route.params.slug}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data);
        })
        .catch((error) => console.log(error, "error"));
    },

       convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    getEditItems(item) {
      this.name = item.name;
      this.meta_title = item.meta_title;
      this.meta_description = item.meta_description;
      this.gazettes = item.gazettes;
      this.newbanners = item.newbanners;
      this.termsconditions = item.termsconditions;
      this.privacypolicy = item.privacypolicy;
      this.shopbanner = item.shopbanner;
      this.banners = item.banners;
      this.aboutus = item.aboutus;
      this.id = item.id
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);

    },
    submitForm() {
      if (this.banners.length == 0) {
        this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Add Atleast One Banner",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
        });
          return
      }
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          data = {
            name: this.name,
            gazettes: this.gazettes,
            newbanners: this.newbanners,
            termsconditions: this.termsconditions,
            privacypolicy: this.privacypolicy,
            shopbanner: this.shopbanner,
            banners: this.banners,
            aboutus: this.aboutus,
            meta_title : this.meta_title,
            meta_description : this.meta_description,
          };
          if (this.ifEdit) {
            data.slug= this.convertToSlug(this.name)
          }
        }

        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.check = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/types/${this.id}`
              : `${baseApi}/types`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.check = false;
              this.$swal({
                title: "Submited",
                text: "Successfully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/banner");
            })
            .catch((error) => {
              this.check = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    removeRow(name, index) {
      const vue = this;
      vue[name].splice(index, 1);
    },
    addRow(name) {
      const vue = this;
      if (name == "aboutus") {
        vue[name].push({
          image: {
            id: "",
            thumbnail: "",
            original: "",
          },
          description: "",
          title: "",
        });
      }
      else if (name == "banners") {
        vue[name].push({
          image: {
            id: "",
            thumbnail: "",
            original: "",
          },
          redirecturl: "",
          bannertext: '',
          position: '',
          title: "",
        });
      }
      else {
        vue[name].push({
          image: {
            id: "",
            thumbnail: "",
            original: "",
          },
          title: "",
        });
      }
    },
    async handleFileChange(e, name, id, idName) {
      this.check = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("attachment[]", e);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachments`,
      };
      await axios(requestOptions)
        .then((response) => {
          this[name][id].image.thumbnail = response.data[0].thumbnail;
          this[name][id].image.id = response.data[0].id;
          this[name][id].image.original = response.data[0].original;
          this.check = false;
        })
        .catch((error) => {
          this.check = false;
        });
    },
    onClickBack() {
      this.$router.push('/banner');
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>

