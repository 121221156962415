export default [
  // {
  //   header: 'Apps & Pages'
  // },
  {
    title: 'Product',
    route: 'product',
    icon: 'BoxIcon'
  },
  {
    title: 'Banner',
    route: 'banner',
    icon: 'CodepenIcon'
  },
  {
    title: 'Categories',
    route: 'categories',
    icon: 'GridIcon'
  },
  {
    title: 'Latest Update',
    route: 'latestupdate',
    icon: 'TagIcon'
  },
  {
    title: 'Coupons',
    route: 'Coupons',
    icon: 'FileTextIcon'
  },
  {
    title: 'Gazette',
    route: 'gazette',
    icon: 'BookIcon'
  },
  {
    title: 'CMS',
    route: 'ContentManagement',
    icon: 'PenToolIcon'
  },
  {
    title: 'Location And Currency',
    icon: 'MapIcon',
    children: [
      {

        title: 'Country',
        route: 'country',
        icon: 'MapIcon',
      },
      {

        title: 'State',
        route: 'state',
        icon: 'MapIcon',
      },
      {

        title: 'City',
        route: 'city',
        icon: 'MapIcon',
      },
      {

        title: 'Currency',
        route: 'currency',
        icon: 'DollarSignIcon',
      }
    ]
  },
  {
    title: 'Testimonial',
    route: 'testimonial',
    icon: 'UserIcon'
  },
  {
    title: 'Orders',
    route: 'orders',
    icon: 'ShoppingCartIcon'
  },
  // {
  //   title: 'Create Order',
  //   route: 'createorder',
  //   icon: 'ShoppingBagIcon'
  // },
  {
    title: 'Users',
    route: 'users',
    icon: 'UsersIcon'
  },
  {
    title: 'Notify Users',
    route: 'notify-users',
    icon: 'MailIcon'
  },
  // {
  //   title: 'Coupons',
  //   route: 'Coupons',
  //   icon: 'GiftIcon'
  // },
  // {
  //   title: 'Taxes',
  //   route: 'taxes',
  //   icon: 'ServerIcon'
  // },
  {
    title: 'Shippings',
    route: 'Shipping',
    icon: 'TruckIcon'
  },
  {
    title: 'Reviews',
    route: 'Reviews',
    icon: 'ThumbsUpIcon'
  },
  {
    title: 'Gallery',
    route: 'Gallery',
    icon: 'ImageIcon'
  },
  {
    title: 'Marquee',
    route: 'marquee',
    icon: 'PenToolIcon'
  },
  {
    title: 'Email Templates',
    route: 'emailtemplate',
    icon: 'MailIcon'
  },
  {
    title: 'Settings',
    route: 'Settings',
    icon: 'SettingsIcon'
  },
]
