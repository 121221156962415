<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        v-if="notifications.length > 0"
        :badge="notifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
      <feather-icon
        v-else
        :badge="notifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div
        class="dropdown-header d-flex justify-content-between align-items-center p-1"
      >
        <h4 class="notification-title mb-0 mr-auto d-flex align-items-center">
          <b-form-checkbox
          class="d-flex align-items-center"
          v-model="allNotification"
          @change="handleCheckAll($event)"
          ></b-form-checkbox>Notifications</h4>
        <b-badge pill variant="light-primary">
          {{ notifications.length }} New
        </b-badge>
        <!-- <b-button variant="primary" size="sm" @click="handleRead('single')">
          Mark As Read
        </b-button> -->
      </div>
    </li>

    <!-- Notifications -->
    <div
      class="scrollable-container media-list scroll-area overflow-auto"
      tagname="li"
    >
      <!-- Account Notification -->
      <div v-for="notification in notifications" :key="notification.id" class="p-1">
        <div class="d-flex">
          <div>
            <div class="notiContainer d-flex align-items-center h-100">
              <b-form-checkbox
              v-model="notification.action" @change="handleCheck($event, notification)"
              ></b-form-checkbox>
              <b-avatar size="32" :variant="getColor(notification)">
                <feather-icon icon="CheckIcon"/>
              </b-avatar>
            </div>
          </div>
          <div class="media-heading pl-1">
            <span class="font-weight-bolder">
              {{ notification.text }}
            </span>
            <p>
              <small :class="`notification-text w-100 text-${getColorSmall(notification)}`"><b>{{ notification.title }}</b></small>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="handleRead()"
        >Read {{notificationIds.length==0?'all' :''}} notifications</b-button
      >
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import axios from "axios";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      notifications: [],
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      checkedItem: [],
      notificationIds: [],
      allNotification:false
    };
  },
  computed: {
     getColor() {
      let obj = {
        'sitevisit': 'light-success',
        'channelpartner': 'light-danger',
        'franchise': 'light-danger',
        'employee': 'light-danger',
        'task': 'light-warning',
        'lead': 'light-primary',
        'none':'light-secondary'
      }

      return (item) => {
        for (let key in obj) {
          if (item.title.replace(/\s/g, "").toLowerCase().includes(key)) {
            return obj[key]
          }
        }
        return 'light-decondary'
      };

    },
    getColorSmall() {
      let obj = {
        'sitevisit': 'success',
        'channelpartner': 'danger',
        'franchise': 'danger',
        'employee': 'danger',
        'task': 'warning',
        'lead': 'primary',
        'none':'secondary'
      }

      return (item) => {
        for (let key in obj) {
          if (item.title.replace(/\s/g, "").toLowerCase().includes(key)) {
            return obj[key]
          }
        }
        return 'decondary'
      };
    }
  },
  mounted() {
    // this.getNotification();
    setInterval(() => {
      // this.getNotification();
    }, 1000 * 60 * 5);
  },
  methods: {
    handleCheckAll(e){
      if(e){
        this.notifications.map((noti) => {
          noti.action=true
          this.notificationIds.push(noti.id);
        });
      }
      else{
        this.notifications.map((noti) => {
          noti.action=false
        })
        this.notificationIds=[]
      }
    },
    handleCheck(e, noti) {
      noti.action = e;
      if (e) {
        this.notificationIds.push(noti.id);
      } else {
        let index = this.notificationIds.indexOf(noti.id);
        this.notificationIds.splice(index, 1);
      }
    },
    async handleRead() {
      if (this.notificationIds.length == 0) {
        this.notificationIds = [];
        this.notifications.map((noti) => {
          this.notificationIds.push(noti.id);
        });
      }
      await axios({
        method: "POSt",
        url: `${this.baseApi}/change-notification-status`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify({
          ids: this.notificationIds,
        }),
      }).then((response) => {
        // this.getNotification();
      });
    },
    // async getNotification() {
      // await axios({
    //     method: "GET",
    //     url: `${this.baseApi}/send-notification`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${this.accessToken}`,
    //     },
    //   }).then((response) => {
    //     this.notifications = response.data;
    //   });
    // },

  },
  setup() {
    /* eslint-disable global-require */
    // const notifications = [
    //   {
    //     title: 'Congratulation Sam 🎉',
    //     avatar: require('@/assets/images/avatars/6-small.png'),
    //     subtitle: 'Won the monthly best seller badge',
    //     type: 'light-success'
    //   },
    //   {
    //     title: 'New message received',
    //     avatar: require('@/assets/images/avatars/9-small.png'),
    //     subtitle: 'You have 10 unread messages',
    //     type: 'light-info'
    //   },
    //   {
    //     title: 'Revised Order 👋',
    //     avatar: 'MD',
    //     subtitle: 'MD Inc. order updated',
    //     type: 'light-danger'
    //   }
    // ]
    // /* eslint-disable global-require */

    // const systemNotifications = [
    //   {
    //     title: 'Server down',
    //     subtitle: 'USA Server is down due to hight CPU usage',
    //     type: 'light-danger',
    //     icon: 'XIcon'
    //   },
    //   {
    //     title: 'Sales report generated',
    //     subtitle: 'Last month sales report generated',
    //     type: 'light-success',
    //     icon: 'CheckIcon'
    //   },
    //   {
    //     title: 'High memory usage',
    //     subtitle: 'BLR Server using high memory',
    //     type: 'light-warning',
    //     icon: 'AlertTriangleIcon'
    //   }
    // ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      // notifications,
      // systemNotifications,
      perfectScrollbarSettings,
    };
  },
};
</script>
<style>
.notiContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
