var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"dashboard-analytics"}},[_c('b-row',[_c('b-col',{attrs:{"md":"3","sm":"6","cols":"12"}},[_c('StatisticCardHorizontalDashboard',{attrs:{"statistic":"Total Revenue","statisticTitle":"","subtitle":_vm.totalRevenue,"icon":"rupee","color":"success"}})],1),_c('b-col',{attrs:{"md":"3","cols":"12","sm":"6"}},[_c('StatisticCardHorizontalDashboard',{attrs:{"statistic":"Total Order","statisticTitle":"","subtitle":_vm.totalOrders,"icon":"ShoppingCartIcon","color":"danger"}})],1),_c('b-col',{attrs:{"md":"3","cols":"12","sm":"6"}},[_c('StatisticCardHorizontalDashboard',{attrs:{"statistic":"Today's Revenue","statisticTitle":"","subtitle":_vm.totalRevenue,"icon":"rupee","color":"warning"}})],1),_c('b-col',{attrs:{"md":"3","cols":"12","sm":"6"}},[_c('StatisticCardHorizontalDashboard',{attrs:{"statistic":"New Customers","statisticTitle":"","subtitle":_vm.newCustomers,"icon":"UsersIcon","color":"primary"}})],1)],1),_c('div',[_c('b-card-code',{attrs:{"title":"Recent Orders"}},[_c('vue-good-table',{staticClass:"table mt-2",attrs:{"columns":_vm.fields,"rows":_vm.allOrders,"rtl":_vm.direction,"isLoading":_vm.loadData,"select-options":{
          enabled: false,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false,
          selectAllByGroup: false,
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(
              props.column.field === 'tracking_number' && !props.row.children
            )?_c('div',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(props.row.tracking_number)+" ")]):(props.column.field === 'order_status')?_c('div',[_c('b-badge',{attrs:{"variant":_vm.setColor(props.row.order_status)}},[_vm._v(" "+_vm._s(props.row.order_status)+" ")])],1):(props.column.field === 'payment_status')?_c('div',[_c('b-badge',{attrs:{"variant":_vm.setColor(props.row.payment_status)}},[_vm._v(" "+_vm._s(props.row.payment_status)+" ")])],1):(props.column.field === 'total')?_c('div',[_vm._v(" "+_vm._s(props.row.total ? "₹" + props.row.total.toLocaleString("en-IN") : "₹" + 0.0)+" ")]):_c('div',[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")])]}}])})],1),_c('div',[_c('b-card-code',{attrs:{"title":"Popular Products"}},[_c('vue-good-table',{staticClass:"table",attrs:{"columns":_vm.productfields,"rows":_vm.allProducts,"rtl":_vm.direction,"isLoading":_vm.loadDataProduct,"select-options":{
          enabled: false,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false,
          selectAllByGroup: false,
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'group' && props.row.type)?_c('div',[_vm._v(" "+_vm._s(props.row.type.name)+" ")]):_vm._e(),(props.column.field === 'shop' && props.row.shop)?_c('div',[_vm._v(" "+_vm._s(props.row.shop.name)+" ")]):_c('div',[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }