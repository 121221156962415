<template>
  <div>
    <b-card>
      <div class="row ">
        <!-- <div class="col-md-1">
        </div> -->
        <div class="col-md-4 mt-1">
          <b-input-group class="input-group-merge">
            <b-form-input id="search" v-model="searchTerm" placeholder="search" class="form-control-merge" @input="searchApply" type="text" />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="col-md-4 mt-1">
          <v-select id="sorting" placeholder="Select..." v-model="order_by" class="form-control-merge"
            @input="handleOrderBy($event)" :options="['Oldest', 'Newest']" />
        </div>
        <div class="col-md-4 mt-1">
          <b-button variant="primary" @click="handleClickAdd()" class="btn btn-primary float-right mr-1">
            Add Image
          </b-button>
        </div>
      </div>
    </b-card>
    <b-card>
      <b-row>
        <b-col md="3" cols="6" v-for="(image, index) in allImage" :key="index">
          <div class="h-100 imgBox p-1">
            <div class="w-100 galleryGrid " style="text-align:right">
            <b-dropdown variant="link" toggle-class="text-decoration-none p-0" no-caret style="width:20px;position: absolute;top: 20px;right: 33px;background-color: white;">
                <template v-slot:button-content>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                  </svg>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
                  <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                </svg> -->
                <!-- <feather-icon width="30" height="30" icon="AlignRightIcon" size="16"
                  class="text-body align-end mr-25" /> -->
              </template>
              <b-dropdown-item variant="primary" @click="handleDelete(image)">
                <b>
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  Delete
                </b>
              </b-dropdown-item>
              <b-dropdown-item variant="primary" @click="handleCopyLink(image)">
                  <b>
                    <feather-icon icon="CopyIcon" class="mr-50" />
                    Copy Link
                  </b>
                </b-dropdown-item>
             <b-dropdown-item variant="primary" @click="handleDownload(image)">
                  <b>
                    <feather-icon icon="DownloadIcon" class="mr-50" />
                    Download
                  </b>
              </b-dropdown-item>
              </b-dropdown>
              </div>

            <div class="d-flex flex-column justify-content-between">
              <img :src="image.thumbnail" alt="Preview" class="imageCustom" style="border-radius:10px;width:100%"
                height="160" />
            </div>
            <div class="w-100 d-flex justify-content-between align-items-center mt-1">
              <div class="imgesGallery"> {{ image.name }}</div>
            </div>
          </div>
        </b-col>
      </b-row>
      <div>
        <b-pagination v-model="paginationValue" :total-rows="paginationTotal" :per-page="pageLength" first-number
          last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
          @input="changePagination('change')">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card>
    <b-modal id="modal-form" v-model="imagePopup"  @hidden="resetModal"  size="lg" cancel-variant="outline-secondary" ok-title="Submit"
      cancel-title="Close" title="Add Image" no-close-on-backdrop hide-footer>
      <b-form-file v-model="images" placeholder="Select Image... " drop-placeholder="Drop file here..."
         @input="handleFileChange($event)" multiple/>
        <div class="d-flex flex-wrap">
          <div v-for="(item, index) in images" :key="index">
            <attachment  :data="item.thumbnail" class="mr-1 mt-1" />
          </div>
        </div>
      <div class="text-right">
        <b-button variant="primary" :disabled="check" class="mt-1" @click="handleAddImage">Submit</b-button>
      </div>
    </b-modal>

  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import axios from "@/components/axios";
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import { VueGoodTable } from "vue-good-table";
import Attachment from "../../components/Attechment.vue";
import {
  required,
} from "@validations";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormSelect,
  BPagination,
  BButton,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
  BFormDatepicker,
  BImg,
  BImgLazy,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BFormFile,
  BTooltip,
  BDropdown,
  BDropdownItem
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    required,
    BFormGroup,
    BForm,
    BDropdownItem,
    BDropdown,
    VueGoodTable,
    BRow,
    BBadge,
    BFormInput,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BCol,
    BImg,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BTooltip,
    BFormFile,
    Attachment
  },
  data() {
    return {
      loadData: true,
      order_by: '',

      pagination: {
        enabled: true,
        mode: "records",
        perPage: 500,
        position: "bottom",
        perPageDropdown: [3, 7, 9],
        dropdownAllowAll: false,
        setCurrentPage: 2,
        jumpFirstOrLast: true,
        firstLabel: "First Page",
        lastLabel: "Last Page",
        nextLabel: "next",
        prevLabel: "prev",
        rowsPerPageLabel: "Rows per page",
        ofLabel: "of",
        pageLabel: "page", // for 'pages' mode
        allLabel: "All",
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`,
      },

      pageLength: 20,
      paginationStart: 0,
      paginationValue: 1,
      paginationTotal: 0,

      paginationEnd: 0,

      searchTerm: "",
      imagePopup: false,
      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "thumbnail",
          label: "Image",
          hidden: false,
        },
        {
          field: "name",
          label: "Name",
        },
        {
          field: "action",
          label: "Actions",
        },
      ],
      allImage: [],
      images: [],
      check: false
    };
  },
  computed: {
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  mounted() {
    this.getAllImage();
  },
  methods: {
    resetModal() {
      this.images=[]
    },
   async handleDownload(item) {
      // const imageUrl = item.original;
      // let url = imageUrl.includes("https")
      //   ? imageUrl
      //   : imageUrl.replace("http", "https");

      // try {
      //   const response = await fetch(url);
      //   if (response.ok) {
      //     const blob = await response.blob();
      //     const reader = new FileReader();
      //     reader.onload = () => {
      //       this.imageData = reader.result; // Base64 representation of the image
      //     };
      //     reader.readAsDataURL(blob);
      //   } else {
      //     console.error('Failed to fetch image');
      //   }
      // } catch (error) {
      //   console.error('Error fetching image:', error);
      // }
      // fetch('https://fastly.picsum.photos/id/883/200/300.jpg?hmac=L62LMsIBfvhnxlTirzshbyv6HarwJvd-tSSBcIvbCVw', {
      //   mode: 'no-cors'
      // })
      //   .then(response => { console.log(response); response.blob()})
      //   .catch(error => {
      //     console.error('Fetch error:', error);
      //   });
        // .then(blob => {
        //   let blobUrl = window.URL.createObjectURL(blob);
        // console.log(blob)
        let a = document.createElement('a');
        a.download = 'Image'
        a.href = item.original;
        document.body.appendChild(a);
        a.click();
        a.remove();
        // })

      // axios({
      //   url,
      //   method: "GET",
      //   responseType: "blob",
      // }).then((response) => {
      //   const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      //   const fURL = document.createElement("a");
      //   let ext1 = url.split(".").pop();
      //   fURL.href = fileURL;
      //   fURL.setAttribute("download",item.name);
      //   document.body.appendChild(fURL);
      //   fURL.click();
      // });
    },
    handleCopyLink(item) {
      this.$copyText(item.thumbnail).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Text copied',
            icon: 'CopyIcon'
          }
        })
      }, e => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'CopyIcon'
          }
        })
      })
    },
    handleOrderBy() {
      this.getAllImage()
    },
    async handleAddImage() {
      await axios({
        method: "POST",
        url: `${this.baseApi}/galleryimage`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: {images:this.images},
      })
        .then((json) => {
          this.imagePopup = false
          this.getAllImage()
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.images = []
        })
    },
    handleClickAdd() {
      this.imagePopup = true
    },
    handleDelete(row) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            const request = {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
              url: `${this.baseApi}/galleryimage/${row.id}`,
            };
            axios(request).then((json) => {
              this.getAllImage();
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "Your data has been deleted.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    searchApply: _.debounce(function () {
      this.getAllImage();
    }, 1000),
    changePagination() {
      this.getAllImage();
    },
    async handleFileChange(e) {
      if (e && e.length>0) {
      this.check = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      let name = []
      e.map((item) => {
        formData.append("attachment[]", item);
        name.push(item.name)
      })
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachments`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.images=[]
          response.data.map((item, index) => {
            this.images.push({
              thumbnail : item.thumbnail,
              file_name : name[index],
              id : item.id,
              original : item.original,
              webp_original : item.webp_original,
              webp_thumbnail   : item.webp_thumbnail,
              name:  name[index],
            })
          })
          this.check = false;
        })
        .catch((error) => {
          this.check = false;
        })
      }

    },
    async getAllImage() {
      await this.Setting();
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/galleryimage?&page=${this.paginationValue}&limit=20&search=${this.searchTerm ? "name:" + this.searchTerm : ""}&sortedBy=${this.order_by && this.order_by  == 'Oldest' ? 'asc':'desc'}&orderBy=id`,
      };
      await axios(request).then((json) => {
        this.loadData = false;
        this.allImage = json.data.data;
        this.paginationTotal = json.data.total;
        this.paginationStart = json.data.from;
        this.paginationEnd = json.data.to;
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";



.imgesGallery {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-weight: bolder
}

.imgBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.galleryGrid .dropdown-menu{
  left:-100px !important;
}
</style>

