<template>
  <b-media
    v-if="data && JSON.stringify(data) !== '[]'"
    class="d-flex flex-column"
    no-body
  >
    <b-media-aside class="imageCls">
      <b-link>
        <b-button
          variant="primary"
          v-if="JSON.stringify(data).includes('pdf')"
          href="#0"
          @click="pdf(data)"
          size="sm"
        >
          Show PDF
        </b-button>
        <b-img
          v-else
          rounded
          @click="pdf(data)"
          :src="data"
          style="width: 100%; height: 100%"
        />
      </b-link>
    </b-media-aside>
  </b-media>
</template>
<script>
import { BMedia, BMediaAside, BLink, BImg, BButton } from "bootstrap-vue";
// import PDFViewer from 'pdf-viewer-vue'

export default {
  props: ["data"],
  data(){
    return{
      imagePath: process.env.VUE_APP_IMAGE_PATH
    }
  },
  components: {
    BButton,
    // PDFViewer,
    BMedia,
    BMediaAside,
    BLink,
    BImg,
  },
  methods: {
    pdf(data) {
      window.open(`${data}`);
    },
  },
};
</script>
<style>
.imageCls{
  height: 150px;
  width: 150px;
}

</style>
