<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- old password -->

          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
              <b-col md="6">
            <b-form-group>
              <label>Old Password</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required|password"
                name="Old Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="oldPasswordValue"
                    placeholder="Enter Old Password"
                    :type="passwordFieldTypeOld"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon('passwordFieldTypeOld')"
                      class="cursor-pointer"
                      @click="togglePassword('passwordFieldTypeOld')"
                    />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>New Password</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required"
                name="New Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="newPasswordValue"
                    placeholder="Enter New Password"
                    :type="passwordFieldTypeNew"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon('passwordFieldTypeNew')"
                      class="cursor-pointer"
                      @click="togglePassword('passwordFieldTypeNew')"
                    />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group>
              <label>Retype Pasword</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                rules="required|password"
                name="Retype password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="RetypePassword"
                    placeholder="Enter New Password"
                    :type="passwordFieldTypeRetype"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon('passwordFieldTypeRetype')"
                      class="cursor-pointer"
                      @click="togglePassword('passwordFieldTypeRetype')"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="submitForm()"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
              @click="backForm"
            >
              Back
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import Ripple from "vue-ripple-directive";
import axios from '@/components/axios';

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      newPasswordValue: "",
      RetypePassword: "",
      oldPasswordValue:'',
      passwordFieldTypeNew: "password",
      passwordFieldTypeOld: "password",
      passwordFieldTypeRetype: "password",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      check:false
    };
  },
  computed: {
    passwordToggleIcon() {
      return (name)=> this[name] === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    togglePassword(name) {
      this[name] =
        this[name] === "password" ? "text" : "password";
    },
    backForm() {
      this.$router.push("/");
    },
    async submitForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (
          this.newPasswordValue != this.RetypePassword ||
          this.newPasswordValue == null ||
          success == false
        ) {
          if (success == false) {
            this.$swal({
              title: "Warning",
              timer: 5000,
              text: "Please Fill all fields correctly",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Warning",
              timer: 5000,
              text: "Retype Password Is Not Match With New Password",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        } else {
          const data = {
            oldpassword: this.oldPasswordValue,
            newpassword: this.RetypePassword
          };
          await axios({
            method: "POST",
            url: `${this.baseApi}/change-password`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update Successfully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/");
            })
            .catch((error) => {
              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>
