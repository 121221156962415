<style>
.timeline-item {
  border-left: 1px solid #cccbce !important;
}
</style>
<template>
  <section id="dashboard-analytics">
    <b-row>
      <b-col md="3" sm="6" cols="12">
        <StatisticCardHorizontalDashboard
          statistic="Total Revenue"
          statisticTitle=""
          :subtitle="totalRevenue"
          icon="rupee"
          color="success"
        />
      </b-col>
      <b-col md="3" cols="12" sm="6">
        <StatisticCardHorizontalDashboard
          statistic="Total Order"
          statisticTitle=""
          :subtitle="totalOrders"
          icon="ShoppingCartIcon"
          color="danger"
        />
      </b-col>
      <b-col md="3" cols="12" sm="6">
        <StatisticCardHorizontalDashboard
          statistic="Today's Revenue"
          statisticTitle=""
          :subtitle="totalRevenue"
          icon="rupee"
          color="warning"
        />
      </b-col>
      <b-col md="3" cols="12" sm="6">
        <StatisticCardHorizontalDashboard
          statistic="New Customers"
          statisticTitle=""
          :subtitle="newCustomers"
          icon="UsersIcon"
          color="primary"
        />
      </b-col>
    </b-row>
    <div>
      <b-card-code title="Recent Orders">
        <vue-good-table
          class="table mt-2"
          :columns="fields"
          :rows="allOrders"
          :rtl="direction"
          :isLoading="loadData"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: false,
            selectAllByGroup: false,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <div
              v-if="
                props.column.field === 'tracking_number' && !props.row.children
              "
              class="pl-2"
            >
              {{ props.row.tracking_number }}
            </div>
            <div v-else-if="props.column.field === 'order_status'">
              <b-badge :variant="setColor(props.row.order_status)">
                {{ props.row.order_status }}
              </b-badge>
            </div>
             <div v-else-if="props.column.field === 'payment_status'">
                <b-badge :variant="setColor(props.row.payment_status)">
                  {{ props.row.payment_status }}
                </b-badge>
              </div>

            <div v-else-if="props.column.field === 'total'">
              {{
                props.row.total
                  ? "₹" + props.row.total.toLocaleString("en-IN")
                  : "₹" + 0.0
              }}
            </div>
            <div v-else>
              {{ props.row[props.column.field] }}
            </div>
          </template>
        </vue-good-table>
      </b-card-code>

      <div>
        <b-card-code title="Popular Products">

        <vue-good-table
          class="table"
          :columns="productfields"
          :rows="allProducts"
          :rtl="direction"
          :isLoading="loadDataProduct"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: false,
            selectAllByGroup: false,
          }"
        >
          <template slot="table-row" slot-scope="props">

            <div v-if="props.column.field === 'group' && props.row.type">
              {{ props.row.type.name }}
            </div>
            <div v-if="props.column.field === 'shop' && props.row.shop">
              {{ props.row.shop.name }}
            </div>
            <div v-else>
              {{ props.row[props.column.field] }}
            </div>
          </template>
        </vue-good-table>
        </b-card-code>

      </div>
    </div>
  </section>
</template>

<script>
import axios from "@/components/axios";
import StatisticCardHorizontalDashboard from "@core/components/statistics-cards/StatisticCardHorizontalDashboard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import moment from "moment";
import { VueGoodTable } from "vue-good-table";

import {
  BFormInput,
  BInputGroupAppend,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormSelect,
  BPagination,
  BButton,
  BFormCheckbox,
  BBadge,
  BImg,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BCard,
} from "bootstrap-vue";
export default {
  components: {
    VueGoodTable,
    BFormGroup,
    BInputGroupAppend,
    BCard,
    BForm,
    BRow,
    BBadge,
    BFormInput,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BCol,
    BImg,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BRow,
    BButton,
    StatisticCardHorizontalDashboard,
  },
  data() {
    return {
      branchOptions: [
        { name: "Channel Partner", id: "channelpartner" },
        { name: "Franchise", id: "franchise" },
        { name: "Admin", id: "admin" },
      ],
      leadsource: "",
      loadData: true,
      loadDataProduct:true,
      employee: "",
      pageLength: 10,
      employeeoption: [],
      subemployee: "",
      subemployeeoption: [],
      searchTerm: "",
      leadsourceOptions: [],
      startdate: "",
      enddate: "",
      result: "",
      data: "",

      visitFlag: false,
      agreementShow: false,
      earningsChart: {
        series: [53, 16, 31],
      },
      congratulations: {
        name: "",
        saleToday: "0",
      },
      fields: [
        {
          field: "tracking_number",
          label: "Tracking Number",
        },
        {
          field: "total",
          label: "Total",
        },
        {
          field: "order_date",
          label: "Order Date",
        },
        {
          field: "order_status",
          label: "Order Status",
        },
         {
          field: "payment_status",
          label: "Payment Status",
        },
      ],
      productfields: [
        {
          field: "name",
          label: "Name",
        },
        {
          field: "group",
          label: "Group",
        },
        {
          field: "price",
          label: "Price",
        },
        {
          field: "quantity",
          label: "Quantity",
        },
      ],
      allOrders: [],
      show: {},
      params: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      paginationValue: 1,
      totalRevenue: 0,
      totalOrders: 0,
      todaysRevenue: 0,
      newCustomers: 0,
      allProducts:[]
    };
  },
  computed: {
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    setColor() {
      const statusColor = {
        "Order Processing": "warning",
        "Order Pending": "secondary",
        "Order At Local Facility": "info",
        "Order Out For Delivery": "primary",
        "Order Completed": "success",
        "Order Cancelled": "danger",
        'Payment Success': "success",
        'Payment Processing': "warning",
      };
      return (status) => statusColor[status];
    },
  },
  mounted() {
    if(localStorage.getItem("accessToken")){
      this.getOrders();
      this.getAnalytics();
      this.getProducts();
    }
    else{
      this.$router.push('/login')
    }

  },
  methods: {
    async getProducts() {
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/popular-products?searchJoin=and&with=type%3Bshop&limit=10&language=en`,
      };
      await axios(request).then((json) => {
        this.loadData = false;
        this.allProducts = json.data;
        this.allProducts.map((item) => {
          item.price = '₹'+item.price.toLocaleString('en-IN')
        });
      })
        .finally(() => {
        this.loadDataProduct=false
      })
    },
    async getAnalytics() {
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/analytics`,
      };
      await axios(request).then((json) => {
        let data = json.data;
        this.totalRevenue = data.totalRevenue;
        this.todaysRevenue = data.todaysRevenue;
        this.totalOrders = data.totalOrders;
        this.newCustomers = data.newCustomers;
      });
    },
    async getOrders() {
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/orders?page=${this.paginationValue}&limit=${this.pageLength}`,
      };
      await axios(request).then((json) => {
        this.loadData = false;
        this.allOrders = json.data.data;
        this.allOrders.map((item) => {
          item.order_date = moment(item.created_at).format('DD/MM/YYYY h:m A')
          let status = item.order_status.split("-");
          let status2 = status.map((item) => {
            this.show[item.id] = false;
            return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
          });
          item.order_status = status2.join(" ");

         let paymentStatus = item.payment_status.split("-");

          let paymentStatus2 = paymentStatus.map((item) => {
            this.show[item.id] = false;
            return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
          });
          item.payment_status = paymentStatus2.join(" ");

        });
      });
    },
    handleShow(item) {
      let idx = this.allOrders.findIndex((order) => order.id === item.id);
      this.show[item.id] = this.show[item.id] ? false : true;
      if (this.show[item.id]) {
        item.children.map((child, index) => {
          this.allOrders.splice(idx + index + 1, "", child);
        });
      } else {
        this.allOrders.splice(idx + 1, item.children.length);
      }
      this.$forceUpdate();
    },
  },

  //All Products
  async getOrders() {
    const request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.accessToken}`,
      },
      url: `${this.baseApi}/popular-products?page=${this.paginationValue}&limit=${this.pageLength}`,
    };
    await axios(request).then((json) => {
      this.loadData = false;
      this.allOrders = json.data.data;
      this.allOrders.map((item) => {
        item.order_date = moment(item.created_at).fromNow();
        let status = item.order_status.split("-");
        let status2 = status.map((item) => {
          this.show[item.id] = false;

          return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
        });
        item.order_status = status2.join(" ");

        let shipping_address = "";
        if (item.shipping_address) {

        shipping_address += item.shipping_address.street_address;
        shipping_address += item.shipping_address.city
          ? ", " + item.shipping_address.city
          : "";

        shipping_address += item.shipping_address.state
          ? ", " + item.shipping_address.state
          : "";
        shipping_address += item.shipping_address.zip
          ? ", " + item.shipping_address.zip
          : "";
        shipping_address += item.shipping_address.country
          ? ", " + item.shipping_address.country
          : "";
          item.shipping_address = shipping_address;
        }
      });
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
<style>
.order .dropdown-menu.show {
  left: -57px !important;
}
.rc-table-row-expand-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.rc-table-row-expand-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  color: #aaa;
  line-height: 16px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid;
  cursor: pointer;
}
.rc-table-row-expand-icon.rc-table-row-collapsed:after {
  content: "+";
}
.rc-table-row-expand-icon.rc-table-row-expanded:after {
  content: "-";
}
</style>
