var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-row',{staticClass:"justify-content-end order"},[_c('b-col',{staticClass:"mt-1",attrs:{"md":"6"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"search","type":"text"},on:{"input":_vm.searchApply},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1),_c('div',{staticClass:"col-6 mt-1"},[_c('b-button',{staticClass:"btn btn-primary float-right mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push('/add-shipping')}}},[_vm._v(" Add Shipping ")])],1)],1)],1),_c('vue-good-table',{staticClass:"table",attrs:{"columns":_vm.fields,"rows":_vm.allData,"rtl":_vm.direction,"isLoading":_vm.loadData,"select-options":{
      enabled: false,
      selectOnCheckboxOnly: true,
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: false,
      selectAllByGroup: false,

    },"pagination-options":_vm.pagination},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',{staticClass:"position-relative",staticStyle:{"width":"fit-content","display":"flex"}},[_c('b-badge',{staticClass:"mr-50 cursor-pointer",attrs:{"variant":"warning","id":'edit' + props.row.id},on:{"click":function($event){return _vm.handleEdit(props.row)}}},[_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1),_c('b-badge',{staticClass:"cursor-pointer",attrs:{"variant":"danger","id":'delete' + props.row.id},on:{"click":function($event){return _vm.handleDelete(props.row)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1),_c('b-tooltip',{attrs:{"placement":"top","target":'edit' + props.row.id,"triggers":"hover"}},[_vm._v(" Edit ")]),_c('b-tooltip',{attrs:{"placement":"top","target":'delete' + props.row.id,"triggers":"hover"}},[_vm._v(" Delete ")])],1):_c('div',[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"}),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"total-rows":_vm.paginationTotal,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function($event){return _vm.changePagination('change')}},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true),model:{value:(_vm.paginationValue),callback:function ($$v) {_vm.paginationValue=$$v},expression:"paginationValue"}})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }