<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Image</label>
              <!-- <b-form-file
                v-model="image.thumbnail"
                placeholder="Select Image"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFileChange($event, 'image')"
              /> -->
              <div  @click="openmodal('image')" class="fileuploader cursor-pointer d-flex align-items-center" style="border:1px solid silver;padding:10px;border-radius:5px;display:flex;justify-content:space-between">
                  <div><feather-icon icon="ImageIcon" size="20" /></div>
                  <div>Browse</div>
                </div>
            </b-form-group>
            <attachment v-if="image && image.thumbnail" :data="image.thumbnail" />
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Name"
              >
                <b-form-input v-model="name"
                placeholder="Enter Name"

                 />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
           <b-col md="6">
              <b-form-group>
                <label>Meta Title</label>
                <b-form-input v-model="meta_title"  placeholder="Meta Title"/>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <label>Meta Description</label>
                  <b-form-input v-model="meta_description"  placeholder="Meta Description"/>
                </b-form-group>
              </b-col>

          <b-col md="12">
            <b-form-group>
              <label>Details</label>
              <quill-editor v-model="details" />
            </b-form-group>
          </b-col>
          <!-- submit button -->
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              class="mr-4"
              :disabled="check"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
     <gallary-image-model v-if="modelValue" :modelValue="modelValue" :openmodal="openmodal" @setImage="setImage"/>

  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import { quillEditor } from "vue-quill-editor";
import gallaryImageModel from "../../components/gallaryImageModel.vue";


import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../components/Attechment.vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
export default {
  components: {
    flatPickr,
    BFormCheckboxGroup,
    gallaryImageModel,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BFormCheckbox,
    quillEditor
  },
  
  data() {
    return {
      required,
      subCategoriesOptions: [],
      image: {
        original: "",
        thumbnail: "",
        file_name: "",
        id: "",
      },
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      name: "",
      check: false,
      details: "",
      selectedField: '',
      selectedIndex: false,
      selected: '',
      modelValue: '',
      meta_title:'',
      meta_description:'',
    };
  },
  async mounted() {
    this.ifEdit = !!this.$route.params.slug;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    openmodal(name, index, field) {
      this.modelValue = !this.modelValue
      this.selected = name
      this.selectedField = field ? field : false
      this.selectedIndex = index
      this.$forceUpdate()
    },
    setImage(image) {
      if (this.selectedIndex && this.selectedField) {
        console.log(this[this.selected]);
        this[this.selected][this.selectedIndex - 1][this.selectedField] = image
      }
      else if (this.selectedIndex) {
        this[this.selected][this.selectedIndex - 1] = image
      }
      else {
        this[this.selected] = image
      }
    },
       convertToSlug(Text) {
      return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    async getEditValue() {
      // console.log(this.$route.params.id, "id====");
      await axios({
        method: "GET",
        url: `${this.baseApi}/tags/${this.$route.params.slug}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.id = item.id;
      this.details = item.details;
      this.meta_title = item.meta_title
      this.meta_description = item.meta_description
      this.image = item.image
        ? item.image
        : {
          original: "",
          thumbnail: "",
          file_name: "",
          id: "",
        };
      this.name = item.name;
         setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },

    submitForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          data = {
            details: this.details,
            image: this.image,
            name: this.name,
            meta_title:this.meta_title,
            meta_description:this.meta_description,
            type_id: 8,
          };
          if (this.ifEdit) {
            data.slug= this.convertToSlug(this.name)
          }
        }

        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.check = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/tags/${this.id}`
              : `${baseApi}/tags`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.check = false;
              this.$swal({
                title: "Submited",
                text: "Successfully updated!",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/latestupdate");
            })
            .catch((error) => {
              this.check = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    async handleFileChange(e, name, id, idName) {
      this.check = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("attachment[]", e);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachments`,
      };
      await axios(requestOptions)
        .then((response) => {
            this[name].thumbnail = response.data[0].thumbnail;
            this[name].file_name = e.name;
            this[name].id = response.data[0].id;
            this[name].original = response.data[0].original;
          this.check = false;
        })
        .catch((error) => {
          this.check = false;
        });
    },
    onClickBack() {
      this.$router.push("/latestupdate");
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>

