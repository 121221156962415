var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('div',{staticClass:"row align-items-end"},[_c('div',{staticClass:"col-md-6 mt-1"},[_c('label',[_vm._v(" Search")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Search","type":"text"},on:{"input":_vm.searchApply},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('div',{staticClass:"col-md-6 mt-1"},[_c('b-button',{staticClass:"btn btn-primary float-right",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push('/add-taxes')}}},[_vm._v(" Add Taxes ")])],1)])]),_c('b-card',[_c('b-card',[_c('vue-good-table',{staticClass:"table",attrs:{"columns":_vm.fields,"rows":_vm.allUser,"rtl":_vm.direction,"isLoading":_vm.loadData,"select-options":{
          enabled: false,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false,
          selectAllByGroup: false,
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',{staticClass:"position-relative",staticStyle:{"width":"fit-content","display":"flex"}},[_c('b-badge',{staticClass:"mr-50 cursor-pointer",attrs:{"variant":"warning","id":'edit' + props.row.id},on:{"click":function($event){return _vm.handleEdit(props.row)}}},[_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1),_c('b-badge',{staticClass:"cursor-pointer",attrs:{"variant":"danger","id":'delete' + props.row.id},on:{"click":function($event){return _vm.handleDelete(props.row)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1),_c('b-tooltip',{attrs:{"placement":"top","target":'edit' + props.row.id,"triggers":"hover"}},[_vm._v(" Edit ")]),_c('b-tooltip',{attrs:{"placement":"top","target":'delete' + props.row.id,"triggers":"hover"}},[_vm._v(" Delete ")])],1):_c('div',[_vm._v(" "+_vm._s(props.row[props.column.field])+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }