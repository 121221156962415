<template>
  <div>
    <b-card>

    <div class="row justify-content-between align-items-end">
      <!-- <div class="col-md-1">
          </div> -->
      <div class="col-md-6 mt-1">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="search"
            v-model="searchTerm"
            class="form-control-merge"
            @input="searchApply"
            type="text"
          />
          <b-input-group-append is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="col-md-6 mt-1" >
        <b-button
          variant="primary"
          @click="addUserPopup = !addUserPopup"
          class="btn btn-primary float-right mr-1"
        >
          Add Customer
        </b-button>
      </div>
    </div>
    </b-card>
    <b-card>

    <vue-good-table
      class="table"
      :columns="fields"
      :rows="allUser"
      :rtl="direction"
      :isLoading="loadData"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: false,
        selectAllByGroup: false,
      }"
      :pagination-options="pagination"
    >
      <template slot="table-row" slot-scope="props">
        <span
          v-if="
            props.column.field === 'avatar' &&
            props.row.profile &&
            props.row.profile.avatar &&
            props.row.profile.avatar.thumbnail
          "
        >
          <!-- {{ props.row.image.thumbnail }} -->

          <b-img
            :src="props.row.profile.avatar.thumbnail"
            fluid
            style="width: 100px; height: 100px"
          />
        </span>
        <div
          v-if="props.column.field === 'available_points' && props.row.wallet"
        >
          {{ props.row.wallet.available_points }}
        </div>
        <span
          v-else-if="props.column.field === 'action'"
          style="width: fit-content; display: flex"
          class='position-relative'
        >
          <b-badge
            variant="primary"
            :id="'wallet' + props.row.id"
            class="cursor-pointer mr-50"
            @click="handleAddWallet(props.row)"
          >
            <feather-icon icon="PlusIcon" />
          </b-badge>
          <b-tooltip
          placement="top"  :target="'wallet' + props.row.id" triggers="hover">
            Add Wallet
          </b-tooltip>

          <b-badge
            variant="warning"
            :id="'permission' + props.row.id"
            class="mr-50 cursor-pointer"
            @click="handlePermission(props.row)"
          >
            <feather-icon icon="UserIcon" />
          </b-badge>
           <b-tooltip
           placement="top"
              :target="'permission' + props.row.id"
              triggers="hover"
            >
              Permission
            </b-tooltip>
          <b-badge
            variant="danger"
            :id="'ban' + props.row.id"
            class="cursor-pointer"
            @click="handleBan(props.row)"
            v-if="props.row.is_active"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 459.739 459.739"
              fill="currentColor"
              width="20"
            >
              <path
                d="M229.869 0C102.919 0 0 102.918 0 229.87s102.919 229.869 229.869 229.869c126.952 0 229.87-102.917 229.87-229.869S356.821 0 229.869 0zM61.299 229.87c0-37.1 12.196-71.325 32.58-99.198L329.062 365.86c-27.868 20.392-62.093 32.581-99.192 32.581-92.951 0-168.571-75.621-168.571-168.571zm307.839 94.813L135.048 90.601c27.044-18.468 59.684-29.303 94.821-29.303 92.952 0 168.571 75.622 168.571 168.572 0 35.139-10.833 67.779-29.302 94.813z"
              ></path>
            </svg>
          </b-badge>
          <b-tooltip
          placement="top"
            v-if="props.row.is_active"
            :target="'ban' + props.row.id"
            triggers="hover"
          >
            Ban
          </b-tooltip>
          <b-badge
            variant="success"
            :id="'active' + props.row.id"
            class="cursor-pointer"
            @click="handleActive(props.row)"
            v-else
          >
            <feather-icon icon="CheckIcon" />
          </b-badge>
          <b-tooltip
          placement="top"
            v-if="!props.row.is_active"
            :target="'active' + props.row.id"
            triggers="hover"
          >
            Active
          </b-tooltip>
        </span>
        <div v-else>
          {{ props.row[props.column.field] }}
        </div>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <!-- <b-form-select v-model="pageLength" :options="['5', '10', '20', '50', '100', '500']" class="mx-1"
                @input="changePagination()" />
              <span class="text-nowrap"> Showing {{ paginationStart }} to {{ paginationEnd }} of {{ paginationTotal
              }}</span> -->
          </div>
          <div>
            <b-pagination
            v-if='showPagination'
              v-model="paginationValue"
              :total-rows="paginationTotal"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="changePagination('change')"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    </b-card>

    <b-modal
      id="modal-form"
      v-model="walletPopup"
      size="sm"
      cancel-variant="outline-secondary"
      ok-title="Submit"
      cancel-title="Close"
      title="Add Wallet Points"
      no-close-on-backdrop
      hide-footer
    >
      <b-form-input v-model="walletPoint" type="number"></b-form-input>
      <div class="text-right">
        <b-button variant="primary" class="mt-1" @click="submitWallat"
          >Submit</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-form"
      v-model="addUserPopup"
      size="sm"
      cancel-variant="outline-secondary"
      ok-title="Submit"
      cancel-title="Close"
      title="Add New Customer"
      no-close-on-backdrop
      hide-footer
    >
    <validation-observer ref="simpleRules">
      <b-row>

        <b-col md="12">

          <b-form-group>
            <label>Name</label>
              <label style="color: red !important">*</label>

            <validation-provider
                #default="{ errors }"
                rules="required"
                name="Name"
              >
            <b-form-input
              id="search"
              v-model="name"
              class="form-control-merge"
              type="text"
              placeholder="Enter Name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group>
            <label>Email</label>
              <label style="color: red !important">*</label>

            <validation-provider
                #default="{ errors }"
                rules="required"
                name="Email"
              >
            <b-form-input
              id="search"
              v-model="email"
              class="form-control-merge"
              type="text"
              placeholder="Enter Emai"
            />
             <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Password"
              vid="password"
              rules="required"
            >
              <label> Password</label>
              <label style="color: red !important">*</label>

              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="login-password"
                  v-model="password"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-password"
                  placeholder="Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="text-right w-100">
            <b-button variant="primary" class="mt-1" @click="submitCustomer"
              >Submit</b-button
            >
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import axios from "@/components/axios";
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import moment from "moment";
import { VueGoodTable } from "vue-good-table";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BInputGroup,
  BRow,
  BCol,
  BFormSelect,
  BPagination,
  BButton,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
  BFormDatepicker,
  BImg,
  BImgLazy,
  BInputGroupAppend,
  BTooltip,
  BCard
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  required,
} from "@validations";
export default {
  mixins: [togglePasswordVisibility],
  components: {
    BFormGroup,
    BForm,
    BTooltip,
    VueGoodTable,
    BRow,
    BBadge,
    BFormInput,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BInputGroup,
    BCol,
    BImg,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    BInputGroupAppend,
  BCard

  },
  data() {
    return {
      required,
      branchOptions: [
        { name: "Channel Partner", id: "channelpartner" },
        { name: "Franchise", id: "franchise" },
        { name: "Admin", id: "admin" },
      ],
      leadsource: "",
      loadData: true,
      pagination: {
        enabled: true,
        mode: "records",
        perPage: 500,
        position: "bottom",
        perPageDropdown: [3, 7, 9],
        dropdownAllowAll: false,
        setCurrentPage: 2,
        jumpFirstOrLast: true,
        firstLabel: "First Page",
        lastLabel: "Last Page",
        nextLabel: "next",
        prevLabel: "prev",
        rowsPerPageLabel: "Rows per page",
        ofLabel: "of",
        pageLabel: "page", // for 'pages' mode
        allLabel: "All",
        infoFn: (params) => `my own page ${params.firstRecordOnPage}`,
      },
      employee: "",
      pageLength: 20,
      employeeoption: [],
      paginationStart: 0,
      category: "",
      paginationValue: 1,
      paginationTotal: 0,
      categoryData: [],
      paginationEnd: 0,
      subemployee: "",
      subemployeeoption: [],
      searchTerm: "",
      leadsourceOptions: [],
      startdate: "",
      enddate: "",
      result: "",
      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "avatar",
          label: "Avatar",
          hidden: false,
        },
        {
          field: "name",
          label: "Name",
        },
        {
          field: "email",
          label: "Email",
        },
        {
          field: "permissions",
          label: "Permissions",
        },
        {
          field: "available_points",
          label: "Available wallet points",
        },
        {
          field: "status",
          label: "Status",
        },
        {
          field: "action",
          label: "Actions",
        },
      ],
      allUser: [],
      userId: 0,
      walletPopup: false,
      walletPoint: 0,
      addUserPopup: false,
      email: "",
      name: "",
      password: "",
      showPagination :false

    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  mounted() {
    this.paginationValue = this.$route.query.page ? this.$route.query.page : 1
    this.searchTerm = this.$route.query.search ? this.$route.query.search : ''
    this.getAllUsers();
  },
  methods: {
    submitCustomer() {
      this.$refs.simpleRules.validate().then(async (success) => {
         if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          return
        }

        let data = {
          name: this.name,
          email: this.email,
          password: this.password,
          permission: "store_owner"
        };
        const request = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/register`,
          data: data,
        };
        axios(request).then((json) => {
          this.name =''
          this.email=''
          this.password=''
          this.addUserPopup = false;
          this.getAllUsers();
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Successfully added!",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        })
          .catch((res) => {
            this.$swal({
              icon: "success",
              title: "Success",
              text: "Successfully added!",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          })
      })
    },
    submitWallat() {
      let data = {
        customer_id: this.userId,
        points: parseInt(this.walletPoint),
      };
      const request = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/add-points`,
        data: data,
      };
      axios(request).then((json) => {
        this.walletPopup = false;
        this.getAllUsers();
        this.$swal({
          icon: "success",
          title: "Success",
          text: "Successfully added!",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      });
    },
    handleAddWallet(row) {
      this.userId = row.id;
      this.walletPopup = true;
    },
    handleEdit(row) {
      this.$router.push(`/editusers/${row.slug}`);
    },
    handleBan(row) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to block user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            let data = {
              id: row.id,
            };
            const request = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
              url: `${this.baseApi}/users/block-user`,
              data: data,
            };
            axios(request).then((json) => {
              this.getAllUsers();
              this.$swal({
                icon: "success",
                title: "Success",
                text: "User successfully baned",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    handleActive(row) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to active user",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            let data = {
              id: row.id,
            };
            const request = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
              url: `${this.baseApi}/users/unblock-user`,
              data: data,
            };
            axios(request).then((json) => {
              this.getAllUsers();
              this.$swal({
                icon: "success",
                title: "Success",
                text: "User successfully active",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    handlePermission(row) {
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure you want to assign/revoke admin permission?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            let data = {
              user_id: row.id,
            };
            const request = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
              url: `${this.baseApi}/users/make-admin`,
              data: data,
            };
            axios(request).then((json) => {
              this.getAllUsers();
              this.$swal({
                icon: "success",
                title: "Success",
                text: "Your data has saved.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    handleDelete(row) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            const request = {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
              url: `${this.baseApi}/users/${row.id}`,
            };
            axios(request).then((json) => {
              this.getAllUsers();
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "Your data has been deleted.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async ToggleChanged(id, e) {
      let tmpData = this.allUser.filter((i) => {
        i.id == id;
      });
      await this.Setting();
      const request = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: {
          is_approved: e,
          name: tmpData.name,
          language: tmpData.language,
        },
        url: `${this.baseApi}/products/${id}`,
      };
      await axios(request).then((json) => {
        this.getAllUsers();
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Successfully Updated`,
            icon: "CheckIcon",
            variant: "success",
            text: `You have successfully Changed Status of ${json.data.name}.`,
          },
        });
      });
    },
    searchApply: _.debounce(function () {
       const url = new URL(window.location.href);
      if (this.searchTerm) {
        url.searchParams.set('search', this.searchTerm);
      }
      else {
        url.searchParams.delete('search')
      }
      window.history.replaceState({}, '', url);
      this.getAllUsers();
    }, 1000),
    changePagination() {
      const url = new URL(window.location.href);
      url.searchParams.set('page', this.paginationValue);
      window.history.replaceState({}, '', url);
      this.getAllUsers();
    },
    async getAllUsers() {
      this.loadData = true;

      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/users?page=${this.paginationValue}&limit=${
          this.pageLength
        }&with=wallet&searchJoin=and&search=${
          this.searchTerm
            ? "name:" + this.searchTerm + (this.category ? "%3B" : "")
            : ""
        }`,
      };
      await axios(request).then((json) => {
        this.loadData = false;
        this.allUser = json.data.data;

        this.allUser.map((item) => {
          item.status = item.is_active ? "Active" : "In Active";
          let permissions = item.permissions
            ? item.permissions.map((per) => {
                return per.name;
              })
            : [];
          item.permissions = permissions.join(",");
        });
        this.paginationTotal = json.data.total;
        this.paginationStart = json.data.from;
        this.paginationEnd = json.data.to;
        this.showPagination = true

      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

