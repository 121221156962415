<style>
@media (max-width: 460px) {
  .resButtons {
    width: 100%;
  }
}
</style>


<template>
  <div v-if="renderComponent">
    <div class="custom-search d-flex justify-content-between flex-wrap">
      <b-form-group class="resButtons">
        <!-- <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div> -->
      </b-form-group>
      <b-form-group class="resButtons">
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            v-b-tooltip.hover.v-success
            title="Print"
            variant="gradient-success"
            class="ml-2 btn-icon"
            @click="printData"
          >
            <feather-icon icon="PrinterIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.v-success
            title="Pdf"
            variant="gradient-success"
            class="ml-2 btn-icon"
            @click="pdfData"
          >
            <feather-icon icon="DownloadIcon" />
          </b-button>
          <b-button
            v-b-tooltip.hover.v-success
            title="Excel"
            variant="gradient-success"
            class="ml-2 btn-icon"
            @click="clickExcel"
          >
            <!-- @click="excelData" -->
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </div>
      </b-form-group>
    </div>
    <div style="display: none">
      <table id="printTable" border="1" cellpadding="1">
        <thead>
          <tr>
            <th v-for="(item, index) in columns" :key="index">
              {{ item.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" :key="index">
            <td v-for="data in columns" :key="data.id">
              {{ item[data.field] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="data"
      :rtl="direction"
      :line-numbers="true"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: false,
        perPage: pageLength,
      }"
      collapsable:2
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <div v-if="props.column.field === 'image'" class="text-nowrap">
          <b-image :src="props.row.attachment" class="mx-2" size="45px" />
          <!-- <span class="text-nowrap">{{ props.row.fullName }}</span> -->
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.rating == true">
          <b-badge
            v-if="props.row[props.column.field] == 'Poor'"
            variant="dark"
          >
            {{ props.row[props.column.field] }}
          </b-badge>
          <b-badge
            v-if="props.row[props.column.field] == 'Fair'"
            variant="warning"
          >
            {{ props.row[props.column.field] }}
          </b-badge>
          <b-badge
            v-if="props.row[props.column.field] == 'Satisfactory'"
            variant="info"
          >
            {{ props.row[props.column.field] }}
          </b-badge>
          <b-badge
            v-if="props.row[props.column.field] == 'Good'"
            variant="primary"
          >
            {{ props.row[props.column.field] }}
          </b-badge>
          <b-badge
            v-if="props.row[props.column.field] == 'Excellent'"
            variant="success"
          >
            {{ props.row[props.column.field] }}
          </b-badge>
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['5', '10', '20', '50', '100']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import axios from '@/components/axios';
import exportFromJSON from "export-from-json";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BButton,
  BDropdownItem,
  VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
// import { codeColumnSearch } from "./code";

export default {
  props: ["title", "columns", "data"],
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  data(props) {
    return {
      props,
      visible: false,
      pageLength: 10,

      renderComponent: false,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"), // JSON.parse()
      searchTerm: "",
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  mounted() {
    this.forceRerender();
  },
  methods: {
    clickExcel() {
      this.data1 = [];
      this.data.map((data) => {
        const data1 = {};
        this.columns.map((col) => {
          if (data.hasOwnProperty(col.field)) {
            data1[col.label] = data[col.field];
          }
        });
        this.data1.push(data1);
      });
      const fileName = "exported-data";
      const data = this.data1;
      const fileExportType = null;
      const exportType = exportFromJSON.types[fileExportType || "xls"];
      exportFromJSON({ data, fileName, exportType });
    },
    printData() {
      const divToPrint = document.getElementById("printTable");
      const newWin = window.open();
      newWin.document.write(divToPrint.outerHTML);
      newWin.print();
      newWin.close();
    },
    setSr() {
      if (this.data.length !== 0) {
        this.data.map((item, index) => {
          item.id = index + 1;
        });
      }
      return true;
    },

    pdfData() {
      const data = document.getElementById("printTable");
      html2canvas(data).then((canvas) => {
        const fileWidth = 208;
        const fileHeight = (canvas.height * fileWidth) / canvas.width;
        const fileuri = canvas.toDataURL("image/png");
        const PDF = new jsPDF("p", "mm", "a4");
        const position = 0;
        PDF.addTable(fileuri, "PNG", 0, position, fileWidth, fileHeight);
        PDF.addFont(data);
        PDF.save("angular-demo.pdf");
      });
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
  },
};
</script>
