<template>
  <div class="galleryModal">
    <b-modal v-model="modelValueProps" no-close-on-backdrop @hidden="openmodal" hide-footer
      centered size="lg" title="Gallery">
      <b-tabs align="left" content-class="pt-1" pills>
        <b-tab active title="Select File">
          <b-row>
            <b-col md="9">
            </b-col>
            <b-col md="6" class="mt-1">
               <b-form-input v-model="searchTerm" placeholder="Search" @input="searchApply" type="text"
              class="d-inline-block" />
              </b-col>
              <b-col md="6" class="mt-1">
              <v-select placeholder="Select One" v-model="type" :options="['Oldest', 'Newest']" @input='getGallery()'></v-select>
            </b-col>
            <b-col md="12">
              <b-row class="mt-1">
                <b-col md="3" cols="6" v-for="(file,index) in gallery" :key="index" @click="handleClickImage(file)" class="cursor-pointer" >
                  <div class="h-100 imgBox p-1" :class="showImage[file.id] ? 'active-image' : 'inactive-img'">
                    <div class="d-flex flex-column justify-content-between" style="padding:5px;min-height:110px;"  >
                      <img :src="file.thumbnail" alt="Preview" class="imageCustom" style="border-radius:10px"/>
                    </div>
                    <div class="w-100 d-flex justify-content-between align-items-center mt-2">
                      <div class="imgesGallery"> {{ file.name }}</div>
                      <b-badge variant="danger" class="cursor-pointer " style="width:30px" @click="remove(index,file)">
                        <feather-icon icon="TrashIcon" />
                      </b-badge>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-pagination v-model="paginationValue" :total-rows="paginationTotal" :per-page="pageLength" first-number
                last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="getGallery()">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            <div class="w-100 mt-4 d-flex justify-content-end">
              <b-button @click='handleSubmit' variant='primary'>
                Select File
              </b-button>
            </div>
        </b-tab>
        <b-tab title="Upload New">
          <div class="flex w-full h-screen items-center justify-center text-center" id="app">
            <div class="" @dragover="dragover" @dragleave="dragleave" @drop="drop">
              <div>
                <b-form-file type="file" v-model="uploaded" name="fields[assetsFieldHandle][]"
                  id="assetsFieldHandle" class="w-px h-px opacity-0 overflow-hidden absolute" @input="onChange($event)" ref="file"
                  accept=".pdf,.jpg,.jpeg,.png"  multiple/>
              </div>
            </div>
            <div class="mt-4" v-if="this.filelist.length" v-cloak style="overflow:auto;overflow-x:hidden">
              <b-row style="height:400px">
                <b-col lg="3" md="4" cols="6" class="text-sm p-1 mt-1" v-for="(file, index) in filelist" :key="index">
                  <div class="d-flex flex-column justify-content-between">
                    <img :src="file.thumbnail" alt="Preview" class="imageCustom" style="border-radius:10px"/>
                  </div>
                  <div class="w-100 d-flex justify-content-between align-items-center mt-3">
                    <div class="imgesGallery"> {{ file.name }}</div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>


        </b-tab>
      </b-tabs>

      <div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, BFormInput, VBModal, BRow, BCol, BTabs, BTab, BBadge, BFormFile, BPagination } from 'bootstrap-vue'
import { Upload } from 'element-ui';
import vSelect from 'vue-select';
import axios from 'axios'
export default {
  props: ['modelValue', 'openmodal' ,'selectedIndex'],
  emit: ['setImage'],
  components: {
    BButton,
    vSelect,
    BModal, BRow, BCol, BTabs, BTab,
    BFormFile,
    BBadge,
    BPagination,
    BFormInput
  },
  data() {
    return {
      filelistShow: {},
      imagePreview: null,
      filelist: [],
      uploaded: [],
      gallery: [],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      showImage: {},
      selectedFile: null,
      newest: [],
      oldest: [],
      pageLength: 20,
      paginationStart: 0,
      paginationValue: 1,
      paginationTotal: 0,
      paginationEnd: 0,
      searchTerm: '',
      type: 'Newest',
      multipleFile:[],
      modelValueProps:false
    }
  },
  mounted() {
    this.getGallery()
    this.modelValueProps = this.modelValue
  },
  methods: {
      searchApply: _.debounce(function () {
      this.getGallery();
    }, 1000),
    handleClickImage(file) {
      if (!this.selectedIndex) {
        this.showImage = {}
      }
      this.showImage[file.id] = this.showImage[file.id] ? false : true
      if (this.showImage[file.id]) {
        if (this.selectedIndex) {
          this.multipleFile.push(
             {
              webp_original: file.webp_original,
              webp_thumbnail: file.webp_thumbnail,
              original: file.original,
              thumbnail: file.thumbnail,
            }
          )
        }
        else {
          this.selectedFile =
          {
            webp_original:file.webp_original,
            webp_thumbnail:file.webp_thumbnail,
            original:file.original,
            thumbnail: file.thumbnail,
          }
        }
      }
      else {
        let index = this.multipleFile.indexOf(file)
        this.multipleFile.splice(index,1)
      }
      this.$forceUpdate()
    },
    async getGallery() {
       await axios({
        method: "GET",
        url: `${this.baseApi}/galleryimage?&page=${this.paginationValue}&limit=20&search=${this.searchTerm
          ? "name:" + this.searchTerm :""}&orderBy=id&sortedBy=${this.type == 'Oldest' ? 'asc' : 'desc'}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
         .then((json) => {
           this.gallery = json.data.data
          //  this.newest = json.data.data
          //  this.oldest = this.newest.slice().reverse()
          this.paginationTotal = json.data.total;
          this.paginationStart = json.data.from;
          this.paginationEnd = json.data.to;


        })
        .catch((error) => console.log(error, "error"));
    },
    // handleFileChange(event) {
    //   const file = event.target.files[0];
    //   if (file) {
    //     this.previewImage(file);
    //   }
    // },
    handleSubmit() {
      if (this.multipleFile.length > 0 || this.selectedFile.thumbnail) {
        this.$emit('setImage', this.selectedIndex?this.multipleFile:this.selectedFile)
        this.openmodal()
      }
    },
    previewImage(file, index) {
      const reader = new FileReader();
      reader.onload = () => {
        this.filelistShow[index] = reader.result;
        this.$forceUpdate();
      };
      reader.readAsDataURL(file);
    },
    async imagePreviewFun() {
      this.filelistShow = {}
      this.filelist.map((item, index) => {
        const file = item
        if (file) {

          this.previewImage(file, index);
          // console.log(image)
          // let obj = {
          //   image: image,
          //   name: file.name
          // }
          //   this.filelistShow.push(obj)
        }
      })
    },
    async onChange(e) {
      const formData = new FormData();
      let name = []
      e.map((item, index) => {
        name.push(item.name)
        formData.append("attachment[]", item);
      })
      await  axios({
        method: "POST",
        url: `${this.baseApi}/attachments`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
      })
        .then((json) => {
         let array = [...json.data]
          array.map((item, index) => {
            item.name = name[index]
          })
          // let data = {
          //   ...json.data[0],
          //   name:name
          // }
          this.filelist=[...this.filelist,...array];
          this.addGallery(array)

        })
        .catch((error) => console.log(error, "error"));
    },
    async addGallery(array) {
          axios({
            method: "POST",
          url: `${this.baseApi}/galleryimage`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          data: {images: array },
        })
          .then((json) => {
        })
          .catch((error) => console.log(error, "error"))
          .finally(() => {
              this.getGallery()
          })
    },
    remove(i,file) {
      this.filelist.splice(i, 1);
      this.removeImage(file)
      // this.imagePreviewFun();
    },
    async removeImage(file) {
      await axios({
        method: "DELETE",
        url: `${this.baseApi}/galleryimage/${file.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getGallery()
        })
        .catch((error) => console.log(error, "error"));
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    }
  }
}
</script>
<style scoped>
.galleryModal .modal-content {
  min-height: calc(100vh - 100px);
}

.input-file-custom {
  width: 1000%;
  height: 100px;
}

.imgesGallery {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-weight: bolder
}
.active-image{
  border:1px solid #314270 !important;
  border-radius: 10px;
}
.inactive-img{
  border:1px solid transparent !important;
  border-radius: 10px;
}
.imgBox{
  display: flex;
  flex-direction: column ;
  justify-content: space-between;
}
.imgBox:hover{
  border:1px solid  #314270 !important;
  border-radius: 10px;
}

</style>
