<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="8">
            <b-form-group>
              <label>Logo</label>
              <small>
                (Upload your site logo from here. Dimension of the logo should
                be 128x40 Pixel)</small
              >
              <!-- <b-form-file
                v-model="logo.thumbnail"
                placeholder="Select Image"
                drop-placeholder="Drop file here..."
                @input="handleFileChange($event, 'logo')"
              /> -->
              <div  @click="openmodal('logo')" class="fileuploader cursor-pointer d-flex align-items-center" style="border:1px solid silver;padding:10px;border-radius:5px;display:flex;justify-content:space-between">
                <div><feather-icon icon="ImageIcon" size="20" /></div>
                <div>Browse</div>
              </div>
              <attachment v-if="logo && logo.thumbnail" :data="logo.thumbnail" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-card-code title="Information" no-body />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Site Title</label>
              <b-form-input v-model="siteTitle" placeholder="Enter title" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Sub Sitetitle</label>
              <b-form-input
                v-model="siteSubtitle"
                placeholder="Enter Sub Sitetitle"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Currency</label>
              <v-select
                v-model="currency"
                placeholder="Select..."
                label="name"
                :options="[
                  { value: 'INR', name: 'Indian Rupee' },
                  { value: 'USD', name: 'United States Dollar' },
                  { value: 'SGD', name: 'Singapore Dollar' },
                  { value: 'GBP', name: 'Pound Sterling' },
                  { value: 'EUR', name: 'Euro, official currency of Eurozone' },
                ]"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Minimum Order Amount</label>
              <b-form-input
                v-model="minimumOrderAmount"
                placeholder="Enter Amount"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Wallet Currency Ratio</label>
              <b-form-input
                v-model="currencyToWalletRatio"
                type="number"
                placeholder="Enter Ratio"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Maximum Question Limit</label>
              <b-form-input
                v-model="maximumQuestionLimit"
                type="number"
                placeholder="Enter Ratio"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Tax Class</label>
              <b-form-input
                v-model="taxClass"
                type="number"
                placeholder="Enter Ratio"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Shipping Class</label>
              <b-form-input
                v-model="shippingClass"
                type="number"
                placeholder="Enter Ratio"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-row>
              <b-col md="4">
                <label>Enable Free Shipping</label>
                <b-form-checkbox
                  v-model="freeShipping"
                  class="custom-control-primary"
                  name="check-button"
                  @change="handleChange()"
                  switch
                >
                </b-form-checkbox>
              </b-col>
              <b-col md="4">
                <label>Use OTP at checkout</label>
                <b-form-checkbox
                  v-model="useOtp"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                >
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-col>

          <b-col md="12" class="mt-2">
            <b-card-code title="Payment Gatways" no-body />
          </b-col>
          <b-col md="4">
            <label>Cash On Delivery</label>
            <b-form-checkbox
              v-model="useCashOnDelivery"
              class="custom-control-primary"
              name="check-button"
              switch
            >
            </b-form-checkbox>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Paymwnt Gateways</label>
              <v-select
                v-model="paymentGateway"
                placeholder="Select..."
                :options="['Strip', 'Paypal', 'RazorPay', 'Mollie', 'Ccavanue']"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Webhook URL</label>
              <b-form-input
                v-model="webhookurl"
                type="number"
                placeholder="Enter URL"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-card-code title="SEO" no-body />
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Meta Title</label>
              <b-form-input
                v-model="seo.metaTitle"
                type="number"
                placeholder="Enter URL"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Meta Description</label>
              <b-form-textarea
                v-model="seo.metaDescription"
                type="number"
                placeholder="Enter Description"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Canonical URL</label>
              <b-form-input
                v-model="seo.canonicalUrl"
                type="number"
                placeholder="Enter Canonical URL"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>OG Title</label>
              <b-form-input
                v-model="seo.ogTitle"
                type="number"
                placeholder="Enter OG Title"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>OG Description</label>
              <b-form-textarea
                v-model="seo.ogDescription"
                type="number"
                placeholder="Enter OG Description"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>OG Image</label>
              <b-form-file
                v-model="seo.ogImage"
                placeholder="Select Image"
                drop-placeholder="Drop file here..."
                @input="handleFileChange($event, 'aboutus', id+1)"
              />
              <attachment :data="seo.ogImage" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Twitter Handle</label>
              <b-form-input
                v-model="seo.twitterHandle"
                type="number"
                placeholder="Your twitter username (exp: @username)"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Twitter Card Type</label>
              <b-form-input
                v-model="seo.twitterHandle"
                type="number"
                placeholder="one of summary, summary_large_image, app, or player"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-card-code title="Delivery Schedule" no-body />
          </b-col>
          <b-col md="12">
            <div class="overflow-auto">
              <table class="table table-bordered" style="min-width: 15cm">
                <thead class="text">
                  <tr>
                    <th></th>
                    <th>Title/Time</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in deliveryTime" :key="id">
                    <td>{{ id + 1 }}</td>
                    <td>
                      <b-form-input
                        v-model="item.title"
                        placeholder="Enter Title"
                      />
                    </td>
                    <td>
                      <b-form-textarea
                        rows="5"
                        v-model="item.description"
                        placeholder="Enter Description"
                      />
                    </td>
                    <td>
                      <b-button
                        variant="outline-danger"
                        @click="removeRow('deliveryTime', id)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button
              variant="primary"
              @click="addRow('deliveryTime')"
              class="my-1"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>

          <b-col md="12">
            <b-card-code title="Shop Settings" no-body />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Set location from map</label>
              <b-form-textarea
                v-model="contactDetails.location.formattedAddress"
                placeholder="Set location from map"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Contact Number</label>
              <small> (Use ' , ' to separate contact numbers)</small>
              <b-form-input
                v-model="contactDetails.contact"
                placeholder="Enter Contact Number"
              />
            </b-form-group>
          </b-col>
           <b-col md="4">
              <b-form-group>
                <label>Email</label>
                <b-form-input
                  v-model="contactDetails.email"
                  placeholder="Enter Email"
                />
              </b-form-group>
            </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Website</label>
              <b-form-input
                v-model="contactDetails.website"
                placeholder="Enter Website"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <label>Enable Google Map Address</label>
            <b-form-checkbox
              v-model="useGoogleMap"
              class="custom-control-primary"
              name="Enable Google Map Address"
              switch
            >
            </b-form-checkbox>
          </b-col>

          <b-col md="12" class="mt-2">
            <div class="overflow-auto">
              <table class="table table-bordered" style="min-width: 15cm">
                <thead class="text">
                  <tr>
                    <th></th>
                    <th>Select social platform</th>
                    <th>Add profile url</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in socials" :key="id">
                    <td>{{ id + 1 }}</td>
                    <td>
                      <v-select
                        v-model="item.icon"
                        placeholder="Select..."
                        :options="[
                          'Facebook',
                          'Instagram',
                          'Twitter',
                          'Youtube',
                        ]"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.url"
                        placeholder="Enter url"
                      />
                    </td>
                    <td>
                      <b-button
                        variant="outline-danger"
                        @click="removeRow('socials', id)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addRow('socials')" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>CGST (%)</label>
                <b-form-input v-model="cgst" placeholder="Enter CGST"/>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>SGST</label>
                <b-form-input v-model="sgst" placeholder="Enter SGST"/>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>IGST</label>
                <b-form-input v-model="igst" placeholder="Enter IGST"/>
            </b-form-group>
          </b-col>

           <b-col md="12">
              <b-card-code title="Product Shipping" no-body />
            </b-col>
          <b-col md="12">
              <quill-editor v-model="product_shippping" />
          </b-col>



          <b-col cols="12" class="mt-2">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              class="mr-4"
              :disabled="check"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
     <gallary-image-model v-if="modelValue" :modelValue="modelValue" :openmodal="openmodal" @setImage="setImage"/>

    </validation-observer>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import gallaryImageModel from "../../components/gallaryImageModel.vue";

import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";

import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../components/Attechment.vue";

export default {
  components: {
    quillEditor,
    flatPickr,
    BFormCheckboxGroup,
    gallaryImageModel,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BFormCheckbox,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      name: "",
      check: false,
      product_shippping: '',
      cgst:'',
      sgst:'',
      igst:'',
      deliveryTime: [
        {
          title: "",
          description: "",
        },
      ],
      socials: [
        {
          icon: "",
          url: "",
        },
      ],
      contactDetails: {
        contact: "",
        website: "",
        location: {
          formattedAddress: "",
        },
        email:''
      },
      useGoogleMap: false,
      logo: {
        id: "",
        file_name: "",
        thumbnail: "",
        original: "",
      },
      siteTitle: "",
      siteSubtitle: "",
      currency: "",
      minimumOrderAmount: 0,
      currencyToWalletRatio: 0,
      signupPoints: 0,
      maximumQuestionLimit: 0,
      useOtp: false,
      taxClass: 0,
      shippingClass: 0,
      freeShipping: false,
      paymentGateway: "",
      useCashOnDelivery:false,

      seo: {
        metaTitle: "",
        metaDescription: "",
        metaTags: "",
        canonicalUrl: "",
        ogTitle: "",
        ogDescription: "",
        ogImage: "",
        twitterHandle: "",
        twitterCardType: "",
      },
      webhookurl: "",
      selectedField: '',
      selectedIndex: false,
      selected: '',
      modelValue: ''
    };
  },
  async mounted() {
    this.getEditValue();
  },
  methods: {
    openmodal(name, index, field) {
      this.modelValue = !this.modelValue
      this.selected = name
      this.selectedField = field ? field : false
      this.selectedIndex = index
      this.$forceUpdate()
    },
    setImage(image) {
      if (this.selectedIndex && this.selectedField) {
        console.log(this[this.selected]);
        this[this.selected][this.selectedIndex - 1][this.selectedField] = image
      }
      else if (this.selectedIndex) {
        this[this.selected][this.selectedIndex - 1] = image
      }
      else {
        this[this.selected] = image
      }
    },
    handleChange() {
    },
    async getEditValue() {
      // console.log(this.$route.params.id, "id====");
      await axios({
        method: "GET",
        url: `${this.baseApi}/settings?language=en`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      let data = item.options

      this.logo = data.logo

      this.siteTitle=data.siteTitle;
      this.siteSubtitle=data.siteSubtitle;
      this.currency=data.currency;
      this.minimumOrderAmount=data.minimumOrderAmount
      this.currencyToWalletRatio=data.currencyToWalletRatio
      this.signupPoints=data.signupPoints
      this.maximumQuestionLimit=data.maximumQuestionLimit
      this.useOtp=data.useOtplse;
      this.taxClass=data.taxClass
      this.shippingClass=data.shippingClass
      this.freeShipping=data.freeShippinglse;
      this.paymentGateway = data.paymentGateway;
      this.useCashOnDelivery = data.useCashOnDelivery;
      this.product_shippping = data.product_shippping;
      this.cgst = data.cgst;
      this.sgst = data.sgst;
      this.igst = data.igst;
      this.seo = {
        metaTitle: data.seo.metaTitle,
        metaDescription: data.seo.metaDescription,
        metaTags: data.seo.metaTags,
        canonicalUrl: data.seo.canonicalUrl,
        ogTitle: data.seo.ogTitle,
        ogDescription: data.seo.ogDescription,
        ogImage: data.seo.ogImage,
        twitterHandle: data.seo.twitterHandle,
        twitterCardType: data.seo.twitterCardType,
      },
      this.deliveryTime = data.deliveryTime;
      this.contactDetails = {
        contact: data.contactDetails.contact,
        website: data.contactDetails.website,
        location: {
          formattedAddress: data.contactDetails.location.formattedAddress,
        },
        email:data.contactDetails.email
      }
      this.useGoogleMap = data.useGoogleMap;
      this.webhookurl = "http://demo.anmolsilverjewellery.com/api/webhooks/razorpay";
      this.socials = data.contactDetails.socials ?? [];
      this.socials.map((item) => {
          item.icon = item.icon.split('Icon')[0]
      })
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          data = {
          logo :this.logo,
          siteTitle:this.siteTitle,
          siteSubtitle:this.siteSubtitle,
          currency:this.currency,
          minimumOrderAmount:this.minimumOrderAmount,
          currencyToWalletRatio:this.currencyToWalletRatio,
          signupPoints:this.signupPoints,
          maximumQuestionLimit:this.maximumQuestionLimit,
          useOtp:this.useOtplse,
          taxClass:this.taxClass,
          shippingClass:this.shippingClass,
          freeShipping:this.freeShippinglse,
          paymentGateway :this.paymentGateway,
          useCashOnDelivery: this.useCashOnDelivery,
          product_shippping:this.product_shippping,
          seo :{
            metaTitle: this.seo.metaTitle,
            metaDescription: this.seo.metaDescription,
            metaTags: this.seo.metaTags,
            canonicalUrl: this.seo.canonicalUrl,
            ogTitle: this.seo.ogTitle,
            ogDescription: this.seo.ogDescription,
            ogImage: this.seo.ogImage,
            twitterHandle: this.seo.twitterHandle,
            twitterCardType: this.seo.twitterCardType,
          },
        deliveryTime : this.deliveryTime,
        contactDetails : {
        contact: this.contactDetails.contact,
        website: this.contactDetails.website,
        email: this.contactDetails.email,
        location: {
          formattedAddress: this.contactDetails.location.formattedAddress,
        },
        socials :this.contactDetails.socials
      },
        useGoogleMap :this.useGoogleMap,
            webhookurl: "http://demo.anmolsilverjewellery.com/api/webhooks/razorpay",
         cgst: this.cgst,
            sgst: this.sgst,
            igst: this.igst,
          };
        }

        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.check = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method:  "post",
            url: `${baseApi}/settings`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(
              {
                language: 'en',
                options:data
             }
            ),
          })
            .then((json) => {
              this.check = false;
              this.$swal({
                title: "Submited",
                text: "Successfully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              this.check = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    removeRow(name, index) {
      const vue = this;
      vue[name].splice(index, 1);
    },
    addRow(name) {
      const vue = this;
      if (name == "aboutus") {
        vue[name].push({
          image: {
            id: "",
            thumbnail: "",
            original: "",
          },
          description: "",
          title: "",
        });
      }
      vue[name].push({
        image: {
          id: "",
          thumbnail: "",
          original: "",
        },
        title: "",
      });
    },
    async handleFileChange(e, name, id, idName) {
      this.check = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("attachment[]", e);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachments`,
      };
      await axios(requestOptions)
        .then((response) => {
          if (id) {
          this[name][id-1].thumbnail = response.data[0].thumbnail
          this[name][id-1].file_name = e.name
          this[name][id-1].id = response.data[0].id
          this[name][id-1].original = response.data[0].original
          } else {
            this[name].thumbnail = response.data[0].thumbnail
            this[name].file_name = e.name
            this[name].id = response.data[0].id
            this[name].original = response.data[0].original
          }
          this.check = false;
        })
        .catch((error) => {
          this.check = false;
        });
    },
    onClickBack() {
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>

