<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Type</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Type">
                <v-select v-model="type" placeholder="Enter Type"  :options="typeOption" ></v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Subject</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Subject">
                  <input class="form-control" v-model="subject" ref="subject" placeholder="Enter Subject"
                  />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="6" class="d-flex align-items-center">
              <b-dropdown dir="right" text="Insert placeholder">
                <b-dropdown-item
                    @click.native="addPlaceHolder('Customer Name')"
                  >
                    Customer Name
                  </b-dropdown-item>
                <b-dropdown-item
                  @click.native="addPlaceHolder('Invoice Total')"
                  divide
                >
                  Invoice Total
                </b-dropdown-item>
                <b-dropdown-item
                  @click.native="addPlaceHolder('Month Year')"
                  divide
                >
                  Month Year
                </b-dropdown-item>
                <b-dropdown-item @click.native="addPlaceHolder('Month')" divide>
                  Month
                </b-dropdown-item>
                <b-dropdown-item
                  @click.native="addPlaceHolder('Online Invoice Link')"
                  divide
                >
                  Online Invoice Link
                </b-dropdown-item>
                <b-dropdown-item
                  @click.native="addPlaceHolder('Reference')"
                  divide
                >
                  Reference
                </b-dropdown-item>
                <b-dropdown-item
                  @click.native="addPlaceHolder('Trading Name')"
                  divide
                >
                  Trading Name
                </b-dropdown-item>
                <b-dropdown-item
                  @click.native="addPlaceHolder('Week Year')"
                  divide
                >
                  Week Year
                </b-dropdown-item>
                <b-dropdown-item @click.native="addPlaceHolder('Week')" divide>
                  Week
                </b-dropdown-item>
                <b-dropdown-item @click.native="addPlaceHolder('Year')" divide>
                  Year
                </b-dropdown-item>
            </b-dropdown>
          </b-col> -->

          <b-col md="12">
            <b-form-group>
              <validation-provider #default="{ errors }" rules="required" name="Subject">
                  <label>Description</label>
                  <label style="color: red !important">*</label>
                  <b-form-textarea
                  v-model="description"
                  rows="15"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- submit button -->
          <b-col cols="12">
            <b-button variant="primary" type="submit" @click.prevent="submitForm" class="mr-4" >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import { quillEditor } from "vue-quill-editor";
import gallaryImageModel from "../../components/gallaryImageModel.vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  min_value,
  max_value,
  digits,
  alphaDash,
  length,
} from "@validations";


import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormCheckbox,
  BFormCheckboxGroup,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../components/Attechment.vue";

export default {
  components: {
    required,
    flatPickr,
    BFormCheckboxGroup,
    gallaryImageModel,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BFormCheckbox,
    quillEditor,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      description: '',
      name: '',
      type: '',
      subject:'',
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      focusedInput:'',
      selectedName:'',
      cursorPosition: '',
      typeOption: [
        { label: 'OTP', value: 'OTP' },
        { label: 'User Notify', value: 'User Notify' },
        { label: 'Contact admin', value: 'Contact admin' },
        { label: 'Forget Password', value: 'Forget Password' },
        { label: 'Order notification for user', value: 'Order notification for user' },
        { label: 'Order notification for admin', value: 'Order notification for admin' },
        { label: 'Product available notification', value: 'Product available notification' },
        { label: 'Payment Success', value: 'Payment Success' },
      ],
      ifEdit:false

    };
  },
  async mounted() {
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    addPlaceHolder(placeholder) {
      if (this.selectedName =='subject') {
        const textToInsert = `[${placeholder}]`;
         this.cursorPosition = this.focusedInput.target.selectionStart;
        const inputValue = this.focusedInput.target.value;
        const newValue =
          inputValue.slice(0, this.cursorPosition) +
          textToInsert +
          inputValue.slice(this.cursorPosition);
        this.focusedInput.target.value = newValue;
        this[this.selectedName] = newValue;

        const newCursorPosition = this.cursorPosition + textToInsert.length;
        this.focusedInput.target.setSelectionRange(
          newCursorPosition,
          newCursorPosition
        );
        this.focusedInput.target.focus();
      }
      else if (this.selectedName == 'description') {
       const quillInstance = this.$refs.myQuillEditor.quill;

        // Get the current selection position
        const cursorPosition = quillInstance.getSelection().index;

        // Insert the placeholder text at the cursor position
        quillInstance.clipboard.dangerouslyPasteHTML(
          cursorPosition,
          `<span class="placeholder">[${placeholder}]</span>`
        );

        // Move the cursor to the end of the inserted text
        quillInstance.setSelection(cursorPosition + placeholder.length);

      }
    },
    async getEditValue() {
      // console.log(this.$route.params.id, "id====");
      await axios({
        method: "GET",
        url: `${this.baseApi}/emailtemplate/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
     this.name = item.name
     this.type = { label  :item.type , value:item.type }
     this.description = item.description
     this.subject = item.subject

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },

    submitForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          data = {
            name: this.name,
            type: this.type ? this.type.value :'' ,
            subject: this.subject,
            description: this.description,
          };
        }

        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/emailtemplate/${this.$route.params.id}`
              : `${baseApi}/emailtemplate`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.$swal({
                title: "Submited",
                text: this.ifEdit ? "Successfully updated!" : "Successfully added!",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/emailtemplate");
            })
            .catch((error) => {
              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    onClickBack() {
      this.$router.push("/emailtemplate");
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
</style>

